import React from 'react';
import Keycloak from 'keycloak-js';
import { ThemeProvider } from '@mui/material/styles';
import { INITIAL_THEME } from './theme';
import Navigator from './components/navigation/Navigator';
import Wrapper from './components/layout/wrapper';
import RenderOnAuthenticated from './components/RenderOnAuthenticated';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import CONSTANTS from './constants/constants';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { StylesProvider } from '@mui/styles';
import { BrowserRouter as Router } from 'react-router-dom';

// !--------- KEYCLOAK IMPORT -------------
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { setSessionStorageItem } from './encrypt';
import COMMON from './constants/common';

const KeycloakWrapper = (props) => {
  const { config } = props;

  const generateClassName = createGenerateClassName({
    seed: 'empMaster',
  });

  // !--------- KEYCLOAK CODE -------------

  const keycloak = new Keycloak({
    realm: `${config.realm}`,
    url: `${config.authURL}auth/`,
    clientId: `${config.clientId}`,
  });

  const setTokens = () => {
    const { token, refreshToken, idTokenParsed } = keycloak;
    if (token && refreshToken && idTokenParsed) {
      setSessionStorageItem(CONSTANTS.USER_EMAIL, idTokenParsed?.email);
      setSessionStorageItem(CONSTANTS.FIRST_NAME, idTokenParsed?.given_name);
      setSessionStorageItem(CONSTANTS.LAST_NAME, idTokenParsed?.family_name);
      setSessionStorageItem(CONSTANTS.USER_ID, idTokenParsed?.sub);
      setSessionStorageItem(CONSTANTS.REACT_TOKEN, token);
      setSessionStorageItem(CONSTANTS.USER_TYPE, 'user');
      setSessionStorageItem('realm', config?.realm);
      setSessionStorageItem('leavePolicyPdfID', config?.leavePolicyPdfID);
      setSessionStorageItem('emp360HelpDocPdfID', config?.emp360HelpDocPdfID);
      setSessionStorageItem('taskManagementURL', config?.taskManagementURL);
      setSessionStorageItem(
        'insuranceDashboardUrl',
        config?.insuranceDashboardUrl
      );
      setSessionStorageItem(COMMON.GATE_WAY_URL, config?.gatewayURL);
      setSessionStorageItem('keycloakURL', config?.authURL);
      setSessionStorageItem('suprsetDomain', config?.suprsetDomain);
      setSessionStorageItem('suprsetDashboardID', config?.suprsetDashboardID);
      setSessionStorageItem('userName', idTokenParsed?.preferred_username);
      setSessionStorageItem('publicURL', '/');
      setSessionStorageItem('config', JSON.stringify(config));
    }
  };

  const refreshAccessToken = () => {
    keycloak
      .updateToken(50)
      .success((refreshed) => {
        if (refreshed) {
          setTokens();
        }
      })
      .error(() => {
        keycloak.logout();
        sessionStorage.clear();
      });
  };

  const handleEvent = (event) => {
    if (event === 'onAuthSuccess') {
      setTokens();
    }

    if (event === 'onTokenExpired') {
      refreshAccessToken();
    }

    if (event === 'onAuthLogout') {
      sessionStorage.clear();
    }
  };
  // !----------------------------------------------

  const getAppChildren = () => (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={INITIAL_THEME}>
        <Router>
          <Provider store={store}>
            <Wrapper>
              <Navigator />
            </Wrapper>
          </Provider>
        </Router>
      </ThemeProvider>
    </StylesProvider>
  );

  return (
    <>
      <ReactKeycloakProvider
        initOptions={{
          onLoad: 'login-required',
          checkLoginIframe: false,
        }}
        authClient={keycloak}
        onEvent={handleEvent}
      >
        <RenderOnAuthenticated>{getAppChildren()}</RenderOnAuthenticated>
      </ReactKeycloakProvider>
    </>
  );
};

export default KeycloakWrapper;
