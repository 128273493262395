export const LeaveApplicationFilterValues = {
  ALL: { value: 'all', label: 'All' },
  PENDING: { value: 'pending', label: 'Pending' },
  APPROVED: { value: 'approved', label: 'Approved' },
  REJECTED: { value: 'rejected', label: 'Rejected' },
};
export const LeaveApplcationUtils = {
  LEAVE_MANAGEMENT_TABS_DISPLAY_NAME: 'LeaveManagementTabs',
  LOADING_REQUESTS: 'Loading Requests',
  NO_COMP_OFF_REQUESTS_AVAILABLE: 'No Comp Off Requests Available',
  NO_REQUESTS_AVAILABLE: 'No Requests Available',
};
export const SessionStorageConstants = {
  KEY_USER_ROLE: 'User-Role',
};
export const DataGridUtils = {
  PAGE_SIZE_ARRAY: [10, 20, 30],
};

export type FilterAction = { type: string; payload: string };
export const FilterActionTypes = { SET_STATUS: 'SET_STATUS' };

export interface FilterState {
  status: string;
}
export const initialFilterState: FilterState = {
  status: LeaveApplicationFilterValues.ALL.value,
};

export const dataGridStyles = () => ({
  container: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    gap: '10px !important',
  },
  buttonApply: {
    textTransform: 'capitalize !important',
  },
  buttonCancel: {
    textTransform: 'capitalize !important',
  },
  tableStyle: {
    height: '500px !important',
  },
  overlayStyle: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    height: '100% !important',
  },
  tableHeaderStyle: {
    fontSize: '16px !important',
    fontWeight: 'bold !important',
  },
  acceptButtonStyle: {
    color: '#326B16 !important',
    backgroundColor: '#EDEDED !important',
    textTransform: 'capitalize !important',
    '&.Mui-disabled': {
      color: '#808080 !important',
      backgroundColor: '#e7e7e7 !important',
    },
  },
  rejectButtonstyle: {
    color: '#C04A2B !important',
    width: '30px !important',
    textTransform: 'capitalize !important',
    '&.Mui-disabled': {
      color: '#808080 !important',
      backgroundColor: '#e7e7e7 !important',
    },
  },
});
