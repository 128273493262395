import React, { useEffect, useReducer, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, getDate, getDay, parse } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  ApplyCompOff,
  ApplyLeavePost,
  ApplyWfhPost,
} from '../../services/lmsService';
import {
  LEAVE_DETAILS_FORM_ID,
  MY_COMP_OFFS_FORM_ID,
} from '../../constants/formConstants';
import { pushNotification } from '../../redux/actions';
import CONSTANTS, { HOLIDAYS_FORMID } from '../../constants/constants';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  fetchRuntimeFormData,
  FilterFormDetailsWithEmployeeId,
  FilterFormDetailsWithOfficalEmail,
} from '../../services/formService';
import { getAllUsers } from '../../services/userService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RectangularLoader from '../../Loaders/ContentLoader/Loader';
import { leaveTypeEnums, leaveTypes } from '../uiElementsAccess';
import { getCompOffDetailsById } from '../../services/compOffService';
import { formatDate } from '../../services/commonHelperService';
import LeaveNotificationPopup, {
  LeaveNotificationCallbackActions,
} from './UserOverview/LeaveNotificationPopup';
import { getSessionStorageItem } from '../../encrypt';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  APPLY_LEAVE_CONSTANTS,
  leaveCategories,
  partialLeaveCategories,
  popupMessages,
  validationMessages,
} from './applyLeaveConstants';

const useStyles = makeStyles(() => ({
  screenHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#162952',
    height: '50px',
    color: '#FFFFFF',
  },
  formHeadingContainer: {
    display: 'flex',
    alignSelf: 'center',
    margin: '0 auto',
  },
  lmsSubmitBtn: {
    backgroundColor: '#162952 !important',
    borderRadius: '35px !important',
    width: '137px',
  },
  lmsDatePicker: {
    height: '39px',
    // width: "300px",
  },
  lmsLabel: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  empDetailHolder: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
  dialogBox1: {
    wordBreak: 'break-word',
    width: '140px',
    marginRight: '30px',
    fontWeight: 'bold',
    color: 'black',
    justifyContent: 'flex-start',
  },
  dialogBox2: {
    wordBreak: 'break-word',
    width: '200px',
    marginLeft: '30px',
    justifyContent: 'flex-end',
  },
  datePickerStyles: {
    height: '36px',
    border: '1px solid grey !important',
    borderRadius: '5px',
    '&::placeholder': {
      textAlign: 'center',
    },
    textAlign: 'center',
    // "@media (min-width: 1500px) and (max-width: 2000px)": {
    //   paddingTop: "8px !important",
    //   paddingBottom: "8px !important",
    // },
  },
  inputStyles: {
    textAlign: 'center',
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem) !important',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem) !important',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem) !important',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem) !important',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
    },
  },
  rowTwoContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  columnFlexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rowFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quarterColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    minWidth: '280px',
  },
  timePickersContainer: {
    display: 'flex',
    gap: 10,
  },
  flexWrapContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  submitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  datePickerOuterContainerOne: {
    padding: '0.5px',
  },
  datePickerOuterContainerTwo: {
    border: '1px solid gray',
  },
  reportingContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem !important',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  informCC: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
  },
  reasonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  placeCenter: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  reasonTextField: {
    '& .MuiInputBase-input': {
      fontSize: '1rem !important',
    },
  },
}));

const styles = {
  dialogContext: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 500,
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
  },
  typography: {
    screenHeadingContainer: {
      '@media (min-width: 2000px)': {
        fontSize: 'calc(12px + 1.8rem)',
      },
      '@media (min-width: 1500px) and (max-width: 2000px)': {
        fontSize: 'calc(12px + 1.2rem)',
      },
      '@media (min-width: 1000px) and (max-width: 1500px)': {
        fontSize: 'calc(12px + 0.08rem)',
      },
      '@media (min-width: 600px) and (max-width: 1000px)': {
        fontSize: 'calc(12px + 0.008rem)',
      },
      '@media (max-width: 600px)': {
        fontSize: 'calc(12px + 0.001rem)',
      },
    },
    errorText: { color: 'red' },
  },
  leaveFormContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '98%',
    overflowY: 'scroll',
  },
  empCardMain: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
      fontWeight: 'bold',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
      fontWeight: 'bold',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
      fontWeight: 'bold',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
      fontWeight: 'bold',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
      fontWeight: 'bold !important',
    },
  },
  empCardSub: {
    display: 'flex',
    flexDirection: 'column',
    mt: 5,
    gap: 2.5,
    width: '70vw',
    margin: '5% 0',
  },
  empId: {
    width: '80px',
    '@media (min-width: 2000px)': {
      width: '120px !important',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      width: '100px !important',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      width: '80px !important',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      width: '60px !important',
    },
    '@media (max-width: 600px)': {
      width: '60px !important',
    },
  },
  leaveType: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
      fontWeight: 'bold',
      color: 'black',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
      fontWeight: 'bold !important',
      color: 'black',
    },
  },
  radioGroup: {
    justifyContent: 'center',
  },
  radioButton: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
    },
    '@media (min-width: 1000px) and (max-width: 1499px)': {
      fontSize: 'calc(12px + 0.04rem)',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
  },
  requestTo: {
    fontSize: '50px',
    width: '190px',
    '@media (min-width: 2000px)': {
      width: '500px !important',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      width: '450px !important',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      width: '380px !important',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      width: '240px !important',
    },
    '@media (max-width: 600px)': {
      width: '220px !important',
    },
  },
  submitButton: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem)',
      width: '250px',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem)',
      width: '220px',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
      width: '200px',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
  },
  icon: {
    color: '#FFFF',
  },
  datePickersContainer: {
    padding: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    flexWrap: 'wrap',
    alignItems: 'center',
    '@media (max-width: 800px)': {
      // Apply styles for screens up to 960px width
      flexDirection: 'column',
    },
  },
  informToBox: {
    justifyContent: 'flex-start',
  },
};

interface propsSchema {
  show: boolean;
  setShow: (show: boolean) => void;
  leaveChoice: string;
  compOffId: string;
}

const ApplyLeave = (props: propsSchema) => {
  const { setShow, leaveChoice, compOffId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [empId, setEmpId] = useState('');
  const [availableLeaves, setAvailableLeaves] = useState(0);
  const [reportingTo, setReportingTo] = useState('');
  const [usersData, setUsersData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [empLmsData, setEmpLmsData] = useState<any>({});
  const [finalData, setFinalData] = useState<any>({});
  const emailId: any = getSessionStorageItem(CONSTANTS?.USER_EMAIL);
  const [totalLeaveDays, setTotalLeaveDays] = useState(0);
  const [applyDisable, setApplyDisable] = useState(false);
  const [holidays, setHolidays] = useState<any>({});
  const [allHolidays, setAllHolidays] = useState<any>([]);
  const [isDisableToDate, setIsDisableToDate] = useState(false);
  const [compOffData, setCompOffData] = useState<any>();
  const [openWarning, setOpenWarning] = useState(false);
  const [availableGeneralLeaves, setAvailableGeneralLeaves] = useState(0);
  const [availablePrivilegeLeaves, setAvailablePrivilegeLeaves] = useState(0);
  const [allFormValues, setAllFormValues] = useState<any>();
  const minimumDate = new Date();

  minimumDate?.setDate(minimumDate?.getDate() - 14);
  let totalNoOfLeaveDays = 0;

  const popupDataReducer = (mPopupData, action) => {
    switch (action?.type) {
      case APPLY_LEAVE_CONSTANTS?.UPDATE:
        return { ...mPopupData, ...action?.payload };
      default:
        return mPopupData;
    }
  };
  const [popupData, setPopupData] = useReducer(popupDataReducer, {
    infoText: '',
    rightButtonText: '',
    leftButtonText: '',
  });

  const handleClose = () => {
    setOpenDialog(false);
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme?.breakpoints?.down(1500));

  const formOne = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      leaveType: leaveChoice,
      informTo: [],
      reason: '',
      leaveCategory: APPLY_LEAVE_CONSTANTS?.FULL_DAY,
      partialLeaveType: '',
      fromTime: '',
      toTime: '',
    },
    validationSchema: Yup.object({
      startDate: Yup.date()
        .typeError('')
        .required(validationMessages?.startDate?.required)
        .nullable()
        .test(
          APPLY_LEAVE_CONSTANTS?.START_DATE,
          validationMessages?.startDate?.invalid,
          function (startDate) {
            if (!startDate) return true;
            const currentDate = new Date();
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo?.setDate(currentDate?.getDate() - 31);

            const isWithinPast31Days =
              startDate >= thirtyDaysAgo && startDate <= currentDate;
            const isFutureDate = startDate > currentDate;

            return isWithinPast31Days || isFutureDate;
          }
        ),
      endDate: Yup.date()
        .typeError('')
        .required(validationMessages?.endDate?.required)
        .nullable()
        .test(
          APPLY_LEAVE_CONSTANTS?.END_DATE,
          validationMessages?.endDate?.beforeStartDate,
          function (endDate) {
            const startDate = this.resolve(
              Yup.ref(APPLY_LEAVE_CONSTANTS?.START_DATE)
            );
            if (!endDate || !startDate) return true;
            return endDate >= startDate;
          }
        ),
      leaveType: Yup.string().required(validationMessages?.leaveType?.required),
      informTo: Yup.array(),
      reason: Yup.string().required(validationMessages?.reason?.required),
      leaveCategory: Yup.string().required(
        validationMessages?.leaveCategory?.required
      ),
      partialLeaveType: Yup.string().when(
        APPLY_LEAVE_CONSTANTS?.LEAVE_CATEGORY,
        {
          is: (leaveCategory) =>
            leaveCategory === APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY &&
            !isWFHRequest(),
          then: Yup.string().required(
            validationMessages?.partialLeaveType?.required
          ),
          otherwise: Yup.string(),
        }
      ),
      fromTime: Yup.string()
        .when(APPLY_LEAVE_CONSTANTS?.LEAVE_CATEGORY, {
          is: (leaveCategory) =>
            leaveCategory === APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY &&
            isWFHRequest(),
          then: Yup.string().required(validationMessages?.fromTime?.required),
          otherwise: Yup.string(),
        })
        .matches(
          /^\d{1,2}:\d{2} (AM|PM)$/,
          validationMessages?.fromTime?.invalid
        ),
      toTime: Yup.string()
        .when(APPLY_LEAVE_CONSTANTS?.LEAVE_CATEGORY, {
          is: (leaveCategory) =>
            leaveCategory === APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY &&
            isWFHRequest(),
          then: Yup.string().required(validationMessages?.toTime?.required),
          otherwise: Yup.string(),
        })
        .matches(
          /^\d{1,2}:\d{2} (AM|PM)$/,
          validationMessages?.toTime?.invalid
        ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(true);
    setAllFormValues(values);
    setTotalNoOfLeaveDays(values);
    if (!isWFHRequest() && isShowLeavePopup(values)) {
      setOpenWarning(true);
    } else {
      postFormValues(values);
    }
  };

  const calculateWfhLeaveFraction = (fromTime: any, toTime: any) => {
    const fromMoment = moment(fromTime, 'hh:mm A');
    const toMoment = moment(toTime, 'hh:mm A');
    const hoursDifference = moment
      ?.duration(toMoment?.diff(fromMoment))
      ?.asHours();

    const leaveFraction = hoursDifference / 12;
    return Math?.round(leaveFraction * 20) / 20;
  };

  const setTotalNoOfLeaveDays = (values: any) => {
    totalNoOfLeaveDays =
      values?.leaveCategory === APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY &&
      isWFHRequest()
        ? calculateWfhLeaveFraction(values?.fromTime, values?.toTime)
        : values?.leaveCategory === APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY &&
          !isWFHRequest()
        ? 0.5
        : getBusinessDaysCount( 
            new Date(
              moment(values?.startDate?.toISOString())?.format('YYYY-MM-DD')
            ),
            new Date(
              moment(values?.endDate?.toISOString())?.format('YYYY-MM-DD')
            ),
            holidays
          );
  };

  const isShowLeavePopup = (values: any) => {
    if (availableGeneralLeaves === 0 && availablePrivilegeLeaves === 0) {
      setPopupData({
        type: APPLY_LEAVE_CONSTANTS?.UPDATE,
        payload: popupMessages?.NO_LEAVES,
      });
      return true;
    }

    if (availableGeneralLeaves === 0 && availablePrivilegeLeaves !== 0) {
      if (values?.leaveType === leaveTypeEnums?.GENERAL) {
        setPopupData({
          type: APPLY_LEAVE_CONSTANTS?.UPDATE,
          payload: popupMessages?.NO_GENERAL_LEAVES_USE_PRIVILEGE,
        });
        return true;
      }

      if (values?.leaveType === leaveTypeEnums?.PRIVILEGE) {
        if (totalNoOfLeaveDays > availablePrivilegeLeaves) {
          setPopupData({
            type: APPLY_LEAVE_CONSTANTS?.UPDATE,
            payload: popupMessages?.NO_GENERAL_LEAVES_LOP,
          });
          return true;
        }
      }
    }

    if (availableGeneralLeaves !== 0 && availablePrivilegeLeaves !== 0) {
      if (values?.leaveType === leaveTypeEnums?.GENERAL) {
        if (totalNoOfLeaveDays > availableGeneralLeaves) {
          setPopupData({
            type: APPLY_LEAVE_CONSTANTS?.UPDATE,
            payload: popupMessages?.EXCEEDS_GENERAL_USE_PRIVILEGE,
          });
          return true;
        }
      }

      if (values?.leaveType === leaveTypeEnums?.PRIVILEGE) {
        setPopupData({
          type: APPLY_LEAVE_CONSTANTS?.UPDATE,
          payload: popupMessages?.GENERAL_LEAVES_AVAILABLE,
        });
        return true;
      }
    }

    if (availableGeneralLeaves !== 0 && availablePrivilegeLeaves === 0) {
      if (values?.leaveType === leaveTypeEnums?.GENERAL) {
        if (totalNoOfLeaveDays > availableGeneralLeaves) {
          setPopupData({
            type: APPLY_LEAVE_CONSTANTS?.UPDATE,
            payload: popupMessages?.NO_LEAVES,
          });
          return true;
        }
      }
      if (values?.leaveType === leaveTypeEnums?.PRIVILEGE) {
        setPopupData({
          type: APPLY_LEAVE_CONSTANTS?.UPDATE,
          payload: popupMessages?.NO_PRIVILEGE_LEAVES,
        });
        return true;
      }
    }

    return false;
  };

  const warningCallbackAction = (action: string) => {
    if (action === LeaveNotificationCallbackActions?.CONTINUE) {
      postFormValues(allFormValues);
    }
  };

  const formPayload = (values: any) => ({
    fromDate: moment(values?.startDate?.toISOString())?.format('YYYY-MM-DD'),
    toDate: moment(values?.endDate?.toISOString())?.format('YYYY-MM-DD'),
    leaveType: values?.leaveType,
    comments: values?.reason,
    empId: empId,
    informTo: values?.informTo,
    requestTo: reportingTo,
    leaveCategory: values?.leaveCategory,
    partialLeaveType: values?.partialLeaveType,
    lop: getIsLop(values?.leaveType),
    lopDays: getLopDays(values?.leaveType),
  });

  const formWFHPayload = (values: any) => ({
    fromDate: moment(values?.startDate?.toISOString())?.format('YYYY-MM-DD'),
    toDate: moment(values?.endDate?.toISOString())?.format('YYYY-MM-DD'),
    leaveType: values?.leaveType,
    comments: values?.reason,
    empId: empId,
    informTo: values?.informTo,
    requestTo: reportingTo,
    leaveCategory: values?.leaveCategory,
    fromTime: values?.fromTime,
    toTime: values?.toTime,
    lop: getIsLop(values?.leaveType),
    lopDays: getLopDays(values?.leaveType),
  });

  const postFormValues = (values: any) => {
    let postData: any = isWFHRequest()
      ? formWFHPayload(values)
      : formPayload(values);
    const totalDays =
      postData?.leaveCategory === APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY &&
      isWFHRequest()
        ? calculateWfhLeaveFraction(postData?.fromTime, postData?.toTime)
        : postData?.leaveCategory === APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY &&
          !isWFHRequest()
        ? 0.5
        : getBusinessDaysCount(
            new Date(postData?.fromDate),
            new Date(postData?.toDate),
            holidays
          );
    setTotalLeaveDays(totalDays);
    postData = {
      ...postData,
      lop: getIsLop(postData?.leaveType, totalDays),
      lopDays: getLopDays(postData?.leaveType, totalDays),
    };
    setFinalData(postData);
    setOpenDialog(true);
  };

  const getIsLop = (leaveType: any, leaveDays = totalLeaveDays): boolean => {
    return leaveType === leaveTypeEnums?.WFH
      ? false
      : availableLeaves < leaveDays;
  };

  const getLopDays = (leaveType: any, leaveDays = totalLeaveDays): number => {
    const lopDays =
      availableLeaves <= leaveDays ? leaveDays - availableLeaves : 0;
    return leaveType === leaveTypeEnums?.WFH ? 0 : lopDays;
  };

  const getCompOffRequestBody = () => {
    const requestBody = !isWFHRequest()
      ? {
          ...finalData,
          employeeId: empId,
          reason: finalData?.comments,
          requestTo: [finalData?.requestTo],
          leaveDays: totalLeaveDays,
          timeRange: finalData?.partialLeaveType,
        }
      : {
          ...finalData,
          employeeId: empId,
          reason: finalData?.comments,
          requestTo: [finalData?.requestTo],
          leaveDays: totalLeaveDays,
          fromTime: finalData?.fromTime,
          toTime: finalData?.toTime,
        };

    if (leaveChoice === leaveTypeEnums?.COMP_OFF) {
      requestBody[APPLY_LEAVE_CONSTANTS?.COMP_OFF_ID] = props?.compOffId;
    }

    delete requestBody[APPLY_LEAVE_CONSTANTS?.EMP_ID];
    delete requestBody[APPLY_LEAVE_CONSTANTS?.PARTIAL_LEAVE_TYPE];
    delete requestBody[APPLY_LEAVE_CONSTANTS?.COMMENTS];

    return requestBody;
  };

  const handlePostApiRequest = async () => {
    setApplyDisable(true);
    const requestBody = getCompOffRequestBody();
    let response;

    if (leaveChoice === leaveTypeEnums?.COMP_OFF) {
      response = await ApplyCompOff(requestBody);
    } else {
      !isWFHRequest()
        ? (response = await ApplyLeavePost(requestBody))
        : (response = await ApplyWfhPost(requestBody));
    }

    if (response?.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.SUCCESS,
        })
      );
      setOpenDialog(false);
      setShow(false);
      setApplyDisable(false);
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
      setApplyDisable(false);
    }
  };

  const setLeaves = (
    leaveType: any,
    leaveDetails: any = empLmsData?.leaveDetails,
    compOffDetails: any = compOffData
  ) => {
    switch (leaveType) {
      case leaveTypeEnums?.GENERAL:
        setAvailableLeaves(leaveDetails?.general);
        break;
      case leaveTypeEnums?.PRIVILEGE:
        setAvailableLeaves(leaveDetails?.privilege);
        break;
      case leaveTypeEnums?.COMP_OFF:
        setAvailableLeaves(compOffDetails?.availableLeaves);
        break;
      default:
        setAvailableLeaves(0);
        break;
    }
  };

  const handleGetLmsData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      LEAVE_DETAILS_FORM_ID,
      emailId
    );

    if (response?.success && response?.data?.content?.length > 0) {
      setEmpLmsData(response?.data?.content?.[0]?.formData);
      setEmpId(response?.data?.content?.[0]?.formData?.employeeId);
      setReportingTo(response?.data?.content?.[0]?.formData?.reportingTo);
      const leaveDetails = response?.data?.content?.[0]?.formData?.leaveDetails;
      setLeaves(leaveChoice, leaveDetails);
      setAvailableGeneralLeaves(leaveDetails?.general);
      setAvailablePrivilegeLeaves(leaveDetails?.privilege);
    } else {
      setEmpLmsData({});
      setEmpId('');
      setReportingTo('');
      setAvailableLeaves(0);
    }
  };

  const handleGetUserData = async () => {
    const response = await getAllUsers();
    if (response?.success && response?.data?.length > 0) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  const fetchCompOffDetails = async () => {
    const response: any = await FilterFormDetailsWithEmployeeId(
      MY_COMP_OFFS_FORM_ID,
      empId
    );

    let compOffDetails;
    if (response?.success && response?.data?.content?.length > 0) {
      compOffDetails = getCompOffDetailsById(
        compOffId,
        response?.data?.content
      );
      setCompOffData(compOffDetails);
    } else {
      compOffDetails = getCompOffDetailsById(
        compOffId,
        response?.data?.content
      );
      setCompOffData(compOffDetails);
    }
    setLeaves(leaveChoice, empLmsData?.leaveDetails, compOffDetails);
  };

  const handleLeaveTypeChange = async (leaveType: string) => {
    await formOne?.setFieldValue(APPLY_LEAVE_CONSTANTS?.LEAVE_TYPE, leaveType);
    setLeaves(leaveType);
  };

  const handleOptionSelect = (event, value) => {
    const selectedValuesArray = value?.map((option) => {
      const mapData = option?.userData;
      return mapData?.emailId;
    });

    formOne?.setFieldValue(
      APPLY_LEAVE_CONSTANTS?.INFORM_TO,
      selectedValuesArray
    );
  };

  const handleLeaveCategory = async (event, value) => {
    await formOne?.setFieldValue(
      APPLY_LEAVE_CONSTANTS?.LEAVE_CATEGORY,
      value?.value
    );
    await formOne?.setFieldValue(APPLY_LEAVE_CONSTANTS?.START_DATE, null);
    await formOne?.setFieldValue(APPLY_LEAVE_CONSTANTS?.END_DATE, null);
    if (value?.value === APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY) {
      setIsDisableToDate(true);
    } else {
      setIsDisableToDate(false);
    }
  };

  const handlePartialLeaveCategory = (event, selectedValue) => {
    formOne?.setFieldValue(
      APPLY_LEAVE_CONSTANTS?.PARTIAL_LEAVE_TYPE,
      selectedValue?.value
    );
  };

  const getBusinessDaysCount = (startDate, endDate, holidays) => {
    // Convert the start and end dates to milliseconds
    const startMs = startDate?.getTime();
    const endMs = endDate?.getTime();

    // Calculate the number of milliseconds in a day
    const msInDay = 1000 * 60 * 60 * 24;

    // Initialize a counter for the number of business days
    let businessDays = 0;

    // Loop through each day between the start and end dates
    for (let i = startMs; i <= endMs; i += msInDay) {
      const currentDate = new Date(i);

      // If the current day is not a Saturday or Sunday and is not a holiday, increment the businessDays counter
      if (
        currentDate?.getDay() !== 0 &&
        currentDate?.getDay() !== 6 &&
        !isHoliday(currentDate, holidays)
      ) {
        businessDays++;
      }
    }

    // Return the number of business days
    return businessDays;
  };

  // Helper function to check if a date is in the list of holidays
  const isHoliday = (date, holidays) => {
    if (!holidays || !Array?.isArray(holidays)) {
      return false;
    }

    const dateStr = date?.toISOString().split('T')?.[0]; // Get the date part as a string

    return holidays?.includes(dateStr);
  };

  const fullScreen = useMediaQuery(theme?.breakpoints?.down('md'));

  const handleGetHolidaysList = async () => {
    setIsLoading(true);
    const currentYear = new Date().getFullYear();

    try {
      const response = await fetchRuntimeFormData(HOLIDAYS_FORMID);

      if (response?.success) {
        const currentYearHolidayList =
          response?.data?.content?.[0]?.formData?.holidayList?.[currentYear];

        const nextYearHolidayList =
          response?.data?.content?.[0]?.formData?.holidayList?.[
            currentYear + 1
          ];

        const mapData = [...currentYearHolidayList, ...nextYearHolidayList];

        if (mapData) {
          const promises = mapData?.map(async (item) => {
            const date = item?.Date;
            const holidayName = item?.Purpose;

            return { date: date, holidayName: holidayName };
          });

          const holidayListArray = await Promise?.all(promises);
          const holidaysArray = holidayListArray?.map((item) => item?.date);

          setHolidays(holidaysArray);
          setAllHolidays(holidayListArray);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console?.error(APPLY_LEAVE_CONSTANTS?.ERROR_MESSAGES?.HOLIDAYS, error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartDateChange = async (date) => {
    await formOne?.setFieldValue(APPLY_LEAVE_CONSTANTS?.START_DATE, date);

    if (formOne?.values?.leaveCategory === APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY) {
      await formOne?.setFieldValue(APPLY_LEAVE_CONSTANTS?.END_DATE, date);
    }
  };

  const isWeekday = (date: any) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const renderDayContents = (day, date) => {
    return <span title={''}>{getDate(date)}</span>;
  };

  useEffect(() => {
    handleGetLmsData();
    handleGetUserData();
    handleGetHolidaysList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  useEffect(() => {
    if (empId) {
      fetchCompOffDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empId]);

  const getTitle = () => {
    switch (leaveChoice) {
      case leaveTypeEnums?.WFH:
        return APPLY_LEAVE_CONSTANTS?.WFH;
      case leaveTypeEnums?.COMP_OFF:
        return APPLY_LEAVE_CONSTANTS?.COMP_OFF;
      default:
        return APPLY_LEAVE_CONSTANTS?.LEAVE;
    }
  };

  const isWFHOrCompOffRequest = () => {
    return (
      leaveChoice === leaveTypeEnums?.WFH ||
      leaveChoice === leaveTypeEnums?.COMP_OFF
    );
  };

  const isCompOffRequest = () => {
    return leaveChoice === leaveTypeEnums?.COMP_OFF;
  };

  const isWFHRequest = () => {
    return leaveChoice === leaveTypeEnums?.WFH;
  };

  return (
    <>
      <div>
        <form onSubmit={formOne?.handleSubmit}>
          <Box>
            <Dialog
              open={openDialog}
              onClose={handleClose}
              fullScreen={fullScreen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle align="center" id="alert-dialog-title">
                {getTitle()}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  sx={styles?.dialogContext}
                >
                  <Box>
                    <Box className={classes?.empDetailHolder}>
                      <Box className={classes?.dialogBox1}>
                        <p className="applyLeaveHeader">
                          {APPLY_LEAVE_CONSTANTS?.EMP_ID_FIELD_HEADER}
                        </p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box className={classes?.dialogBox2}>{empId}</Box>
                    </Box>
                    {!isWFHRequest() ? (
                      <Box className={classes?.empDetailHolder}>
                        <Box className={classes?.dialogBox1}>
                          <p className="applyLeaveHeader">
                            {APPLY_LEAVE_CONSTANTS?.LEAVE_TYPE_FIELD_HEADER}
                          </p>
                        </Box>
                        &nbsp;:&nbsp;
                        <Box className={classes?.dialogBox2}>
                          {formOne?.values?.leaveType}
                        </Box>
                      </Box>
                    ) : null}

                    {!isWFHRequest() ? (
                      <Box className={classes?.empDetailHolder}>
                        <Box className={classes?.dialogBox1}>
                          <p className="applyLeaveHeader">
                            {APPLY_LEAVE_CONSTANTS?.AVAILABLE}{' '}
                            {formOne?.values?.leaveType ===
                            leaveTypeEnums?.COMP_OFF
                              ? APPLY_LEAVE_CONSTANTS?.COMP_OFFS
                              : APPLY_LEAVE_CONSTANTS?.LEAVES}
                          </p>
                        </Box>
                        &nbsp;:&nbsp;
                        <Box className={classes?.dialogBox2}>
                          {availableLeaves}
                        </Box>
                      </Box>
                    ) : null}

                    {!isWFHOrCompOffRequest() ? (
                      <Box className={classes?.empDetailHolder}>
                        <Box className={classes?.dialogBox1}>
                          <p className="applyLeaveHeader">
                            {APPLY_LEAVE_CONSTANTS?.LOP_FIELD_HEADER}
                          </p>
                        </Box>
                        &nbsp;:&nbsp;
                        <Box className={classes?.dialogBox2}>
                          {finalData?.lopDays}
                        </Box>
                      </Box>
                    ) : null}

                    <Box className={classes?.empDetailHolder}>
                      <Box className={classes?.dialogBox1}>
                        <p className="applyLeaveHeader">
                          {APPLY_LEAVE_CONSTANTS?.FROM_DATE_FIELD_HEADER}
                        </p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box className={classes?.dialogBox2}>
                        {formatDate(finalData?.fromDate)}
                      </Box>
                    </Box>
                    <Box className={classes?.empDetailHolder}>
                      <Box className={classes?.dialogBox1}>
                        <p className="applyLeaveHeader">
                          {APPLY_LEAVE_CONSTANTS?.TO_DATE_FIELD_HEADER}
                        </p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box className={classes?.dialogBox2}>
                        {formatDate(finalData?.toDate)}
                      </Box>
                    </Box>

                    <Box className={classes?.empDetailHolder}>
                      <Box className={classes?.dialogBox1}>
                        <p className="applyLeaveHeader">
                          {APPLY_LEAVE_CONSTANTS?.DAYS_FIELD_HEADER}
                        </p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box className={classes?.dialogBox2}>
                        {totalLeaveDays}
                      </Box>
                    </Box>

                    <Box className={classes?.empDetailHolder}>
                      <Box className={classes?.dialogBox1}>
                        <p className="applyLeaveHeader">
                          {APPLY_LEAVE_CONSTANTS?.RM_FIELD_HEADER}
                        </p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box className={classes?.dialogBox2}>{reportingTo}</Box>
                    </Box>
                    <Box className={classes?.empDetailHolder}>
                      <Box className={classes?.dialogBox1}>
                        <p className="applyLeaveHeader">
                          {APPLY_LEAVE_CONSTANTS?.CC_FIELD_HEADER}
                        </p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box
                        className={classes?.dialogBox2}
                        sx={styles?.informToBox}
                      >
                        {formOne?.values?.informTo?.join(', ')}
                      </Box>
                    </Box>
                    <Box className={classes?.empDetailHolder}>
                      <Box className={classes?.dialogBox1}>
                        <p className="applyLeaveHeader">
                          {APPLY_LEAVE_CONSTANTS?.REASON_FIELD_HEADER}
                        </p>
                      </Box>
                      &nbsp;:&nbsp;
                      <Box className={classes?.dialogBox2}>
                        {formOne?.values?.reason}
                      </Box>
                    </Box>
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handlePostApiRequest}
                  disabled={applyDisable}
                >
                  {APPLY_LEAVE_CONSTANTS?.APPLY_BUTTON}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  autoFocus
                  disabled={applyDisable}
                >
                  {APPLY_LEAVE_CONSTANTS?.CANCEL_BUTTON}
                </Button>
              </DialogActions>
            </Dialog>

            {openWarning && (
              <LeaveNotificationPopup
                show={openWarning}
                setShow={setOpenWarning}
                availablePrivilegeLeaves={availablePrivilegeLeaves}
                availableGeneralLeaves={availableGeneralLeaves}
                callbackAction={warningCallbackAction}
                popupData={popupData}
              />
            )}

            <Box className={classes?.screenHeadingContainer}>
              <Box className={classes?.formHeadingContainer}>
                <Typography
                  variant="h6"
                  sx={styles?.typography?.screenHeadingContainer}
                >
                  {`Apply ${getTitle()}`}
                </Typography>
              </Box>
              <Box>
                <Button sx={styles?.icon} onClick={() => setShow(false)}>
                  <CloseOutlinedIcon />
                </Button>
              </Box>
            </Box>
            <Box className={'empCardMain'}>
              <Box sx={styles?.leaveFormContainer}>
                <Box>
                  <Box sx={[styles?.empCardMain, styles?.empCardSub]}>
                    <Box className={classes?.rowTwoContainer}>
                      <Box className={classes?.columnFlex}>
                        <label className="applyLeaveHeader">
                          {APPLY_LEAVE_CONSTANTS?.EMP_ID_HEADING}
                        </label>
                        <TextField
                          id="Emp-ID"
                          name={APPLY_LEAVE_CONSTANTS?.EMP_ID}
                          value={empId}
                          InputProps={{
                            inputProps: {
                              className: classes?.inputStyles,
                            },
                          }}
                          sx={styles?.empId}
                          onKeyDown={(e) => e?.preventDefault()}
                          size="small"
                        />
                      </Box>

                      {!isWFHOrCompOffRequest() ? (
                        <Box className={classes?.columnFlex}>
                          <FormControl size="small">
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              sx={styles?.leaveType}
                              className={classes?.lmsLabel}
                            >
                              {APPLY_LEAVE_CONSTANTS?.LEAVE_TYPE_HEADING}
                            </FormLabel>

                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={APPLY_LEAVE_CONSTANTS?.LEAVE_TYPE}
                              id="leave-type"
                              onBlur={formOne?.handleBlur}
                              onChange={(event) => {
                                formOne?.handleChange(event);
                                handleLeaveTypeChange(event?.target?.value);
                              }}
                              value={formOne?.values?.leaveType}
                              sx={styles?.radioGroup}
                            >
                              {leaveTypes?.map((leaveTypeItem) => {
                                return (
                                  <FormControlLabel
                                    key={`leaveType_${leaveTypeItem?.value}`}
                                    value={leaveTypeItem?.value}
                                    control={<Radio />}
                                    label={leaveTypeItem?.title}
                                    sx={styles?.radioButton}
                                  />
                                );
                              })}
                            </RadioGroup>
                            <FormHelperText sx={styles?.typography?.errorText}>
                              {formOne?.touched?.leaveType &&
                                formOne?.errors?.leaveType}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      ) : null}

                      {!isWFHOrCompOffRequest() ? (
                        <Box className={classes?.columnFlexCenter}>
                          <label>
                            {APPLY_LEAVE_CONSTANTS?.AVAILABLE_LEAVES_HEADING}
                          </label>
                          <TextField
                            id="available-leaves"
                            name={APPLY_LEAVE_CONSTANTS?.AVAILABLE_LEAVES}
                            value={availableLeaves}
                            InputProps={{
                              inputProps: {
                                className: classes?.inputStyles,
                              },
                            }}
                            sx={styles?.empId}
                            onKeyDown={(e) => e?.preventDefault()}
                            size="small"
                          />
                        </Box>
                      ) : null}
                    </Box>

                    {!isCompOffRequest() ? (
                      <Box className={classes?.rowTwoContainer}>
                        <Box className={classes?.quarterColumnContainer}>
                          <label className={classes?.lmsLabel}>
                            {APPLY_LEAVE_CONSTANTS?.LEAVE_CATEGORY_HEADING}
                          </label>

                          <Autocomplete
                            disablePortal
                            id={APPLY_LEAVE_CONSTANTS?.LEAVE_CATEGORY}
                            options={leaveCategories}
                            getOptionLabel={(option: any) =>
                              option?.title || ''
                            }
                            onChange={handleLeaveCategory}
                            disableClearable
                            size="small"
                            classes={{ input: classes?.inputStyles }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={
                                  formOne?.touched?.leaveCategory &&
                                  Boolean(formOne?.errors?.leaveCategory)
                                }
                                helperText={
                                  formOne?.touched?.leaveCategory &&
                                  formOne?.errors?.leaveCategory
                                }
                              />
                            )}
                          />
                        </Box>

                        {!isWFHRequest() ? (
                          formOne?.values?.leaveCategory ===
                          APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY ? (
                            <Box className={classes?.quarterColumnContainer}>
                              <label className={classes?.lmsLabel}>
                                {APPLY_LEAVE_CONSTANTS?.TIME_HEADING}
                              </label>

                              <Autocomplete
                                disablePortal
                                id={
                                  APPLY_LEAVE_CONSTANTS?.PARTIAL_LEAVE_CATEGORY
                                }
                                options={partialLeaveCategories}
                                getOptionLabel={(option: any) =>
                                  option?.title || ''
                                }
                                onChange={handlePartialLeaveCategory}
                                disableClearable
                                size="small"
                                classes={{ input: classes?.inputStyles }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={
                                      formOne?.touched?.partialLeaveType &&
                                      Boolean(formOne?.errors?.partialLeaveType)
                                    }
                                    helperText={
                                      formOne?.touched?.partialLeaveType &&
                                      formOne?.errors?.partialLeaveType
                                    }
                                  />
                                )}
                              />
                            </Box>
                          ) : null
                        ) : formOne?.values?.leaveCategory ===
                          APPLY_LEAVE_CONSTANTS?.PARTIAL_DAY ? (
                          <Box className={classes?.quarterColumnContainer}>
                            <label className={classes?.lmsLabel}>
                              {APPLY_LEAVE_CONSTANTS?.TIME_HEADING}
                            </label>
                            <Box className={classes?.timePickersContainer}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <TimePicker
                                  label={
                                    APPLY_LEAVE_CONSTANTS?.FROM_TIME_HEADING
                                  }
                                  value={
                                    formOne?.values?.fromTime
                                      ? parse(
                                          formOne?.values?.fromTime,
                                          'hh:mm a',
                                          new Date()
                                        )
                                      : null
                                  }
                                  onChange={(newValue) => {
                                    const formattedValue = newValue
                                      ? format(newValue, 'hh:mm a')
                                      : '';
                                    formOne?.setFieldValue(
                                      APPLY_LEAVE_CONSTANTS?.FROM_TIME,
                                      formattedValue
                                    );
                                  }}
                                  slotProps={{
                                    textField: {
                                      error:
                                        formOne?.touched?.fromTime &&
                                        Boolean(formOne?.errors?.fromTime),
                                      helperText:
                                        formOne?.touched?.fromTime &&
                                        formOne?.errors?.fromTime,
                                      size: isMd ? 'small' : 'medium',
                                      InputProps: {
                                        className: classes?.inputStyles,
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <TimePicker
                                  label={APPLY_LEAVE_CONSTANTS?.TO_TIME_HEADING}
                                  value={
                                    formOne?.values?.toTime
                                      ? parse(
                                          formOne?.values?.toTime,
                                          'hh:mm a',
                                          new Date()
                                        )
                                      : null
                                  }
                                  onChange={(newValue) => {
                                    const formattedValue = newValue
                                      ? format(newValue, 'hh:mm a')
                                      : '';
                                    formOne?.setFieldValue(
                                      APPLY_LEAVE_CONSTANTS?.TO_TIME,
                                      formattedValue
                                    );
                                  }}
                                  slotProps={{
                                    textField: {
                                      error:
                                        formOne?.touched?.toTime &&
                                        Boolean(formOne?.errors?.toTime),
                                      helperText:
                                        formOne?.touched?.toTime &&
                                        formOne?.errors?.toTime,
                                      size: isMd ? 'small' : 'medium',
                                      InputProps: {
                                        className: classes?.inputStyles,
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Box>
                          </Box>
                        ) : null}
                      </Box>
                    ) : null}

                    <Box className={classes?.flexWrapContainer}>
                      {isLoading ? (
                        <RectangularLoader loaderAlignX={80} />
                      ) : (
                        <Box className={classes?.columnFlex}>
                          <label className={classes?.lmsLabel}>
                            {APPLY_LEAVE_CONSTANTS?.DATE_HEADING}
                          </label>

                          <Box className={classes?.datePickerOuterContainerOne}>
                            <Box
                              className={classes?.datePickerOuterContainerTwo}
                            >
                              <Box sx={styles?.datePickersContainer}>
                                <Box className={classes?.columnFlex}>
                                  <DatePicker
                                    id="datePicker-startDate"
                                    className={classes?.datePickerStyles}
                                    selected={formOne?.values?.startDate}
                                    onChange={(date) =>
                                      handleStartDateChange(date)
                                    }
                                    filterDate={isWeekday}
                                    holidays={allHolidays}
                                    placeholderText={
                                      APPLY_LEAVE_CONSTANTS?.START_DATE_PLACEHOLDER
                                    }
                                    onKeyDown={(e) => e?.preventDefault()}
                                    renderDayContents={renderDayContents}
                                    autoComplete="off"
                                  />
                                </Box>

                                <Box mt={0.5}>-</Box>
                                <Box className={classes?.columnFlex}>
                                  <DatePicker
                                    id="datePicker-endDate"
                                    className={classes?.datePickerStyles}
                                    required
                                    selected={formOne?.values?.endDate}
                                    onChange={(date) =>
                                      formOne?.setFieldValue(
                                        APPLY_LEAVE_CONSTANTS?.END_DATE,
                                        date
                                      )
                                    }
                                    filterDate={isWeekday}
                                    holidays={allHolidays}
                                    disabled={isDisableToDate}
                                    placeholderText={
                                      APPLY_LEAVE_CONSTANTS?.END_DATE_PLACEHOLDER
                                    }
                                    onKeyDown={(e) => e?.preventDefault()}
                                    renderDayContents={renderDayContents}
                                    autoComplete="off"
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box className={classes?.rowFlex}>
                              <FormHelperText
                                sx={styles?.typography?.errorText}
                              >
                                {formOne?.errors?.startDate ||
                                  formOne?.errors?.endDate}
                              </FormHelperText>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Box className={classes?.reportingContainer}>
                      <Box className={classes?.columnFlex}>
                        <label className={classes?.lmsLabel}>
                          {APPLY_LEAVE_CONSTANTS?.REQUEST_TO_HEADING}
                        </label>
                        <TextField
                          id="Request-to"
                          name={APPLY_LEAVE_CONSTANTS?.REQUEST_TO}
                          value={reportingTo}
                          InputProps={{
                            inputProps: {
                              className: classes?.inputStyles,
                            },
                          }}
                          sx={styles?.requestTo}
                          onKeyDown={(e) => e?.preventDefault()}
                          size="small"
                        />
                      </Box>
                      <Box className={classes?.informCC}>
                        <label className={classes?.lmsLabel}>
                          {APPLY_LEAVE_CONSTANTS?.INFORM_TO_HEADING}
                        </label>

                        <Autocomplete
                          disablePortal
                          id={APPLY_LEAVE_CONSTANTS?.INFORM_TO}
                          options={usersData}
                          multiple
                          getOptionLabel={(option: any) =>
                            option?.userData?.emailId || ''
                          }
                          onChange={handleOptionSelect}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                formOne?.touched?.informTo &&
                                Boolean(formOne?.errors?.informTo)
                              }
                              helperText={
                                formOne?.touched?.informTo &&
                                formOne?.errors?.informTo
                              }
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box className={classes?.flexWrapContainer}>
                      <Box className={classes?.reasonContainer}>
                        <label className={classes?.placeCenter}>
                          {APPLY_LEAVE_CONSTANTS?.REASON_HEADING}
                        </label>
                        <TextField
                          id="reason-leave"
                          name={APPLY_LEAVE_CONSTANTS?.REASON}
                          helperText={
                            formOne?.touched?.reason && formOne?.errors?.reason
                          }
                          error={
                            formOne?.touched?.reason &&
                            Boolean(formOne?.errors?.reason)
                          }
                          className={classes?.reasonTextField}
                          onBlur={formOne?.handleBlur}
                          onChange={formOne?.handleChange}
                          value={formOne?.values?.reason}
                          multiline
                        />
                      </Box>
                    </Box>
                    <Box className={classes?.submitContainer}>
                      <Box className={classes?.columnFlex}>
                        <Button
                          className={classes?.lmsSubmitBtn}
                          variant="contained"
                          type="submit"
                          sx={styles?.submitButton}
                        >
                          {APPLY_LEAVE_CONSTANTS?.SUBMIT_BUTTON}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </div>
    </>
  );
};

export default ApplyLeave;
