import { Box, Button, Typography, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from '../../../components/common/ConfirmationModal/ConfirmationModal';
import TsDatagrid from '../../../components/TsDatagrid';
import CONSTANTS from '../../../constants/constants';
import { MY_COMP_OFFS_FORM_ID } from '../../../constants/formConstants';
import { pushNotification } from '../../../redux/actions';
import { capitalize, formatDate } from '../../../services/commonHelperService';
import { FilterFormDetailsWithEmployeeId } from '../../../services/formService';
import { ChangeCompOffStatus } from '../../../services/lmsService';
import { leaveTypeEnums } from '../../uiElementsAccess';
import ApplyLeaveModal from '../applyLeaveModal';
import {
  dataGridStyles,
  DataGridUtils,
  FilterState,
  LeaveApplcationUtils,
  LeaveApplicationFilterValues,
} from '../leaveManagementSystemConstants';
import './myCompOffs.css';
import useCustomStyles from '../../../components/common/styles/CustomStylesHook';
import { LEAVE_CONSTANTS } from '../../adminConstants';

interface MyCompOffsProps {
  empId: any;
  filterState: FilterState;
}
const MyCompOffs = ({ empId, filterState }: MyCompOffsProps) => {
  const theme = useTheme();
  const classes = useCustomStyles(dataGridStyles, theme);
  const dispatch = useDispatch();
  const [selectedRowId, setSelectedRowId] = useState('');
  const [isCancelButtonDisabled, setIsCancelButtonDisabled] = useState(false);
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
    useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);

  const [compOffList, setCompOffList] = useState<any>([]);
  const [isApplyLeaveModalOpen, setIsApplyLeaveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = (id) => {
    setSelectedRowId(id);
    setIsCancelConfirmationModalOpen(true);
  };
  const handleClose = () => {
    setIsCancelConfirmationModalOpen(false);
    setSelectedRowId('');
  };

  useEffect(() => {
    fetchCompOffs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Filter based on the status in filterState
    if (filterState?.status === LeaveApplicationFilterValues?.ALL?.value) {
      // If status is "All," show all data
      setFilteredData(compOffList);
    } else {
      // Otherwise, filter allData by status
      const filtered = compOffList?.filter(
        (item) => item?.status === filterState?.status
      );
      setFilteredData(filtered);
    }
  }, [filterState, compOffList]);

  useEffect(() => {
    if (!isApplyLeaveModalOpen) {
      fetchCompOffs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplyLeaveModalOpen]);

  const fetchCompOffs = async () => {
    setIsLoading(true);
    const response: any = await FilterFormDetailsWithEmployeeId(
      MY_COMP_OFFS_FORM_ID,
      empId
    );
    // setCompOffList(response?.data?.content);
    if (response?.success) {
      const finalData = response?.data?.content?.map((item) => {
        return {
          id: item?.id,
          fromDate: item?.formData?.fromDate,
          toDate: item?.formData?.toDate,
          totalDays: item?.formData?.totalCompOffCount,
          status: item?.formData?.status,
          comments: item?.formData?.reason,
          consumed: item?.formData?.consumedCount,
          expired: item?.formData?.expiredCount,
          appliedOn: item?.createdOn,
          approvedOn:
            Boolean(item?.formData?.approvedOn) &&
            item?.formData?.approvedOn !== ''
              ? item?.formData?.approvedOn
              : '-',
        };
      });
      setCompOffList(finalData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleCancelLeave = async (id) => {
    setIsCancelButtonDisabled(true);
    const response = await ChangeCompOffStatus(id, LEAVE_CONSTANTS?.CANCEL);
    if (response?.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.SUCCESS,
        })
      );
      setIsCancelConfirmationModalOpen(false);
      setIsCancelButtonDisabled(false);
      setSelectedRowId('');
      await fetchCompOffs();
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
      setIsCancelButtonDisabled(false);
      setSelectedRowId('');
    }
  };

  const showApplyCompOff = (data) => {
    const available = data?.totalDays - data?.consumed - data?.expired;
    return (
      (data?.status === LEAVE_CONSTANTS?.APPROVED ||
        data?.status === LEAVE_CONSTANTS?.ACTIVE) &&
      available > 0
    );
  };

  const isCancelDisabled = (compOffListItem) => {
    return (
      compOffListItem?.status === LEAVE_CONSTANTS?.CANCELLED ||
      compOffListItem?.status === LEAVE_CONSTANTS?.APPROVED ||
      compOffListItem?.status === LEAVE_CONSTANTS?.REJECTED
    );
  };

  const openApplyLeaveModal = (id) => {
    setSelectedRowId(id);
    setIsApplyLeaveModalOpen(true);
  };

  const getTableHeader = (title) => {
    return (
      <Typography className={classes?.tableHeaderStyle}>{title}</Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'fromDate',
      headerName: 'From',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'toDate',
      headerName: 'To',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'totalDays',
      headerName: 'No.of.days',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'consumed',
      headerName: 'Consumed',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'appliedOn',
      headerName: 'Applied On',
      editable: false,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row: any) => {
        const value = dayjs(new Date(row?.value))
          .format('DD/MM/YYYY - hh:mm a')
          .replace('am', 'AM')
          .replace('pm', 'PM');
        if (value === 'Invalid Date') return '-';
        else return value;
      },
      flex: 1,
    },
    {
      field: 'approvedOn',
      headerName: 'Approved on',
      minWidth: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      // valueFormatter: (row: any) => {
      //   const value = dayjs(new Date(row?.value)).format("DD/MM/YYYY");
      //   if (value === "Invalid Date") return "-";
      //   else return value;
      // },
      flex: 1,
    },
    {
      field: 'expired',
      headerName: 'Expired',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => capitalize(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      editable: false,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (props) => {
        const compOffListItem = props?.row;
        return (
          <Box gap={1} className={classes?.container}>
            {showApplyCompOff(compOffListItem) ? (
              <Button
                variant="outlined"
                size="small"
                className={`action-button-class apply-comp-off-button ${classes?.buttonApply}`}
                onClick={() => openApplyLeaveModal(compOffListItem?.id)}
              >
                Apply Comp Off
              </Button>
            ) : null}

            {compOffListItem?.status ? (
              <Button
                variant="contained"
                size="small"
                className={`action-button-class cancel-button ${classes?.buttonCancel}`}
                onClick={() => handleOpen(compOffListItem?.id)}
                disabled={isCancelDisabled(compOffListItem)}
              >
                Cancel
              </Button>
            ) : null}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {isCancelConfirmationModalOpen && (
        <ConfirmationModal
          id="confirmationModal"
          title="Cancel Comp Off"
          message="Do you want to cancel this Comp Off?"
          open={isCancelConfirmationModalOpen}
          onClose={handleClose}
          onSubmit={() => handleCancelLeave(selectedRowId)}
          isSubmitButtonDisabled={isCancelButtonDisabled}
        />
      )}

      {isApplyLeaveModalOpen ? (
        <ApplyLeaveModal
          show={isApplyLeaveModalOpen}
          setShow={setIsApplyLeaveModalOpen}
          leaveChoice={leaveTypeEnums?.COMP_OFF}
          compOffId={selectedRowId}
        />
      ) : null}

      <div>
        <Box className="myLeavescontainer">
          <TsDatagrid
            rows={filteredData}
            columns={columns}
            pageSizeArray={DataGridUtils?.PAGE_SIZE_ARRAY}
            getSelectedRowsData={() => {
              // TODO: Implement function
            }}
            handlePageChange={() => {
              // TODO: Implement function
            }}
            handlePageSizeChange={() => {
              // TODO: Implement function
            }}
            pageSize={rowsPerPage}
            hideFooter={false}
            onPageSizeChange={(value) => setRowsPerPage(value)}
            isCheckboxSelection={false}
            totalElements={filteredData?.length}
            className={classes?.tableStyle}
            components={{
              NoRowsOverlay: () => (
                <Box className={classes?.overlayStyle}>
                  {isLoading
                    ? LeaveApplcationUtils?.LOADING_REQUESTS
                    : LeaveApplcationUtils?.NO_COMP_OFF_REQUESTS_AVAILABLE}
                </Box>
              ),
            }}
          />
        </Box>
      </div>
    </>
  );
};

export default MyCompOffs;
