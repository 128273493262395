import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import DataMapComponent from '../components/common/DataMapComponent';
import CONSTANTS, { HOLIDAYS_FORMID } from '../constants/constants';
import { pushNotification } from '../redux/actions';
import {
  attendanceReportRequest,
  leaveHistoryRequest,
  leaveReportRequest,
} from '../services/AdminService';
import { HolidaysUpload } from '../services/DocumentService';
import { fetchRuntimeFormData } from '../services/formService';
import AdminSettings from './ModalViews/adminSettings/AdminSettings';
import useCustomStyles from '../components/common/styles/CustomStylesHook';
import {
  REPORT_TYPES,
  ATTENDANCE_SELECTED_VALUES,
  DataMapCategories,
} from './adminConstants';

const styles = () => ({
  boxCommon: {
    position: 'absolute !important',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    width: '400px !important',
    bgcolor: 'background.paper !important',
    boxShadow: '24 !important',
    padding: '4rem !important',
    background: 'white',
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  screen: {
    width: '100% !important',
    height: '100vh !important',
    backgroundColor: 'rgb(227, 231, 239) !important',
    padding: '1vw !important',
  },
  containerMain: {
    backgroundColor: '#fff !important',
    height: '100% !important',
    font: 'Roboto !important',
    borderRadius: '10px !important',
    overflow: 'auto !important',
  },
  settingsContainer: {},
  dialogTitleContainer: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    width: '100% !important',
  },
  fieldTitle: {
    font: 'Roboto !important',
    fontWeight: '500 !important',
    fontSize: '23px !important',
    lineHeight: '26.95px !important',
    color: '#162952 !important',
    margin: '1rem 0 !important',
  },
  fieldInput: {
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'center !important',
    margin: '1rem 0 !important',
  },
  loaderContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    margin: '5rem 0rem !important',
    flexDirection: 'column !important',
    gap: '6px !important',
  },
  holidayCard: {
    border: '1px solid #ACACAC !important',
    borderRadius: '5px !important',
    width: '15vw !important',
    backgroundColor: '#F8E7E3 !important',
  },
  holidayText: {
    color: '#F15830 !important',
    fontSize: '1.2vw !important',
  },
  holidayPurposeText: {
    color: '#000000 !important',
    fontSize: '1vw !important',
  },
  leaveReportSection: {
    display: 'flex !important',
    flexDirection: 'row !important',
    justifyContent: 'start !important',
    flexWrap: 'wrap !important',
    width: '100% !important',
  },
  leaveReportSectionControls: {
    display: 'flex !important',
    flexDirection: 'row !important',
    width: '100% !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
  },
  leaveReportButton: {
    backgroundColor: '#162952 !important',
    color: '#ffff !important',
    '&:disabled': {
      backgroundColor: 'rgb(128, 128, 128) !important',
      color: '#fff !important',
    },
    '&:hover': {
      backgroundColor: '#081530 !important',
      color: '#fff !important',
    },
  },
  yearDropdown: {
    float: 'right !important',
    marginBottom: '5px !important',
  },
  attendanceReportContainer: {
    mt: '2 !important',
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
  },
  attendanceReportRadioContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    flexDirection: { xs: 'column !important', sm: 'row !important' },
    gap: '1 !important',
    width: '100% !important',
  },
  attendanceReportRadioGroup: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
  },
  attendanceReportRadioButton: {
    padding: { xs: '1 !important', sm: '2 !important' },
    '& .MuiFormControlLabel-label': {
      fontSize: '1rem !important',
    },
  },
  attendanceReportFieldContainer: {
    marginBottom: '0.8rem !important',
    width: '400px !important',
  },
  attendanceReportTextField: {
    marginBottom: '0.8rem !important',
  },
  closeButton: {
    color: '#2b5f6b !important',
    borderRadius: '50% !important',
    width: '40px !important',
    height: '40px !important',
    minWidth: '0 !important',
    padding: '0 !important',
    zIndex: '1100 !important',
  },
  commonIcon: {
    color: '#333 !important',
    width: '30px !important',
    height: '30px !important',
  },
  datesContainer: {
    display: 'flex !important',
    flexDirection: 'column !important',
    width: '100% !important',
  },
  datesOuterContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    border: '1px solid #ACACAC !important',
    borderRadius: '5px !important',
    padding: '0.5rem !important',
  },
  datesInnerContainer: {
    display: 'flex !important',
    flexDirection: 'row !important',
    alignItems: 'center !important',
    gap: '8px !important',
  },
  sectionContainer: {
    display: 'flex !important',
    flexDirection: 'column !important',
    padding: '2 !important',
    gap: '1 !important',
  },
  holidaysContainer: {
    display: 'flex !important',
    flexDirection: 'row !important',
    gap: '1rem !important',
  },
  rowFlex: { display: 'flex !important', flexDirection: 'row !important' },
  columnFlex: {
    display: 'flex !important',
    flexDirection: 'column !important',
  },
  headerText: { mt: '2 !important' },
  errorText: { color: 'red !important' },
  themeText: { color: '#162952 !important' },
  datePickerFromDate: {
    width: '200px !important',
  },
  boxDataMapContainer: {
    display: 'flex !important',
    justifyContent: 'flex-start !important',
    alignItems: 'flex-start !important',
    flexWrap: 'wrap !important',
    gap: '4rem !important',
  },
  selectYearLabel: { width: '100px !important' },
});

const Admin = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [employeeIds, setEmployeeIds] = useState('');
  const [reportType, setReportType] = useState(REPORT_TYPES?.DAILY);
  const [attendancePopupVisible, setAttendancePopupVisible] = useState(false);
  const [settingsPopupVisible, setSettingsPopupVisible] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isAttendanceLoading, setIsAttendanceLoading] = useState(false);

  const fileTypes = ['CSV'];
  const [yearsList, setYearsList] = React.useState<any>([]);
  const [selectedYear, setSelectedYear] = React.useState('');

  const [holidays, setHolidays] = React.useState<any>({});

  const [openLeaveType, setOpenLeaveType] = React.useState(false);
  const handleCloseLeaveType = () => setOpenLeaveType(false);

  const [openEmployeeType, setOpenEmployeeType] = React.useState(false);
  const handleCloseEmployeeType = () => setOpenEmployeeType(false);

  const [openHolidays, setOpenHolidays] = React.useState(false);
  const handleOpenHolidays = () => setOpenHolidays(true);
  const handleCloseHolidays = () => setOpenHolidays(false);
  const [attendanceSelectedValue, setAttendanceSelectedValue] = useState(
    ATTENDANCE_SELECTED_VALUES?.ALL
  );

  const toggleSettingsClick = () => {
    setSettingsPopupVisible(!settingsPopupVisible);
  };

  const handleRadioChange = (event) => {
    const value = event?.target?.value;
    setAttendanceSelectedValue(value);
    if (value === ATTENDANCE_SELECTED_VALUES?.ALL) {
      setEmployeeIds('');
    }
  };

  const handleFileChange = async (file: any) => {
    const response: any = await HolidaysUpload(file);

    if (response?.data?.success) {
      setOpenHolidays(false);
      dispatch(
        pushNotification({
          isOpen: true,

          message: response?.data?.message,

          type: CONSTANTS?.SUCCESS,
        })
      );
    } else {
      dispatch(
        pushNotification({
          isOpen: true,

          message: response?.data?.message,

          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  const handleGetHolidaysList = async () => {
    const response: any = await fetchRuntimeFormData(HOLIDAYS_FORMID);
    if (response?.success) {
      const mapData: any = response?.data?.content[0]?.formData?.holidayList;
      setYearsList(Object.keys(mapData));
      setHolidays(mapData);
      const allKeys = Object.keys(mapData);
      setSelectedYear(allKeys?.[allKeys?.length - 1]);
    }
  };

  useEffect(() => {
    handleGetHolidaysList();
  }, [openHolidays]);

  const handleGetLeaveReport = async (startDate, endDate) => {
    setIsloading(true);
    const response = await leaveReportRequest(startDate, endDate);
    if (response?.success) {
      setIsloading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.SUCCESS,
        })
      );
      setStartDate('');
      setEndDate('');
    } else {
      setIsloading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  const handleGetAttendanceReport = async (
    startDate,
    endDate,
    reportType,
    employeeIds
  ) => {
    setIsAttendanceLoading(true);

    const payload = {
      employeeIds: employeeIds
        ? employeeIds?.split(',')?.map((id) => id?.trim())
        : [],
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    };

    const response = await attendanceReportRequest(payload, reportType);

    if (response?.success) {
      setIsAttendanceLoading(false);

      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.SUCCESS,
        })
      );

      setEmployeeIds('');
      setStartDate('');
      setEndDate('');
      setAttendancePopupVisible(false);
    } else {
      setIsAttendanceLoading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  const handleGetLeaveHistory = async (startDate, endDate) => {
    setIsloading(true);
    const response = await leaveHistoryRequest(startDate, endDate);
    if (response?.success) {
      setIsloading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.SUCCESS,
        })
      );
      setStartDate('');
      setEndDate('');
    } else {
      setIsloading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  return (
    <Box className={classes?.screen}>
      <Box className={classes?.containerMain}>
        <Modal
          keepMounted
          open={openHolidays}
          onClose={handleCloseHolidays}
          id="Holidays"
        >
          <Box className={classes?.boxCommon}>
            <FileUploader
              multiple={false}
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
            />
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openLeaveType}
          onClose={handleCloseLeaveType}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className={classes?.boxCommon}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
              className={classes?.fieldTitle}
            >
              Enter Details
            </Typography>
            <Typography
              id="keep-mounted-modal-description"
              className={classes?.headerText}
            >
              <Box className={classes?.fieldInput}>
                <label>Task Type</label>
                <TextField size="small" id="leave-type" />
              </Box>
              <Box className={classes?.fieldInput}>
                <FormGroup className={classes?.rowFlex}>
                  <FormControlLabel control={<Checkbox />} label="Is enable" />
                </FormGroup>
              </Box>
              <Box>
                <Button>Submit</Button>
              </Box>
            </Typography>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openEmployeeType}
          onClose={handleCloseEmployeeType}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className={classes?.boxCommon}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
              className={classes?.fieldTitle}
            >
              Enter Details
            </Typography>
            <Typography
              id="keep-mounted-modal-description"
              className={classes?.headerText}
            >
              <Box className={classes?.fieldInput}>
                <label>Emp Type</label>
                <TextField size="small" id="leave-type" />
              </Box>
              <Box className={classes?.fieldInput}>
                <FormGroup className={classes?.rowFlex}>
                  <FormControlLabel control={<Checkbox />} label="Is enable" />
                </FormGroup>
              </Box>
              <Box>
                <Button>Submit</Button>
              </Box>
            </Typography>
          </Box>
        </Modal>
        <Dialog open={attendancePopupVisible} maxWidth="sm" fullWidth>
          {isAttendanceLoading ? (
            <Box className={classes?.loaderContainer}>
              <CircularProgress size={80} className={classes?.errorText} />
            </Box>
          ) : (
            <>
              <DialogTitle>
                <Box className={classes?.dialogTitleContainer}>
                  <Typography variant="h5">
                    Generate Attendance Report
                  </Typography>
                  <Button
                    className={classes?.closeButton}
                    onClick={() => setAttendancePopupVisible(false)}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box className={classes?.attendanceReportContainer}>
                  <Box className={classes?.attendanceReportRadioContainer}>
                    <RadioGroup
                      row
                      value={attendanceSelectedValue}
                      onChange={handleRadioChange}
                      className={classes?.attendanceReportRadioGroup}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio size="small" />}
                        label="All"
                        className={classes?.attendanceReportRadioButton}
                      />
                      <FormControlLabel
                        value="specific"
                        control={<Radio size="small" />}
                        label="Specific"
                        className={classes?.attendanceReportRadioButton}
                      />
                    </RadioGroup>
                  </Box>
                  {attendanceSelectedValue !==
                    ATTENDANCE_SELECTED_VALUES?.ALL && (
                    <Box className={classes?.attendanceReportFieldContainer}>
                      <TextField
                        label="Employee IDs (comma separated)"
                        variant="outlined"
                        fullWidth
                        value={employeeIds}
                        onChange={(e) => setEmployeeIds(e?.target?.value)}
                        className={classes?.attendanceReportTextField}
                      />
                    </Box>
                  )}
                  <Box className={classes?.attendanceReportFieldContainer}>
                    <FormControl fullWidth>
                      <InputLabel id="report-type-label">
                        Report Type
                      </InputLabel>
                      <Select
                        labelId="report-type-label"
                        value={reportType}
                        label="Report Type"
                        onChange={(e) => setReportType(e?.target?.value)}
                      >
                        <MenuItem value={REPORT_TYPES?.DAILY}>
                          Daily Report
                        </MenuItem>
                        <MenuItem value={REPORT_TYPES?.OVERALL}>
                          Overall Report
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box padding={1}>
                    <Button
                      disabled={!reportType}
                      onClick={() =>
                        handleGetAttendanceReport(
                          startDate,
                          endDate,
                          reportType,
                          employeeIds
                        )
                      }
                      className={classes?.leaveReportButton}
                    >
                      GENERATE REPORT
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </>
          )}
        </Dialog>
        <Dialog open={settingsPopupVisible} maxWidth="sm" fullWidth>
          <DialogTitle>
            <Box className={classes?.dialogTitleContainer}>
              <Typography variant="h5">Attendance Report Settings</Typography>
              <Button
                className={classes?.closeButton}
                onClick={() => setSettingsPopupVisible(false)}
              >
                <CloseIcon />
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={classes?.settingsContainer}>
              <AdminSettings />
            </Box>
          </DialogContent>
        </Dialog>
        <Box padding={5}>
          <Box>
            <Box className={classes?.fieldInput}>
              <Box className={classes?.fieldTitle}>Reports</Box>

              {isLoading ? (
                <Box className={classes?.loaderContainer}>
                  <CircularProgress size={100} className={classes?.errorText} />
                  <Box className={classes?.themeText}>
                    Please wait we are sending the request...
                  </Box>
                </Box>
              ) : (
                <Box className={classes?.leaveReportSection}>
                  <Box className={classes?.datesContainer}>
                    <label>Select Date</label>

                    <Box className={classes?.leaveReportSectionControls}>
                      <Box className={classes?.rowFlex}>
                        <Box className={classes?.datesOuterContainer}>
                          <ThemeProvider theme={theme}>
                            <Box className={classes?.datesInnerContainer}>
                              <Box className={classes?.columnFlex}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    value={startDate ? dayjs(startDate) : null}
                                    disableFuture
                                    onChange={(date: any) => {
                                      const formattedDate = moment(
                                        date?.toISOString()
                                      ).format('YYYY-MM-DD');
                                      setStartDate(formattedDate);
                                      if (
                                        endDate &&
                                        moment(endDate).isBefore(formattedDate)
                                      ) {
                                        setEndDate('');
                                      }
                                    }}
                                    label={'From Date'}
                                    slotProps={{
                                      textField: {
                                        size: 'small',
                                        disabled: true,
                                      },
                                    }}
                                    className={classes?.datePikcerFromDate}
                                    format={'DD/MM/YYYY'}
                                  />
                                </LocalizationProvider>

                                <FormHelperText className={classes?.errorText}>
                                  {}
                                </FormHelperText>
                              </Box>

                              <Box mt={0.5}>to</Box>
                              <Box className={classes?.columnFlex}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    disableFuture
                                    value={endDate ? dayjs(endDate) : null}
                                    onChange={(date: any) => {
                                      setEndDate(
                                        moment(date?.toISOString()).format(
                                          'YYYY-MM-DD'
                                        )
                                      );
                                    }}
                                    label={'To Date'}
                                    minDate={
                                      startDate ? dayjs(startDate) : null
                                    }
                                    slotProps={{
                                      textField: {
                                        size: 'small',
                                        disabled: true,
                                      },
                                    }}
                                    className={classes?.datePickerFromDate}
                                    format={'DD/MM/YYYY'}
                                  />
                                </LocalizationProvider>
                                <FormHelperText className={classes?.errorText}>
                                  {}
                                </FormHelperText>
                              </Box>
                            </Box>
                          </ThemeProvider>
                        </Box>
                        <Box padding={1}>
                          <Button
                            disabled={!startDate || !endDate}
                            onClick={() =>
                              handleGetLeaveReport(startDate, endDate)
                            }
                            className={classes?.leaveReportButton}
                          >
                            LEAVE REPORT
                          </Button>
                        </Box>

                        <Box padding={1}>
                          <Button
                            disabled={!startDate || !endDate}
                            onClick={() =>
                              handleGetLeaveHistory(startDate, endDate)
                            }
                            className={classes?.leaveReportButton}
                          >
                            LEAVE HISTORY
                          </Button>
                        </Box>
                        <Box padding={1}>
                          <Button
                            disabled={!startDate || !endDate}
                            onClick={() => setAttendancePopupVisible(true)}
                            className={classes?.leaveReportButton}
                          >
                            ATTENDANCE REPORT
                          </Button>
                        </Box>
                      </Box>
                      <Tooltip title={'Attendance Report Settings'}>
                        <Button
                          className={classes?.closeButton}
                          onClick={toggleSettingsClick}
                        >
                          <SettingsIcon className={classes?.commonIcon} />
                        </Button>
                      </Tooltip>{' '}
                    </Box>

                    {/* <Box>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Email report"
                    />
                  </Box> */}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Divider />
          <Box className={classes?.sectionContainer}>
            <Box className={classes?.fieldTitle} mb={2}>
              Configure Settings
            </Box>
            <Box className={classes?.boxDataMapContainer}>
              {DataMapCategories?.map((props, index) => (
                <DataMapComponent
                  key={index}
                  header={props?.header}
                  itemKey={props?.itemKey}
                />
              ))}
            </Box>
          </Box>
          <Divider />
          <Box className={classes?.sectionContainer}>
            <Box className={classes?.fieldTitle}>
              Holidays
              <Box className={classes?.yearDropdown}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="year-label">Year</InputLabel>
                  <Select
                    size="small"
                    labelId="year-label"
                    id="yearLabel"
                    value={selectedYear}
                    label="Year"
                    onChange={(e) => {
                      setSelectedYear(e?.target?.value);
                    }}
                    className={classes?.selectYearLabel}
                  >
                    {yearsList?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className="ContainerScrollTm">
              <Box className={classes?.holidaysContainer}>
                {holidays[selectedYear]?.map((item: any, index) => {
                  return (
                    <Box key={item?.Date || index}>
                      <Card className={classes?.holidayCard}>
                        <Box padding={1}>
                          <Box className={classes?.holidayText}>
                            {new Date(item?.Date).toLocaleDateString('en-US', {
                              weekday: 'short',
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                            })}
                          </Box>
                          <Box className={classes?.holidayPurposeText}>
                            {item?.Purpose}
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box padding={1}>
              <Button
                className={classes?.leaveReportButton}
                onClick={handleOpenHolidays}
              >
                Upload Holiday
              </Button>
            </Box>
          </Box>
          {/* <Box display={"flex"} flexDirection={"column"} padding={2}>
            <Box
              className={{
                font: "Roboto",
                fontWeight: 500,
                fontSize: "23px",
                lineHeight: "26.95px",
                color: "#162952",
              }}
              mb={2}
            >
              Configure Leave Type
            </Box>
            <Box>
              <Box display={"flex"} flexDirection={"row"}>
                <Box>
                  <FormGroup className={{ flexDirection: "row" }}>
                    <Box
                      position="relative"
                      onMouseEnter={handleMouseEnterLeaveType}
                      onMouseLeave={handleMouseLeaveLeaveType}
                      minWidth={"8vw"}
                    >
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="General"
                      />
                      {isHoveredLeaveType && (
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className={{ backgroundColor: "#F15830" }}
                        >
                          <Box display="flex" flexDirection={"row"} gap={1}>
                            <Button
                              size="sm"
                              onClick={handleEditLeaveType}
                              style={{
                                backgroundColor: "#F15830",
                                border: "1px solid white",
                              }}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              size="sm"
                              onClick={handleDeleteLeaveType}
                              style={{
                                backgroundColor: "#F15830",
                                border: "1px solid white",
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </FormGroup>
                </Box>
                <MUIButton
                  onClick={handleOpenLeaveType}
                  variant="outlined"
                  startIcon={<AddBoxIcon color="primary" />}
                  className={{ color: "#ACACAC", borderColor: "#ACACAC" }}
                >
                  Add New Leave Type
                </MUIButton>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box display={"flex"} flexDirection={"column"} padding={2}>
            <Box
              className={{
                font: "Roboto",
                fontWeight: 500,
                fontSize: "23px",
                lineHeight: "26.95px",
                color: "#162952",
              }}
              mb={2}
            >
              Configure Employee / Employment Type
            </Box>
            <Box>
              <Box display={"flex"} flexDirection={"row"}>
                <Box>
                  <FormGroup className={{ flexDirection: "row" }}>
                    <Box
                      position="relative"
                      onMouseEnter={handleMouseEnterEmployeeType}
                      onMouseLeave={handleMouseLeaveEmployeeType}
                      minWidth={"8vw"}
                    >
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Intern"
                      />
                      {isHoveredEmployeeType && (
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className={{ backgroundColor: "#F15830" }}
                        >
                          <Box display="flex" flexDirection={"row"} gap={1}>
                            <Button
                              size="sm"
                              onClick={handleEditEmployeeType}
                              style={{
                                backgroundColor: "#F15830",
                                border: "1px solid white",
                              }}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              size="sm"
                              onClick={handleDeleteEmployeeType}
                              style={{
                                backgroundColor: "#F15830",
                                border: "1px solid white",
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </FormGroup>
                </Box>
                <MUIButton
                  onClick={handleOpenEmployeeType}
                  variant="outlined"
                  startIcon={<AddBoxIcon color="primary" />}
                  className={{ color: "#ACACAC", borderColor: "#ACACAC" }}
                >
                  Add New Emp Type
                </MUIButton>
              </Box>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Admin;
