import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import moment from 'moment';
import { CSSProperties, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as Yup from 'yup';
import CONSTANTS, {
  AADHAR_NO,
  EMAIL_REGEX,
  GENERAL_FORMID,
  PAN_NO,
  phoneNumberRegex,
} from '../../constants/constants';
import { getSessionStorageItem } from '../../encrypt';
import { pushNotification, setReportingToEmailId } from '../../redux/actions';
import { fetchApplicationSettings } from '../../services/AdminService';
import { FilterFormDetailsWithOfficalEmail } from '../../services/formService';
import { ReactComponent as BloodGroup } from '../../utils/images/bloodGroup.svg';
import { designationRequriedMsg } from '../ModalViews/HrAdmin/forms/formConstants';
import CustomDatePicker from './CustomDatePicker';
import {
  aadharNumberMsg,
  bloodGroupMsg,
  dobAsPerCertificateMsg,
  dobAsPerCertificateMsg1,
  dobAsPerCertificateMsg2,
  dobAsPerCertificateMsg3,
  empIdMsg1,
  empIdMsg2,
  empTypeMsg,
  firstNameMsg,
  firstNameMsg1,
  genderMsg,
  joiningDateMsg,
  lastNameMsg,
  lastNameMsg1,
  officialEmailMsg,
  officialEmailMsg1,
  panNumberMsg,
  phoneNumberMsg1,
  reportingToMsg,
  reportingToMsg1,
} from './formConstants';
import { BLOOD_TYPES } from '../ModalViews/ModalViewsConstants';

const useStyles = makeStyles(() => ({
  formLableGenderRadio: {
    color: 'black !important',
    fontFamily: 'Roboto !important',
    fontWeight: 'bold !important',
  },
  formControlBloodGroup: { minWidth: 190 },
  formHelperText: { color: 'red' },
  gridPaddingTop2: { paddingTop: '2%' },
  typographyMainHeading: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.4rem) !important',
    },
    '@media (min-width: 1500px) and (max-width: 1999px)': {
      fontSize: 'calc(12px + 0.8rem) !important',
    },
    '@media (min-width: 1000px) and (max-width: 1499px)': {
      fontSize: 'calc(12px + 0.8rem) !important',
    },
    '@media (min-width: 600px) and (max-width: 999px)': {
      fontSize: 'calc(12px + 0.4rem) !important',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.2rem) !important',
    },
  },
  divider: {
    backgroundColor: '#f15830',
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  addBtn: {
    marginTop: '15px',
  },
  createEmployeeBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  PhoneNumber_country_img: {
    height: '1rem',
    width: '1rem',
  },
  phoneInputStyles: {
    width: '100% !important',

    '@media (min-width: 320px )': {
      width: '100% !important',
    },
    '@media (min-width: 375px)': {
      width: '85% !important',
    },
    '@media (min-width: 425px)': {
      width: '85% !important',
    },
    '@media (min-width: 600px)': {
      width: '100% !important',
    },
    '@media (min-width: 768px)': {
      width: '100% !important',
    },
    '@media (min-width: 1440px)': {
      width: '100% !important',
    },
    '@media (min-width: 2560px)': {
      width: '100% !important',
    },
  },
}));

const PersonalForm = (props: any) => {
  const { setOpenFormExitPopup } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [getLoading, setGetLoading] = useState(false);
  const [employeeTypes, setEmployeeTypes] = useState<string[]>([]);
  const color = '#ffffff';
  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: '#4B6D8F',
  };

  const emailId: any = getSessionStorageItem('user-email');
  const [disableAllFields] = useState(true);

  const [countryCode, setCountryCode] = useState('');
  // const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [countryRegex, setCountryRegex] = useState(
    phoneNumberRegex[countryCode]
  );
  const handlePhoneChange = async (value, country) => {
    if (country && 'dialCode' in country) {
      // const countryCode = `+${country.dialCode}`;
      // const phoneNumber = value.slice(countryCode.length);
      // const formattedNumber = `${countryCode} ${phoneNumber}`;
      // setFormattedPhoneNumber(formattedNumber);
    }
    setCountryCode(country?.countryCode);
    setCountryRegex(phoneNumberRegex[country?.countryCode]);
  };
  const formOne = useFormik({
    initialValues: {
      empId: '',
      employeeType: '',
      officialEmail: '',
      firstName: '',
      lastName: '',
      gender: '',
      phoneNumber: '',
      dobAsPerCertificate: '',
      bloodGroup: '',
      panNumber: '',
      aadharNumber: '',
      reportingTo: '',
      dateOfJoining: '',
      designation: '',
    },
    validationSchema: Yup.object({
      empId: Yup.string().required(empIdMsg1).min(3, empIdMsg2),
      employeeType: Yup.string().required(empTypeMsg),
      officialEmail: Yup.string()
        .required(officialEmailMsg)
        .matches(EMAIL_REGEX, officialEmailMsg1),
      firstName: Yup.string().required(firstNameMsg).min(3, firstNameMsg1),
      lastName: Yup.string().required(lastNameMsg).min(3, lastNameMsg1),
      gender: Yup.string().required(genderMsg),
      phoneNumber: Yup.string()
        .required(phoneNumberMsg1)
        .matches(countryRegex, phoneNumberMsg1),
      dobAsPerCertificate: Yup.date()
        .required(dobAsPerCertificateMsg)
        .test(
          dobAsPerCertificateMsg1,
          dobAsPerCertificateMsg2,
          (date) => moment().diff(moment(date), dobAsPerCertificateMsg3) >= 18
        ),

      bloodGroup: Yup.string().required(bloodGroupMsg),

      panNumber: Yup.string()
        .required(panNumberMsg)
        .max(10)
        .matches(PAN_NO)
        .length(10),
      aadharNumber: Yup.string()
        .required(aadharNumberMsg)
        .max(12)
        .matches(AADHAR_NO)
        .length(12),
      reportingTo: Yup.string()
        .required(reportingToMsg)
        .min(3, reportingToMsg1),

      dateOfJoining: Yup.date().required(joiningDateMsg),
      designation: Yup.string().required(designationRequriedMsg),
      // .test(
      //   joiningDateMsg1,
      //   joiningDateMsg2
      //   // (date) => date === moment()
      // ),
    }),

    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);
    // postFormValues(values);
  };

  useEffect(() => {
    prefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      GENERAL_FORMID,
      emailId
    );
    if (response?.success && response?.data?.content?.length > 0) {
      const mapData: any = response?.data?.content?.[0]?.formData;
      dispatch(setReportingToEmailId(mapData?.reportingTo));

      const finalData: any = {
        empId: mapData?.empId,
        employeeType: mapData?.employeeType,
        firstName: mapData?.firstName,
        lastName: mapData?.lastName,
        gender: mapData?.gender,
        phoneNumber: mapData?.phone,
        dobAsPerCertificate: mapData?.dob,
        bloodGroup: mapData?.bloodGroup,
        panNumber: mapData?.panNumber,
        aadharNumber: mapData?.aadharNumber,
        officialEmail: mapData?.officialEmail,
        reportingTo: mapData?.reportingTo,
        dateOfJoining: mapData?.dateOfJoining,
        designation: mapData?.designation,
      };

      formOne?.setValues(finalData);
    } else {
      console.error('Prefill Data Failed');
    }
  };

  const fetchEmployeeOptionsData = async () => {
    setGetLoading(true);

    const response = await fetchApplicationSettings();

    if (response?.success) {
      const tempEmployeeTypes: any = response?.data?.employeeTypes?.map(
        (employeeTypeObj) => employeeTypeObj?.name
      );
      setEmployeeTypes([...tempEmployeeTypes]);
      setGetLoading(false);
    } else {
      setEmployeeTypes([]);
      setGetLoading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  useEffect(() => {
    const caller = async () => {
      await prefillData();
      await fetchEmployeeOptionsData();

      // setFormInitialData(formOne?.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOne?.values]);

  return (
    <>
      <Box>
        <form onSubmit={formOne?.handleSubmit}>
          <Grid container>
            <Typography
              color={'#f15830'}
              width="100%"
              className={classes?.typographyMainHeading}
            >
              General <Divider />
            </Typography>
            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 3, lg: 3, md: 3, sm: 2 }}
              columnSpacing={4}
              rowSpacing={3}
              className={classes?.gridPaddingTop2}
            >
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>Employee Type*</label>
                  <FormControl size="small">
                    <Select
                      labelId="employeeType"
                      id="employee-type"
                      value={formOne?.values?.employeeType}
                      onChange={(e) => {
                        formOne?.setFieldValue(
                          'employeeType',
                          e?.target?.value,
                          true
                        );
                      }}
                      error={
                        formOne?.touched?.employeeType &&
                        Boolean(formOne?.errors?.employeeType)
                      }
                      disabled={disableAllFields}
                    >
                      {getLoading ? (
                        <ClipLoader
                          color={color}
                          loading={getLoading}
                          cssOverride={override}
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      ) : (
                        employeeTypes?.map((type, index) => (
                          <MenuItem key={index} value={type}>
                            {type}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    <FormHelperText className={classes?.formHelperText}>
                      {formOne?.touched?.employeeType &&
                        formOne?.errors?.employeeType}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>Emp ID *</label>
                  <TextField
                    id="emp-id"
                    name="empId"
                    size="small"
                    helperText={
                      formOne?.touched?.empId && formOne?.errors?.empId
                    }
                    error={
                      formOne?.touched?.empId && Boolean(formOne?.errors?.empId)
                    }
                    onBlur={formOne?.handleBlur}
                    onChange={formOne?.handleChange}
                    value={formOne?.values?.empId}
                    // className={{ width: "240px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>Official Email *</label>
                  <TextField
                    size="small"
                    id="officialEmail"
                    name="officialEmail"
                    helperText={
                      formOne?.touched?.officialEmail &&
                      formOne?.errors?.officialEmail
                    }
                    error={
                      formOne?.touched?.officialEmail &&
                      Boolean(formOne?.errors?.officialEmail)
                    }
                    onBlur={formOne?.handleBlur}
                    onChange={formOne?.handleChange}
                    value={formOne?.values?.officialEmail}
                    // className={{ width: "240px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 3, lg: 3, md: 3, sm: 3 }}
              columnSpacing={4}
              rowSpacing={3}
            >
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}> Designation*</label>
                  <TextField
                    id="Designation"
                    name="designation"
                    size="small"
                    helperText={
                      formOne?.touched?.designation &&
                      formOne?.errors?.designation
                    }
                    error={
                      formOne?.touched?.designation &&
                      Boolean(formOne?.errors?.designation)
                    }
                    onBlur={formOne?.handleBlur}
                    onChange={formOne?.handleChange}
                    value={formOne?.values?.designation}
                    // className={{ width: "240px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>
                    {' '}
                    Reporting Manager Email*
                  </label>
                  <TextField
                    id="reporting-to"
                    name="reportingTo"
                    size="small"
                    helperText={
                      formOne?.touched?.reportingTo &&
                      formOne?.errors?.reportingTo
                    }
                    error={
                      formOne?.touched?.reportingTo &&
                      Boolean(formOne?.errors?.reportingTo)
                    }
                    onBlur={formOne?.handleBlur}
                    onChange={formOne?.handleChange}
                    value={formOne?.values?.reportingTo}
                    // className={{ width: "240px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>

              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>Joining Date*</label>
                  <CustomDatePicker
                    value={formOne?.values?.dateOfJoining}
                    handleDateChange={(dateString) => {
                      formOne?.setFieldValue('dateOfJoining', dateString, true);
                    }}
                    datePickerdisabled={disableAllFields}
                    textFieldDisabled={disableAllFields}
                    isError={
                      formOne?.touched?.dateOfJoining &&
                      Boolean(formOne?.errors?.dateOfJoining)
                    }
                    errorText={
                      formOne?.touched?.dateOfJoining &&
                      formOne?.errors?.dateOfJoining
                    }
                    padding={'17.9px 12px'}
                    rest={{}}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 3, lg: 3, md: 3, sm: 3 }}
              columnSpacing={4}
              rowSpacing={3}
            >
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>First Name*</label>
                  <TextField
                    id="first-name"
                    name="firstName"
                    size="small"
                    helperText={
                      formOne?.touched?.firstName && formOne?.errors?.firstName
                    }
                    error={
                      formOne?.touched?.firstName &&
                      Boolean(formOne?.errors?.firstName)
                    }
                    onBlur={formOne?.handleBlur}
                    onChange={formOne?.handleChange}
                    value={formOne?.values?.firstName}
                    // className={{ width: "210px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>Last Name*</label>
                  <TextField
                    size="small"
                    id="last-name"
                    name="lastName"
                    helperText={
                      formOne?.touched?.lastName && formOne?.errors?.lastName
                    }
                    error={
                      formOne?.touched?.lastName &&
                      Boolean(formOne?.errors?.lastName)
                    }
                    onBlur={formOne?.handleBlur}
                    onChange={formOne?.handleChange}
                    value={formOne?.values?.lastName}
                    // className={{ width: "210px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box display={'flex'} flexDirection={'column'}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className={`generalLabel ${classes?.formLableGenderRadio}`}
                    >
                      Gender *
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="gender"
                      id="gender-radio"
                      onBlur={formOne?.handleBlur}
                      onChange={formOne?.handleChange}
                      value={formOne?.values?.gender}
                    >
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label="Male"
                        disabled={disableAllFields}
                      />
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label="Female"
                        disabled={disableAllFields}
                      />

                      <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label="Other"
                        disabled={disableAllFields}
                      />
                    </RadioGroup>
                    <FormHelperText className={classes?.formHelperText}>
                      {formOne?.touched?.gender && formOne?.errors?.gender}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 3, lg: 3, md: 3, sm: 3 }}
              columnSpacing={4}
              rowSpacing={3}
            >
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>Phone*</label>
                  <PhoneInput
                    inputClass={classes?.phoneInputStyles}
                    country={'in'}
                    inputProps={{
                      id: 'phone-number',
                      name: 'phoneNumber',
                    }}
                    onBlur={formOne?.handleBlur}
                    onChange={(value, country) => {
                      handlePhoneChange(value, country);
                      formOne?.handleChange({
                        target: {
                          name: 'phoneNumber',
                          value: value,
                        },
                      });
                    }}
                    isValid={!formOne?.errors?.phoneNumber}
                    disabled={disableAllFields}
                    value={formOne?.values?.phoneNumber}
                  />
                  {formOne?.errors?.phoneNumber && (
                    <div style={{ color: 'red', marginTop: '5px' }}>
                      {formOne?.errors?.phoneNumber}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>
                    DOB (as per Certificate)*
                  </label>
                  <CustomDatePicker
                    value={formOne?.values?.dobAsPerCertificate}
                    errorText={
                      formOne?.touched?.dobAsPerCertificate &&
                      formOne?.errors?.dobAsPerCertificate
                    }
                    isError={
                      formOne?.touched?.dobAsPerCertificate &&
                      Boolean(formOne?.errors?.dobAsPerCertificate)
                    }
                    handleDateChange={(dateString) => {
                      // console?.log("myoff", event);
                      // formOne?.handleChange(dateString);
                      formOne?.setFieldValue(
                        'dobAsPerCertificate',
                        dateString,
                        true
                      );
                      // setButtonDisabled(false);
                    }}
                    datePickerdisabled={true}
                    textFieldDisabled={true}
                    rest={{
                      id: 'dobAsPerCertificate',
                      name: 'dobAsPerCertificate',
                    }}
                    padding={'17.8px 14px'}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>Blood Group *</label>
                  <FormControl
                    className={classes?.formControlBloodGroup}
                    size="small"
                  >
                    <Select
                      labelId="blood-group"
                      id="bloodGroup"
                      value={formOne?.values?.bloodGroup}
                      onChange={(e) => {
                        formOne?.setFieldValue(
                          'bloodGroup',
                          e?.target?.value,
                          true
                        );
                      }}
                      error={
                        formOne?.touched?.bloodGroup &&
                        Boolean(formOne?.errors?.bloodGroup)
                      }
                      disabled={disableAllFields}
                    >
                      {BLOOD_TYPES?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item} <BloodGroup />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className={classes?.formHelperText}>
                      {formOne?.touched?.bloodGroup &&
                        formOne?.errors?.bloodGroup}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 2, lg: 2, md: 2, sm: 2 }}
              columnSpacing={4}
              rowSpacing={3}
            >
              <Grid xl={6} lg={6} md={6} sm={6} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>PAN Number *</label>
                  <TextField
                    id="pan-Number"
                    name="panNumber"
                    size="small"
                    helperText={
                      formOne?.touched?.panNumber && formOne?.errors?.panNumber
                    }
                    error={
                      formOne?.touched?.panNumber &&
                      Boolean(formOne?.errors?.panNumber)
                    }
                    onBlur={formOne?.handleBlur}
                    onChange={formOne?.handleChange}
                    value={formOne?.values?.panNumber}
                    // className={{ width: "345px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={6} lg={6} md={6} sm={6} item>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className="generalLabel"
                >
                  <label className={classes?.label}>Aadhaar Number *</label>
                  <TextField
                    id="aadhar-Number"
                    name="aadharNumber"
                    size="small"
                    helperText={
                      formOne?.touched?.aadharNumber &&
                      formOne?.errors?.aadharNumber
                    }
                    error={
                      formOne?.touched?.aadharNumber &&
                      Boolean(formOne?.errors?.aadharNumber)
                    }
                    onBlur={formOne?.handleBlur}
                    onChange={formOne?.handleChange}
                    value={formOne?.values?.aadharNumber}
                    // className={{ width: "345px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default PersonalForm;
