import { PUSH_NOTIFICATION } from '../constants/actions';
import COMMON from '../constants/common';
import {
  ACCESS_TOEKN,
  LOADER,
  OFFICIALEMAILID,
  REPORTINGTOEMAILID,
  SET_PROFILEPICTURE,
  SET_PROFILEPICTURE_REFRESH,
  SET_ROLE,
  SET_ROLES,
  SET_USERDATA,
  SET_VISITORS,
  UPDATE_VISITOR,
  UPDATE_LOCATION,
  SET_ALL_EMPLOYEES_DATA,
  SET_EXPENSE_REQUESTS,
  UPDATE_EXPENSE_REQUEST,
} from './actions';

// Reducer

export interface NotificationProps {
  isOpen: boolean;
  type?: 'error' | 'info' | 'success' | 'warning';
  message?: string;
  handleClose?: () => void;
}
const initialState: any = {
  isLoading: false,
  notification: {},
  isAnonymous: true,
  access_token: '',
  officialEmailId: '',
  roles: [],
  role: '',
  profilePicture: '',
  profilePictureRefresh: false,
  userData: undefined,
  allEmployeesData: {},
  reportingToEmailId: '',
  items: {},
  expenses: {},
  address: '',
};

export const loadingActionReducer = (state = initialState, action) => {
  switch (action?.type) {
    case LOADER:
      return {
        ...state,
        isLoading: action?.payload,
      };

    case PUSH_NOTIFICATION:
      return { ...state, notification: action?.payload };

    case COMMON?.ANONYMOUS:
      return {
        ...state,
        isAnonymous: action?.payload,
      };

    case UPDATE_LOCATION:
      return { ...state, address: action?.payload };

    case ACCESS_TOEKN:
      return {
        ...state,
        access_token: action?.payload,
      };

    case OFFICIALEMAILID:
      return {
        ...state,
        officialEmailId: action?.payload,
      };

    case SET_ROLES:
      return {
        ...state,
        roles: action?.payload,
      };

    case SET_ROLE:
      return {
        ...state,
        role: action?.payload,
      };

    case SET_PROFILEPICTURE:
      return {
        ...state,
        profilePicture: action?.payload,
      };

    case SET_PROFILEPICTURE_REFRESH:
      return {
        ...state,
        profilePictureRefresh: action?.payload,
      };

    case SET_USERDATA:
      return {
        ...state,
        userData: action?.payload,
      };
    case SET_ALL_EMPLOYEES_DATA:
      return {
        ...state,
        allEmployeesData: action?.payload,
      };

    case REPORTINGTOEMAILID:
      return {
        ...state,
        reportingToEmailId: action?.payload,
      };
    case SET_VISITORS:
      return {
        ...state,
        items: action?.payload,
      };
    case UPDATE_VISITOR: {
      const { id, data } = action?.payload;
      return {
        ...state,
        items: {
          ...state?.items,
          [id]: {
            ...state?.items?.[id],
            ...data,
          },
        },
      };
    }
    case SET_EXPENSE_REQUESTS:
      return {
        ...state,
        expenses: action?.payload || {},
      };
    case UPDATE_EXPENSE_REQUEST: {
      const { id, data } = action?.payload;
      return {
        ...state,
        expenses: {
          ...state?.expenses,
          [id]: {
            ...state?.expenses?.[id],
            ...data,
          },
        },
      };
    }

    default:
      return state;
  }
};
