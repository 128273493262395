import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useCustomStyles from '../../components/common/styles/CustomStylesHook';
import TsDatagrid from '../../components/TsDatagrid';
import CONSTANTS from '../../constants/constants';
import { DOCUMENTS_UPLOAD_FORM_ID } from '../../constants/formConstants';
import { getSessionStorageItem } from '../../encrypt';
import { pushNotification } from '../../redux/actions';
import { FilterFormDetailsWithOfficalEmail } from '../../services/formService';
import { COMMON_CONSTANTS } from '../adminConstants';
import { expenseTrackerTabTypeEnums } from '../uiElementsAccess';
import {
  ButtonConstants,
  ExpenseRequestModel,
  StatusConstants,
} from './types/ExpenseTrackerTypes';
import { getSupportedDocsForThisExpense } from './utils/expenseUtils';
import { getShortFormattedDateTime } from '../../utils/DateOperations';

const styles = () => ({
  dataGridStyles: {
    minHeight: '500px',
  },
  containerMain: {
    borderRadius: '12px !important',
    padding: '8px !important',
    boxShadow: '0 0 4px #777 !important',
  },
  expenseRequestTimeHeader: {
    containerMain: {
      display: 'flex !important',
      justifyContent: 'start !important',
      alignItems: 'center !important',
      gap: '18px !important',
    },
    datePicker: {
      '& .MuiOutlinedInput-input': {
        padding: '3px 8px !important',
        fontSize: '14px !important',
        minWidth: '90px !important',
        maxWidth: '90px !important',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '16px !important',
        display: 'block !important',
      },
    },
  },
  statusContainerMain: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    minWidth: '250px !important',
    gap: '20px !important',
    '& .MuiSvgIcon-root': {
      fontSize: '16px !important',
      display: 'block !important',
    },
  },
  statusOption: {
    display: 'flex !important',
    justifyContent: 'start !important',
    alignItems: 'center !important',
    padding: '6px 12px !important',
    fontSize: '14px !important',
    cursor: 'pointer !important',
    '&:hover': {
      background: '#e9e9e9 !important',
    },
  },
  tstatusTxtField: {
    width: '100% !important',
    flexGrow: '1 !important',
    fontSize: '10px !important',
    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      padding: '0px !important',
      fontSize: '14px !important',
    },
  },
  statusLabel: {
    fontSize: '14px !important',
    padding: '3px 8px !important',
    borderRadius: '50px !important',
    minWidth: '8.5rem !important',
    minHeight: '2.3rem !important',
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    textAlign: 'center !important',
  },
  actionContainerMain: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    gap: '12px !important',
    height: '100% !important',
    width: '100% !important',
  },
  actionButtonApprove: {
    fontSize: '14px !important',
    textTransform: 'capitalize !important',
    borderRadius: '1000px !important',
    background: '#02921a',
    minWidth: '6.75rem !important',
    '&:hover': {
      background: '#0b771d !important',
    },
  },
  actionButtonReject: {
    fontSize: '14px !important',
    textTransform: 'capitalize !important',
    borderRadius: '1000px !important',
    background: '#ad2d17',
    minWidth: '6.75rem !important',
    '&:hover': {
      background: '#811a08 !important',
    },
  },
  actionButtonRevoke: {
    fontSize: '14px !important',
    textTransform: 'capitalize !important',
    borderRadius: '1000px !important',
    minWidth: '6.75rem !important',
    backgroundColor: '#ffffff',
    color: '#a50000 !important',
    '&:hover': {
      background: '#f1dede !important',
    },
  },
  actionbuttonDocs: {
    fontSize: '14px !important',
    textTransform: 'capitalize !important',
    borderRadius: '1000px !important',
    minWidth: '6.75rem !important',
    backgroundColor: '#2b5f6b !important',
    color: '#d3d3d3 !important',
    padding: '0.4rem 4rem',
    '&:hover': {
      background: '#143d46 !important',
    },
    '&:disabled': {
      backgroundColor: '#dfdfdf !important',
      color: '#a0a0a0 !important',
      cursor: 'not-allowed',
    },
  },
  buttonDecision: {
    textTransform: 'capitalize !important',
    borderRadius: '500px !important',
    background: '#c54736',
    minWidth: '6.75rem !important',
    '&:hover': {
      background: '#942c1e !important',
    },
  },
  datagridOverlayStyle: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    height: '100% !important',
  },
  loaderStyle: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
  },
  expenseRequestExpenseRequestPrimaryDetails: {
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'center !important',
    alignItems: 'start !important',
    width: '100% !important',
  },
  expenseRequestElipsesTypography: {
    maxWidth: '12rem !important',
    lineHeight: 'normal !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    display: '-webkit-box !important',
    WebkitLineClamp: 1,
    whiteSpace: 'nowrap !important',
  },
  expenseRequestStatusContainer: {
    width: '100% !important',
    height: '26px !important',
    fontSize: '10px !important',
  },
  boldFont: {
    fontWeight: 'bold !important',
    fontSize: '1rem !important',
  },
  flexCenter: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  occupyEntireHeight: {
    height: '100% !important',
  },

  paginationPaginationMain: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    gap: '12px !important',
    margin: '0 24px !important',
  },
  paginationRowsPerPageContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    gap: '12px !important',
  },
  paginationRowsPerPageText: {
    fontSize: '14px !important',
    whiteSpace: 'nowrap !important',
  },
  paginationSelectBox: {
    height: '30px !important',
    fontSize: '14px !important',
    '& .MuiOutlinedInput-root, .MuiSelect-root': {
      border: '1px solid red !important',
    },
  },
  paginationPageInfoContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    gap: '4px !important',
  },
  paginationPageText: {
    fontSize: '14px !important',
  },
  paginationNavigationContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    gap: '6px !important',
  },
  paginationArrowIcon: {
    fontSize: '16px !important',
    borderRadius: '100% !important',
    height: '24px !important',
    width: '24px !important',
    padding: '3px !important',
    transition: 'all 0.3s !important',
    color: '#000 !important',
    cursor: 'pointer !important',
    '&:hover': {
      background: '#00000042 !important',
    },
  },
  paginationDisabledIcon: {
    color: '#c9c9c9 !important',
    cursor: 'default !important',
    '&:hover': {
      background: 'transparent !important',
    },
  },
});

interface ExpenseTrackerLogTableProps {
  isBusinessUnit: any;
  isFinanceTeam: any;
  rows: any;
  handleRowsPerPageChange: any;
  page: any;
  handlePageChange: any;
  rowsPerPageArray: any;
  rowsPerPage: any;
  containerStyle?: any;
  statusOptions: any;
  expenseRequestStatus: any;
  setExpenseRequestStatus: any;
  isLoading: any;
  onClickNextPage: any;
  onClickPreviousPage: any;
  handleDecision: any;
  isSearchEmpty: any;
  selectedTab: any;
  setDocsModalVisibility: any;
  setCurrentAllDocs: any;
  isManager: boolean;
}

const getStatusProperties = (status: ExpenseRequestModel['status']) => {
  switch (status) {
    case StatusConstants?.APPROVED:
      return {
        label: ButtonConstants?.APPROVED,
        backgroundColor: '#d9ffd1',
        textColor: '#108a10',
      };
    case StatusConstants?.PAID:
      return {
        label: ButtonConstants?.PAID,
        backgroundColor: '#d9ffd1',
        textColor: '#108a10',
      };
    case StatusConstants?.REJECTED:
      return {
        label: ButtonConstants?.REJECTED,
        backgroundColor: '#fadddd',
        textColor: '#CC0000',
      };
    case StatusConstants?.REVOKED:
      return {
        label: ButtonConstants?.REVOKED,
        backgroundColor: '#ffffff',
        textColor: '#a50000',
        border: '1px solid #a50000',
      };
    default:
      return {
        label: 'Pending',
        backgroundColor: '#ffecd9',
        textColor: '#c47427',
      };
  }
};

function filterApprovedRequests(
  requests: any,
  selectedTab: any,
  isBusinessUnit: boolean,
  isFinanceTeam: boolean
) {
  const email = getSessionStorageItem(CONSTANTS?.USER_EMAIL);

  if (
    (isBusinessUnit || isFinanceTeam) &&
    selectedTab === expenseTrackerTabTypeEnums?.allRequests
  ) {
    return requests;
  }
  if (selectedTab === expenseTrackerTabTypeEnums?.myRequests) {
    return requests?.filter((request) => request?.officialEmail === email);
  }
  if (selectedTab === expenseTrackerTabTypeEnums?.teamRequests) {
    return requests;
  }
}

const ExpenseTrackerLogTable = ({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  isBusinessUnit,
  isFinanceTeam,
  rows,
  handleRowsPerPageChange,
  page,
  handlePageChange,
  rowsPerPageArray,
  containerStyle = {},
  isLoading,
  onClickNextPage,
  onClickPreviousPage,
  rowsPerPage,
  handleDecision,
  isSearchEmpty,
  selectedTab,
  setDocsModalVisibility,
  setCurrentAllDocs,
  isManager,
}: /* eslint-enable @typescript-eslint/no-unused-vars */
ExpenseTrackerLogTableProps) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [localRows, setLocalRows] = useState<any>([]);
  const getTableHeader = (title: any) => {
    return <Typography className={classes?.boldFont}>{title}</Typography>;
  };

  const handleDocClick = async (params: any, dispatch: any) => {
    if (isNoAttachments(params)) {
      showTooltipMessage(COMMON_CONSTANTS?.NO_SUPPORTING_DOCUMENTS_UPLOADED);
      return;
    }
    await setLoading(true);
    const response = await fetchDocumentDetails(params);
    if (response?.success && response?.data?.content?.length > 0) {
      const fetchedSupportingDocs = await fetchSupportingDocs(
        response?.data?.content?.[0]?.formData?.files,
        params?.row?.attachments
      );

      if (params?.row?.attachments?.length !== fetchedSupportingDocs?.length) {
        const notification = {
          isOpen: true,
          message: COMMON_CONSTANTS?.DOCUMENTS_MISSING,
          type: CONSTANTS?.ERROR,
        };

        dispatch(pushNotification(notification));
      }
      const tempBuffer = formatDocumentBuffer(fetchedSupportingDocs);
      setCurrentAllDocs([...tempBuffer]);
      setDocsModalVisibility(true);
    } else {
      handleErrorResponse(response, dispatch);
    }
    await setLoading(true);
  };

  // Check if there are no attachments
  const isNoAttachments = (params: any) => {
    return !params?.row?.attachments || params?.row?.attachments?.length <= 0;
  };

  // Fetch document details with official email
  const fetchDocumentDetails = async (params: any) => {
    return FilterFormDetailsWithOfficalEmail(
      DOCUMENTS_UPLOAD_FORM_ID,
      params?.row?.officialEmail
    );
  };

  // Fetch the supporting documents for the expense
  const fetchSupportingDocs = async (files: any, existingAttachments: any) => {
    return getSupportedDocsForThisExpense(files, existingAttachments);
  };

  // Format the documents into a temporary buffer
  const formatDocumentBuffer = (fetchedSupportingDocs: any) => {
    return (
      fetchedSupportingDocs?.map((mfile: any) => ({
        name: mfile?.fileName,
        id: mfile?.documentId,
        fileType: mfile?.fileType,
      })) || []
    );
  };

  // Handle error response
  const handleErrorResponse = (response: any, dispatch: any) => {
    const notification = {
      isOpen: true,
      message: response?.message,
      type: response?.success ? CONSTANTS?.SUCCESS : CONSTANTS?.ERROR,
    };

    dispatch(pushNotification(notification));
  };

  // Display tooltip message
  const showTooltipMessage = (message: string) => {
    const notification = {
      isOpen: true,
      message,
      type: CONSTANTS?.ERROR,
    };
    dispatch(pushNotification(notification));
  };

  useEffect(() => {
    let tempRows = rows;
    if (isBusinessUnit || isFinanceTeam) {
      tempRows = filterApprovedRequests(
        rows,
        selectedTab,
        isBusinessUnit,
        isFinanceTeam
      );
    }
    setLocalRows([...tempRows]);
  }, [rows, isBusinessUnit, isFinanceTeam, selectedTab]);
  const columns: GridColDef[] = [
    {
      field: 'employeeName',
      headerName: 'Name',
      flex: 1,
      sortable: false,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'expenseType',
      headerName: 'Expense Type',
      sortable: false,
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 1,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 200,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueGetter: (params) => {
        return params?.row?.description === ''
          ? COMMON_CONSTANTS?.DEFAULT_VALUE
          : params?.row?.description;
      },
    },
    {
      field: 'amountRequested',
      headerName: 'Amount Requested',
      sortable: false,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 200,
      hide:
        isFinanceTeam &&
        selectedTab === expenseTrackerTabTypeEnums?.allRequests,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (params) => {
        return getAmountComponent(
          classes,
          params?.row?.amountRequested,
          params,
          false
        );
      },
    },
    {
      field: 'amountApproved',
      headerName: 'Amount Approved',
      sortable: false,
      editable: false,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (params) => {
        const amountApproved = params?.row?.amountApproved;
        if (!amountApproved) {
          return COMMON_CONSTANTS?.DEFAULT_VALUE;
        }

        return getAmountComponent(
          classes,
          params?.row?.amountApproved,
          params,
          true
        );
      },
    },
    {
      field: 'managerApproval',
      headerName: 'RM Approval Status',
      sortable: false,
      hide: !(
        selectedTab === expenseTrackerTabTypeEnums?.allRequests ||
        selectedTab === expenseTrackerTabTypeEnums?.myRequests ||
        selectedTab === expenseTrackerTabTypeEnums?.teamRequests
      ),
      minWidth: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: (props) => {
        return (
          <Box className={classes?.statusContainerMain}>
            <Tooltip
              title={COMMON_CONSTANTS?.REPORTING_MANAGER_APPROVAL_STATUS}
              arrow
            >
              {getTableHeader(props?.colDef?.headerName)}
            </Tooltip>
          </Box>
        );
      },
      renderCell: (params) => {
        const statusProperties = getStatusProperties(params?.value?.status);
        const isCancelled =
          params?.row?.requestStatus === COMMON_CONSTANTS?.INACTIVE;
        return (
          <Tooltip
            title={
              isCancelled
                ? COMMON_CONSTANTS?.DEFAULT_VALUE
                : params?.value?.comments
            }
            arrow
          >
            <Typography
              className={classes?.statusLabel}
              style={{
                color: statusProperties?.textColor,
                background: statusProperties?.backgroundColor,
                border: statusProperties?.border,
              }}
            >
              {isCancelled
                ? COMMON_CONSTANTS?.DEFAULT_VALUE
                : statusProperties?.label}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'businessUnitApproval',
      headerName: 'BU Approval Status',
      sortable: false,
      editable: false,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: (props) => {
        return (
          <Box className={classes?.statusContainerMain}>
            <Tooltip
              title={COMMON_CONSTANTS?.BUSINESS_UNIT_APPROVAL_STATUS}
              arrow
            >
              {getTableHeader(props?.colDef?.headerName)}
            </Tooltip>
          </Box>
        );
      },
      renderCell: (params) => {
        const statusProperties = getStatusProperties(params?.value?.status);
        const isCancelled = params?.row?.requestStatus === 'Inactive';
        return (
          <Tooltip
            title={
              isCancelled
                ? COMMON_CONSTANTS?.DEFAULT_VALUE
                : params?.value?.comments
            }
            arrow
          >
            <Typography
              className={classes?.statusLabel}
              style={{
                color: statusProperties?.textColor,
                background: statusProperties?.backgroundColor,
                border: statusProperties?.border,
              }}
            >
              {isCancelled
                ? COMMON_CONSTANTS?.DEFAULT_VALUE
                : statusProperties?.label}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      hide: !(
        selectedTab === expenseTrackerTabTypeEnums?.allRequests ||
        selectedTab === expenseTrackerTabTypeEnums?.myRequests
      ),
      sortable: false,
      minWidth: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: (props) => {
        return (
          <Box className={classes?.statusContainerMain}>
            <Tooltip title={COMMON_CONSTANTS?.ODOO_PAYMENT_STATUS} arrow>
              {getTableHeader(props?.colDef?.headerName)}
            </Tooltip>
          </Box>
        );
      },
      renderCell: (params) => {
        const statusProperties = getStatusProperties(params?.value);
        const isCancelled = params?.row?.requestStatus === 'Inactive';
        return (
          <Typography
            className={classes?.statusLabel}
            style={{
              color: statusProperties?.textColor,
              background: statusProperties?.backgroundColor,
              border: statusProperties?.border,
            }}
          >
            {isCancelled
              ? COMMON_CONSTANTS?.DEFAULT_VALUE
              : statusProperties?.label}
          </Typography>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created Date-Time',
      width: 200,
      sortable: false,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 250,
      maxWidth: 350,
      valueFormatter: (row: any) =>
        getShortFormattedDateTime(new Date(row?.value))?.dateString,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated Date-Time',
      width: 200,
      sortable: false,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 250,
      maxWidth: 350,
      valueFormatter: (row: any) => {
        const updatedAt = row?.value;
        if (!updatedAt || isNaN(new Date(updatedAt)?.getTime())) {
          return COMMON_CONSTANTS?.DEFAULT_VALUE;
        }

        return getShortFormattedDateTime(new Date(updatedAt))?.dateString;
      },
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'attachments',
      headerName: 'Supporting Documents',
      sortable: false,
      editable: false,
      headerAlign: 'center',
      minWidth: 250,
      align: 'center',
      flex: 1,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (params) => {
        const isDocumentsExists =
          params?.row?.attachments && params?.row?.attachments?.length > 0;
        return (
          <>
            <Box className={classes?.actionContainerMain}>
              <Button
                variant="contained"
                className={classes?.actionbuttonDocs}
                disabled={!isDocumentsExists}
                onClick={async () => {
                  if (isDocumentsExists) {
                    handleDocClick(params, dispatch);
                  }
                }}
              >
                {isDocumentsExists ? 'View' : 'No documents uploaded'}
              </Button>
            </Box>
          </>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      editable: false,
      minWidth: 300,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      hide: selectedTab === expenseTrackerTabTypeEnums?.myRequests,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (params) => {
        return getActionComponent(
          params,
          isManager,
          selectedTab,
          isBusinessUnit,
          isFinanceTeam,
          classes,
          handleDecision
        );
      },
    },
    {
      field: 'userActions',
      headerName: 'Actions',
      editable: false,
      headerAlign: 'center',
      minWidth: 200,
      align: 'center',
      flex: 1,
      hide:
        selectedTab === expenseTrackerTabTypeEnums?.allRequests ||
        selectedTab === expenseTrackerTabTypeEnums?.teamRequests,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (params: any) => {
        const disableButton =
          params?.row?.requestStatus === StatusConstants?.INACTIVE ||
          params?.row?.managerApproval?.status;

        const getLabel = () => {
          if (
            disableButton &&
            params?.row?.requestStatus === StatusConstants?.INACTIVE
          ) {
            return COMMON_CONSTANTS?.CANCELLED;
          }
          return COMMON_CONSTANTS?.CANCEL;
        };
        return (
          <Box>
            <Button
              variant="contained"
              className={classes?.buttonDecision}
              onClick={() => handleDecision(params, StatusConstants?.INACTIVE)}
              disabled={disableButton}
            >
              {getLabel()}
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box className={`${classes?.containerMain}, ${containerStyle}`}>
      {loading && (
        <Dialog
          open={loading}
          a-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Loading</DialogTitle>
          <DialogContent className={classes?.loaderStyle}>
            <CircularProgress size={40} />
          </DialogContent>
        </Dialog>
      )}
      <TsDatagrid
        columns={columns}
        totalElements={localRows?.length}
        rows={localRows}
        isCheckboxSelection={false}
        rowHeight={70}
        hideFooter={false}
        rowsPerPageOptions={rowsPerPageArray}
        onPageSizeChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        components={{
          NoRowsOverlay: () => (
            <Box className={classes?.datagridOverlayStyle}>
              {isLoading
                ? COMMON_CONSTANTS?.LOADING_EXPENSES
                : COMMON_CONSTANTS?.NO_EXPENSES_FOUND}
            </Box>
          ),
        }}
        className={classes?.dataGridStyles}
      />
    </Box>
  );
};

export default ExpenseTrackerLogTable;
function getActionComponent(
  params,
  isManager: boolean,
  selectedTab: any,
  isBusinessUnit: any,
  isFinanceTeam: any,
  classes: { [key: string]: string } | null,
  handleDecision: any
) {
  const currentRequest = params?.row;
  const isRequestInactive =
    currentRequest?.requestStatus === StatusConstants?.INACTIVE;

  const isStatusInvalid = (status) =>
    status === StatusConstants?.REJECTED || status === StatusConstants?.REVOKED;

  const getIsShowApproveButton = () => {
    if (isRequestInactive) {
      return false;
    }
    if (
      isFinanceTeam &&
      selectedTab !== expenseTrackerTabTypeEnums?.teamRequests
    ) {
      return false;
    }

    const managerStatus = currentRequest?.managerApproval?.status;
    const businessUnitStatus = currentRequest?.businessUnitApproval?.status;

    if (isManager && selectedTab === expenseTrackerTabTypeEnums?.teamRequests) {
      return !isStatusInvalid(managerStatus);
    }

    if (
      isManager &&
      isBusinessUnit &&
      selectedTab === expenseTrackerTabTypeEnums?.allRequests
    ) {
      return (
        !isStatusInvalid(managerStatus) && !isStatusInvalid(businessUnitStatus)
      );
    }

    if (isManager && selectedTab === expenseTrackerTabTypeEnums?.allRequests) {
      return !isStatusInvalid(managerStatus);
    }

    if (
      isBusinessUnit &&
      selectedTab === expenseTrackerTabTypeEnums?.allRequests
    ) {
      return !isStatusInvalid(businessUnitStatus);
    }

    return true;
  };

  const getIsShowRejectButton = () => {
    if (isRequestInactive) {
      return false;
    }
    if (
      isFinanceTeam &&
      selectedTab !== expenseTrackerTabTypeEnums?.teamRequests
    ) {
      return false;
    }
    if (isManager && selectedTab === expenseTrackerTabTypeEnums?.teamRequests) {
      if (currentRequest?.managerApproval?.status) {
        if (
          currentRequest?.managerApproval?.status ===
            StatusConstants?.APPROVED ||
          currentRequest?.managerApproval?.status === StatusConstants?.REVOKED
        ) {
          return false;
        }
      }
    }
    if (isManager && selectedTab === expenseTrackerTabTypeEnums?.allRequests) {
      if (currentRequest?.managerApproval?.status) {
        if (
          currentRequest?.managerApproval?.status === StatusConstants?.REVOKED
        ) {
          return false;
        }
      }
    }
    if (
      isBusinessUnit &&
      selectedTab === expenseTrackerTabTypeEnums?.allRequests
    ) {
      if (currentRequest?.businessUnitApproval?.status) {
        if (
          currentRequest?.businessUnitApproval?.status ===
            StatusConstants?.APPROVED ||
          currentRequest?.businessUnitApproval?.status ===
            StatusConstants?.REVOKED
        ) {
          return false;
        }
      }
    }
    return true;
  };
  const getIsShowRevokeButton = () => {
    if (isRequestInactive) {
      return false;
    }
    if (isManager && selectedTab === expenseTrackerTabTypeEnums?.teamRequests) {
      if (!currentRequest?.managerApproval?.status) {
        return false;
      }
      if (
        currentRequest?.managerApproval?.status === StatusConstants?.REJECTED
      ) {
        return false;
      }
      if (currentRequest?.businessUnitApproval) {
        return false;
      }
    }
    if (isManager && selectedTab === expenseTrackerTabTypeEnums?.allRequests) {
      if (!currentRequest?.managerApproval?.status) {
        return false;
      }
      if (
        currentRequest?.managerApproval?.status === StatusConstants?.REVOKED
      ) {
        return true;
      }
      if (currentRequest?.businessUnitApproval) {
        return false;
      }
    }
    if (
      isBusinessUnit &&
      selectedTab === expenseTrackerTabTypeEnums?.allRequests
    ) {
      return false;
    }

    return true;
  };
  const getIsShowPaidButton = () => {
    if (isRequestInactive) {
      return false;
    }
    if (
      !isFinanceTeam ||
      selectedTab !== expenseTrackerTabTypeEnums?.allRequests
    ) {
      return false;
    }

    return true;
  };
  const getIsDisableApproveButton = () => {
    if (
      isManager &&
      selectedTab === expenseTrackerTabTypeEnums?.teamRequests &&
      currentRequest?.managerApproval?.status &&
      currentRequest?.managerApproval?.status === StatusConstants?.APPROVED
    ) {
      return true;
    }
    if (
      isBusinessUnit &&
      selectedTab === expenseTrackerTabTypeEnums?.allRequests &&
      currentRequest?.businessUnitApproval?.status &&
      currentRequest?.businessUnitApproval?.status === StatusConstants?.APPROVED
    ) {
      return true;
    }
    return false;
  };
  const getIsDisableRejectButton = () => {
    if (
      isManager &&
      currentRequest?.managerApproval?.status &&
      currentRequest?.managerApproval?.status === StatusConstants?.REJECTED
    ) {
      return true;
    }
    if (
      isBusinessUnit &&
      currentRequest?.businessUnitApproval?.status &&
      currentRequest?.businessUnitApproval?.status === StatusConstants?.REJECTED
    ) {
      return true;
    }
    return false;
  };
  const getIsDisableRevokeButton = () => {
    if (isManager && selectedTab === expenseTrackerTabTypeEnums?.teamRequests) {
      if (currentRequest?.managerApproval?.status) {
        if (
          currentRequest?.managerApproval?.status === StatusConstants?.REVOKED
        ) {
          return true;
        }
      }

      return false;
    }
    if (isManager && selectedTab === expenseTrackerTabTypeEnums?.allRequests) {
      if (currentRequest?.managerApproval?.status) {
        if (
          currentRequest?.managerApproval?.status === StatusConstants?.REVOKED
        ) {
          return true;
        }
      }

      return false;
    }
    if (
      isBusinessUnit &&
      selectedTab === expenseTrackerTabTypeEnums?.allRequests
    ) {
      if (currentRequest?.businessUnitApproval?.status) {
        if (
          currentRequest?.businessUnitApproval?.status ===
          StatusConstants?.REVOKED
        ) {
          return true;
        }
      }

      return false;
    }
    return false;
  };
  const getIsDisablePaidButton = () => {
    if (
      currentRequest?.paymentStatus === StatusConstants?.PAID ||
      currentRequest?.businessUnitApproval?.status !== StatusConstants?.APPROVED
    ) {
      return true;
    }
    return false;
  };

  const isShowApproveButton = getIsShowApproveButton();
  const isShowRejectButton = getIsShowRejectButton();
  const isShowRevokeButton = !isFinanceTeam && getIsShowRevokeButton();
  const isShowPaidButton = isFinanceTeam && getIsShowPaidButton();
  const isDisableApproveButton = getIsDisableApproveButton();
  const isDisableRejectButton = getIsDisableRejectButton();
  const isDisableRevokeButton = getIsDisableRevokeButton();
  const isDisablePaidButton = getIsDisablePaidButton();
  return (
    <>
      <Box className={classes?.actionContainerMain}>
        {isShowApproveButton && (
          <Button
            variant="contained"
            className={classes?.actionButtonApprove}
            onClick={() => {
              handleDecision(params, StatusConstants?.APPROVED);
            }}
            disabled={isDisableApproveButton}
          >
            {isDisableApproveButton
              ? COMMON_CONSTANTS?.APPROVED
              : COMMON_CONSTANTS?.APPROVE}
          </Button>
        )}
        {isShowRejectButton && (
          <Button
            variant="contained"
            className={classes?.actionButtonReject}
            onClick={() => {
              handleDecision(params, StatusConstants?.REJECTED);
            }}
            disabled={isDisableRejectButton}
          >
            {isDisableRejectButton
              ? COMMON_CONSTANTS?.REJECTED
              : COMMON_CONSTANTS?.REJECT}
          </Button>
        )}
        {isShowRevokeButton && (
          <Button
            variant="contained"
            className={classes?.actionButtonRevoke}
            onClick={() => {
              handleDecision(params, StatusConstants?.REVOKED);
            }}
            disabled={isDisableRevokeButton}
          >
            {isDisableRevokeButton
              ? COMMON_CONSTANTS?.REVOKED
              : COMMON_CONSTANTS?.REVOKE}
          </Button>
        )}
        {isShowPaidButton && (
          <Button
            variant="contained"
            className={classes?.actionButtonApprove}
            onClick={() => {
              handleDecision(params, StatusConstants?.PAID);
            }}
            disabled={isDisablePaidButton}
          >
            {currentRequest?.paymentStatus === StatusConstants?.PAID
              ? COMMON_CONSTANTS?.PAID
              : COMMON_CONSTANTS?.CONFIRM_PAYMENT}
          </Button>
        )}
        {isRequestInactive && (
          <Button
            variant="contained"
            className={classes?.actionButtonRevoke}
            onClick={() => {
              handleDecision(params, StatusConstants?.INACTIVE);
            }}
            disabled={isRequestInactive}
          >
            {isRequestInactive && COMMON_CONSTANTS?.CANCELLED}
          </Button>
        )}
      </Box>
    </>
  );
}

function getAmountComponent(
  classes: { [key: string]: string } | null,
  amount,
  params,
  showTooltip
) {
  const getTooltipTitle = () => {
    if (!showTooltip) return '';

    const amountDeviatedRM =
      params?.row?.amountRequested -
      params?.row?.managerApproval?.deviationAmount;
    const deviationReasonRM = params?.row?.managerApproval?.deviationReason
      ? ` due to ${params?.row?.managerApproval?.deviationReason}`
      : '';

    const amountDeviatedBU = params?.row?.businessUnitApproval
      ? params?.row?.managerApproval?.deviationAmount -
        params?.row?.businessUnitApproval?.deviationAmount
      : null;

    const deviationReasonBU = params?.row?.businessUnitApproval?.deviationReason
      ? ` due to ${params?.row?.businessUnitApproval?.deviationReason}`
      : '';

    let tooltipTitle = '';
    if (!isNaN(amountDeviatedRM)) {
      tooltipTitle += `₹${Math?.abs(amountDeviatedRM)} has been ${
        amountDeviatedRM > 0
          ? 'deducted'
          : amountDeviatedRM < 0
          ? 'added'
          : 'deviated'
      } by RM${deviationReasonRM}`;
    }
    if (amountDeviatedBU !== null) {
      tooltipTitle += `
        and ₹${Math?.abs(amountDeviatedBU)} has been ${
        amountDeviatedBU > 0
          ? 'deducted'
          : amountDeviatedBU < 0
          ? 'added'
          : 'deviated'
      } by BU${deviationReasonBU}`;
    }

    return tooltipTitle;
  };

  return (
    <Box className={`${classes?.flexCenter} ${classes?.occupyEntireHeight}`}>
      <Box className={classes?.expenseRequestExpenseRequestPrimaryDetails}>
        <Tooltip title={getTooltipTitle()} arrow>
          <Typography
            variant="body1"
            noWrap
            className={classes?.expenseRequestElipsesTypography}
          >
            ₹{amount}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
}
