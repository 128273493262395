// types.ts
export enum StatusConstants {
  PENDING_APPROVAL = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  REVOKED = 'revoked',
  INACTIVE = 'Inactive',
  PAID = 'paid',
}

export enum ButtonConstants {
  APPROVE = 'Approve',
  REJECT = 'Reject',
  REVOKE = 'Revoke',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  REVOKED = 'Revoked',
  PAID = 'Paid',
}

export interface ExpenseRequestModel {
  id: any;
  expensePurpose: any;
  comments: any;
  status: StatusConstants;
}

export interface State {
  items: Record<any, ExpenseRequestModel>;
}

export const SET_ITEMS = 'SET_ITEMS';
export const UPDATE_ITEM = 'UPDATE_ITEM';

interface SetExpenseModelAction {
  type: typeof SET_ITEMS;
  payload: { items: Record<any, ExpenseRequestModel> };
}

interface UpdateExpenseModelAction {
  type: typeof UPDATE_ITEM;
  payload: { id: any; data: Partial<ExpenseRequestModel> };
}

export type ActionTypes = SetExpenseModelAction | UpdateExpenseModelAction;
