// DocsModal.tsx
import React from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FileDownloadOutlined } from '@mui/icons-material';
import useCustomStyles from '../../components/common/styles/CustomStylesHook';
import { COMMON_CONSTANTS } from '../adminConstants';

interface DocsModalProps {
  docsModalVisibility: boolean;
  handleCloseForm: () => void;
  currentAllDocs: any[];
  downloadFiles: (id: string, name: string) => void;
  classes: any; // Add your classes if you have them
}

const styles = () => ({
  containersModalMain: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    flexWrap: 'wrap !important',
    gap: 16,
    width: '100% !important',
    '& *': {
      fontSize: '12px',
      color: '#000000ca',
    },
    background: '#f0f4fc',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    maxHeight: '90% !important',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  containersModalMainInner: {
    display: 'flex !important',
    justifyContent: 'start !important',
    alignItems: 'start !important',
    flexWrap: 'wrap !important',
    gap: '12px !important',
  },

  buttonsCloseButton: {
    color: '#2b5f6b',
    position: 'absolute',
    top: 16,
    right: 16,
    borderRadius: '50%',
    width: 40,
    height: 40,
    minWidth: 0,
    padding: 0,
    zIndex: 1100,
  },
  documentsBox: {
    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    border: '1px solid #C3C3C3 !important',
    borderRadius: '15px !important',
    gap: 2,
    padding: '8px !important',
    width: '48% !important',
    minWidth: '200px !important',
  },
  fileIconStyle: {
    height: '2rem !important',
    width: '2rem !important',
    backgroundPosition: 'center !important',
    objectFit: 'cover',
    objectPosition: 'center !important',
  },
});

const DocsModal: React.FC<DocsModalProps> = ({
  docsModalVisibility,
  handleCloseForm,
  currentAllDocs,
  downloadFiles,
  classes,
}) => {
  const theme = useTheme();
  const localClasses = useCustomStyles(styles, theme);

  return (
    <Modal open={docsModalVisibility} onClose={handleCloseForm}>
      <div className={`${classes?.modalRoot} modal-container`}>
        <Button
          className={localClasses?.buttonsCloseButton}
          onClick={handleCloseForm}
        >
          <CloseOutlinedIcon />
        </Button>
        <Typography variant="h5">
          {COMMON_CONSTANTS?.SUPPORTING_DOCUMENTS}
        </Typography>
        <Box className={localClasses?.containersModalMain}>
          {currentAllDocs?.map((d: any, i: number) => (
            <Box key={d?.id || i} className={localClasses?.documentsBox}>
              <Box className={localClasses?.containersModalMainInner}>
                {(() => {
                  switch (d?.fileType) {
                    case 'image/png':
                      return (
                        <img
                          className={classes?.icon}
                          src="https://cdn-icons-png.flaticon.com/512/337/337948.png"
                          alt="icon"
                        />
                      );
                    case 'application/pdf':
                      return (
                        <img
                          className={classes?.icon}
                          src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                          alt="icon"
                        />
                      );
                    case 'image/jpg':
                      return (
                        <img
                          className={classes?.icon}
                          src="https://www.svgrepo.com/show/255826/jpg.svg"
                          alt="icon"
                        />
                      );
                    case 'doc':
                      return (
                        <img
                          className={classes?.icon}
                          src="https://www.svgrepo.com/show/255810/doc.svg"
                          alt="icon"
                        />
                      );
                    case 'text/csv':
                      return (
                        <img
                          className={classes?.icon}
                          src="https://cdn-icons-png.flaticon.com/512/172/172534.png"
                          alt="icon"
                        />
                      );
                    case 'image/jpeg':
                      return (
                        <img
                          className={classes?.icon}
                          src="https://cdn-icons-png.flaticon.com/512/337/337940.png"
                          alt="icon"
                        />
                      );
                    case 'text/plain':
                      return (
                        <img
                          className={classes?.icon}
                          src="https://cdn-icons-png.flaticon.com/512/2656/2656402.png"
                          alt="icon"
                        />
                      );
                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                      return (
                        <img
                          className={classes?.icon}
                          src="https://img.icons8.com/external-fauzidea-flat-fauzidea/256/external-docx-file-file-extension-fauzidea-flat-fauzidea.png"
                          alt="icon"
                        />
                      );
                    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                      return (
                        <img
                          className={classes?.icon}
                          src="https://cdn-icons-png.flaticon.com/512/9496/9496502.png"
                          alt="icon"
                        />
                      );
                    case 'UNKNOWN':
                      return (
                        <Box
                          component={'img'}
                          src="path-to-your-icon"
                          className={localClasses?.fileIconStyle}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </Box>
              <Box className={`${classes?.fileNameBox} `}>
                {d?.fileName || d?.name}
              </Box>
              {d?.fileType !== COMMON_CONSTANTS?.UNKNOWN && (
                <IconButton
                  onClick={() => downloadFiles(d?.id, d?.fileName || d?.name)}
                >
                  <FileDownloadOutlined
                    color={'info'}
                    className={classes?.downloadIcon}
                  />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </div>
    </Modal>
  );
};

export default DocsModal;
