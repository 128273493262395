import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { CSSProperties, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as Yup from 'yup';
import useCustomStyles from '../../../components/common/styles/CustomStylesHook';
import CONSTANTS from '../../../constants/constants';
import { GENERAL_FORM_ID } from '../../../constants/formConstants';
import { getSessionStorageItem } from '../../../encrypt';
import { pushNotification } from '../../../redux/actions';
import { fetchApplicationSettings } from '../../../services/AdminService';
import { fetchFormDataWithId } from '../../../services/formService';
import CustomTextField from '../../CustomTextField';
import { fileTypes } from '../../EmployeeEvents/types/EventTypes';
import FormExitPopup, { FormExitPopupActions } from '../../FormExitPopup';
import { formPic } from '../images';
import { StatusConstants } from '../types/ExpenseTrackerTypes';
import {
  ErrorMessages,
  removeSpaces,
  ValidationUtils,
} from '../utils/expenseUtils';

const styles = () => ({
  modalRoot: {
    maxHeight: '100%',
    width: '22vw',
    minWidth: '500px',
    borderRadius: '18px',
    backgroundColor: 'transparent',
    '@media (max-width: 1440px )': {
      minWidth: '400px',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  fieldWidth: {
    width: '48%',
  },
  errorText: {
    color: 'red',
  },
  divider: {
    backgroundColor: '#f15830',
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '15px',
  },
  addBtn: {
    marginTop: '15px',
  },
  createEmployeeBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  phoneInputStyles: {
    width: '100% !important',

    '@media (min-width: 320px )': {
      width: '100% !important',
    },
    '@media (min-width: 375px)': {
      width: '85% !important',
    },
    '@media (min-width: 425px)': {
      width: '85% !important',
    },
    '@media (min-width: 600px)': {
      width: '100% !important',
    },
    '@media (min-width: 768px)': {
      width: '100% !important',
    },
    '@media (min-width: 1440px)': {
      width: '100% !important',
    },
    '@media (min-width: 2560px)': {
      width: '100% !important',
    },
  },
  inputStyles: {
    textAlign: 'center',
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 1.6rem) !important',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.8rem) !important',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem) !important',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem) !important',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem) !important',
    },
  },

  containerMain: {
    width: '22vw',
    minWidth: '500px',
    maxHeight: '90vh',
    '@media (max-width: 1440px )': {
      minWidth: '400px',
    },
    '& *': {
      fontSize: '12px',
      color: '#000000ca',
    },
  },
  containerMainBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '90vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '-ms-overflow-style': 'auto',
    'scrollbar-width': '8px',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9c9c9c',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#2b5f6b',
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: '#1c3a42',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      borderRadius: '4px',
    },
    background: '#f0f4fc',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '24px',
  },
  containerLogo: {
    flexGrow: '1',
    minHeight: '10rem',
    maxHeight: '10rem',
    display: 'flex',
    background: `url(${formPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center left',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
  containerFormMain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    padding: '1.5rem',
    gap: '0.8rem',
    background: '#f2f9ff',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  },
  containerMainHeading: {
    display: 'flex',
    flexGrow: '1',
    gap: '0.8rem',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'start',
  },
  containerFormBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    flexGrow: '1',
    padding: '0.2rem',
    width: '100%',
  },
  containerExpenseRequestBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  },
  containerExpenseRequestSectionBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
    gap: '0vw',
  },
  containerPurposeBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    '& *': {
      fontSize: '14px !important',
    },
  },
  containerRowSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
    gap: '4px',
    '& *': {
      fontSize: '14px !important',
      lineHeight: '1.2 !important',
    },
  },
  containerAmount: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 1,
    width: '100%',
  },
  containerFilesBufferContainer: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    gap: '8px !important',
    border: '1px solid #1591ea !important',
    borderRadius: '4px !important',
    background: 'rgb(21, 145, 234,0.1) !important',
    padding: '8px !important',
    width: '45% !important',
    margin: '0.5rem',
  },
  typographyHeadingEnterDetails: {
    textAlign: 'start',
    fontSize: '28px !important',
    color: '#000000bd !important',
  },
  typographyInfoText: {
    fontSize: '14px !important',
  },
  typographyErrorText: {
    color: 'red !important',
    fontSize: '12px !important',
  },
  typographyFilesBufferFileName: {
    fontSize: '1rem !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    display: '-webkit-box !important',
    textAlign: 'start',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    maxWidth: '200px !important',
  },
  dividersHeadingUnderlineDivider: {
    height: '1px',
    background: '#00000035',
    width: '100%',
  },
  buttonsSubmitButton: {
    width: '100%',
    borderRadius: '24px',
    background: '#2b5f6b',
    '&:hover': {
      background: '#1c3a42',
    },
    color: 'white !important',
    textTransform: 'capitalize',
    fontsize: '1.5rem !important',
  },
  buttonsCloseButton: {
    color: '#2b5f6b',
    position: 'absolute',
    top: 16,
    right: 16,
    borderRadius: '50%',
    width: 40,
    height: 40,
    minWidth: 0,
    padding: 0,
    zIndex: 1100,
  },
  iconsFilesBufferClearIcon: {
    '&:hover': {
      cursor: 'pointer !important',
    },
  },
  dateTimePicker: {
    '& .MuiOutlinedInput-input': {
      flexGrow: '1',
      height: '9px',
      width: '100%',
    },
  },
  boxExpenseTypesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  autoCompleteExpenseType: { width: '48%' },
  boxFilesBufferSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
});

interface AddExpenseRequestFormProps {
  show: any;
  setShow: any;
  addExpenseRequestCallback: any;
}

interface EmployeeData {
  empId: number;
  fullName: string;
  reportingTo: string;
  phoneNumber: string;
}
interface ExpenseType {
  name: string;
  expenseAccountId: number;
  liabilityAccountId: number;
  reference: string;
  delete: boolean;
}

const AddExpenseRequestForm = ({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  show,
  setShow,
  addExpenseRequestCallback: addExpenseRequestCallback,
}: /* eslint-enable @typescript-eslint/no-unused-vars */
AddExpenseRequestFormProps) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const emailId: any = getSessionStorageItem('user-email');
  const [filesBuffer, setFilesBuffer] = useState<any[]>([]);

  const [isOpenFormExitPopup, setIsShowFormExitPopup] = useState(false);
  const [empData, setEmpData] = useState<EmployeeData[]>([]);
  const dispatch = useDispatch();
  const [getLoading, setGetLoading] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState<any>();
  const [parsedExpenseTypes, setParsedExpenseTypes] = useState<ExpenseType[]>();

  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: '#4B6D8F',
  };

  const validationSchema = Yup.object({
    expenseType: Yup.string().required(
      ValidationUtils?.MESSAGE_REQUEST_TYPE_REQUIRED
    ),
    description: Yup.string()
      .matches(
        ValidationUtils?.AlphabeticRegexAtLeast5,
        ValidationUtils?.AT_LEAST_FIVE_CHARACTERS_REQUIRED
      )
      .max(250, ValidationUtils?.MESSAGE_DESCRIPTION_MAX_LENGTH),
    amountRequested: Yup.number()
      .required(ValidationUtils?.MESSAGE_REQUEST_AMOUNT_REQUIRED)
      .typeError(ValidationUtils?.MESSAGE_REQUEST_AMOUNT_NUMBER)
      .min(0, ValidationUtils?.MESSAGE_REQUEST_AMOUNT_NEGATIVE),
  });

  const formik = useFormik({
    initialValues: {
      expenseType: '',
      description: '',
      amountRequested: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const emp = empData?.[0];

      const formValues = {
        ...values,
        employeeId: `${emp?.empId}`,
        employeeName: emp?.fullName,
        reportingTo: emp?.reportingTo,
        requestStatus: StatusConstants?.PENDING_APPROVAL,
        filesBuffer: filesBuffer,
        phoneNumber: emp?.phoneNumber,
      };

      addExpenseRequestCallback(formValues);
    },
  });



  const handleFileChange = (file) => {
    setFilesBuffer([...filesBuffer, ...file]);
  };

  const deleteFromFilesBuffer = (_fileToRemove, index) => {
    setFilesBuffer(filesBuffer?.filter((_, i) => i !== index));
  };

  // const formatToCurrency = (value) => {
  //   if (!value) return '';
  //   return new Intl.NumberFormat('en-IN', {
  //     style: 'currency',
  //     currency: 'INR',
  //     minimumFractionDigits: 2,
  //   }).format(value);
  // };

  const handleFieldChange = (field: any, value: any) => {
    formik?.handleChange({
      target: {
        name: field,
        value: value,
      },
    });
  };

  const fetchEmployeeOptionsData = async () => {
    setGetLoading(true);

    const response = await fetchApplicationSettings();

    if (response?.success) {
      setExpenseTypes(response?.data?.expenseTypes || []);
      setGetLoading(false);
    } else {
      setExpenseTypes([]);
      setGetLoading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  useEffect(() => {
    const caller = async () => {
      await fetchEmployeeOptionsData();
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik?.resetForm();
    setFilesBuffer([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  useEffect(() => {
    if (expenseTypes && Array.isArray(expenseTypes)) {
      const expenseTypesArray: ExpenseType[] = expenseTypes?.map((expense) => {
        return expense as ExpenseType;
      });
      setParsedExpenseTypes(expenseTypesArray);
    }
  }, [expenseTypes]);

  const handleCloseForm = () => {
    if (formik?.dirty) {
      setIsShowFormExitPopup(true);
    } else {
      setShow(false);
    }
  };

  const handleFormExitPopupAction = (action: string) => {
    if (action === FormExitPopupActions?.LEAVE) {
      setShow(false);
    }
    setIsShowFormExitPopup(false);
  };

  const handleRequestType = async (event, value) => {
    await formik?.setFieldValue('expenseType', value || '');
  };

  const fetchUserData = async () => {
    const response = await fetchFormDataWithId(GENERAL_FORM_ID, emailId);
    if (response?.success && response?.data?.length > 0) {
      const mapData: any = response?.data?.[0].formData;

      const extractedData = [
        {
          empId: mapData?.empId,
          fullName: mapData?.firstName + mapData?.lastName,
          reportingTo: mapData?.reportingTo,
          phoneNumber: (mapData?.phone || '') as string,
        },
      ];
      setEmpData(extractedData);
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: ErrorMessages?.FAILD_TO_FETCH_USER_DATA,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  useEffect(() => {
    const caller = async () => {
      await fetchUserData();
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={show}
      onClose={() => {
        handleCloseForm();
      }}
    >
      <div className={`${classes?.modalRoot} modal-container`}>
        {isOpenFormExitPopup && (
          <FormExitPopup
            isShowFormExitPopup={isOpenFormExitPopup}
            setIsShowFormExitPopup={setIsShowFormExitPopup}
            callbackAction={handleFormExitPopupAction}
          />
        )}
        <Button
          className={classes?.buttonsCloseButton}
          onClick={handleCloseForm}
        >
          <CloseOutlinedIcon />
        </Button>

        <form onSubmit={formik?.handleSubmit}>
          <Box className={classes?.containerMain}>
            <Box className={classes?.containerMainBox}>
              <Box className={classes?.containerLogo} />
              <Box className={classes?.containerFormMain}>
                <Box className={classes?.containerMainHeading}>
                  <Typography
                    className={classes?.typographyHeadingEnterDetails}
                  >
                    Enter Expense Details
                  </Typography>
                  <Divider
                    orientation="horizontal"
                    className={classes?.dividersHeadingUnderlineDivider}
                  />
                </Box>
                <Box className={classes?.containerFormBox}>
                  <Box className={classes?.containerExpenseRequestBox}>
                    <Box className={classes?.containerExpenseRequestSectionBox}>
                      <Box className={classes?.containerRowSection}>
                        <Typography>Expense Type*:</Typography>

                        <Box className={classes?.boxExpenseTypesContainer}>
                          <Autocomplete
                            disablePortal
                            id="expenseType"
                            options={
                              parsedExpenseTypes?.map((type) => type?.name) ||
                              []
                            }
                            loading={getLoading}
                            onChange={handleRequestType}
                            disableClearable
                            size="small"
                            classes={{ input: classes?.inputStyles }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select a request type"
                                InputProps={{
                                  ...params?.InputProps,
                                  endAdornment: (
                                    <>
                                      {getLoading ? (
                                        <ClipLoader
                                          color="#162952"
                                          cssOverride={override}
                                          size={20}
                                        />
                                      ) : null}
                                      {params?.InputProps?.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                            className={classes?.autoCompleteExpenseType}
                          />
                        </Box>
                        {formik?.touched?.expenseType &&
                        formik?.errors?.expenseType ? (
                          <Typography
                            color="error"
                            className={classes?.typographyErrorText}
                          >
                            {formik?.errors?.expenseType}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box className={classes?.containerExpenseRequestSectionBox}>
                      <Box className={classes?.containerRowSection}>
                        <Typography>Request Amount*:</Typography>
                        <Box className={classes?.containerAmount}>
                          <Typography>₹</Typography>
                          <TextField
                            id="amountRequested"
                            name="amountRequested"
                            size="small"
                            onBlur={formik?.handleBlur}
                            placeholder="₹"
                            type="number"
                            onChange={(e) => {
                              const strippedValue = removeSpaces(
                                e?.target?.value
                              );
                              handleFieldChange(
                                'amountRequested',
                                strippedValue
                              );
                            }}
                            value={formik?.values?.amountRequested}
                            className={classes?.autoCompleteExpenseType}
                          />
                        </Box>
                        {formik?.touched?.amountRequested &&
                        formik?.errors?.amountRequested ? (
                          <Typography
                            color="error"
                            className={classes?.typographyErrorText}
                          >
                            {formik?.errors?.amountRequested}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box className={classes?.containerExpenseRequestSectionBox}>
                      <Box className={classes?.containerRowSection}>
                        <Typography>Description:</Typography>
                        <CustomTextField
                          name="description"
                          contentText={formik?.values?.description}
                          handleContentTextChange={(e: any) => {
                            formik?.setFieldValue(
                              'description',
                              e?.target?.value
                            );
                          }}
                          color="#162952"
                          fontsize="16px"
                          height="50px"
                          width="100%"
                        />
                        {formik?.touched?.description &&
                        formik?.errors?.description ? (
                          <Typography
                            color="error"
                            className={classes?.typographyErrorText}
                          >
                            {formik?.errors?.description}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box className={classes?.containerExpenseRequestSectionBox}>
                      <Box className={classes?.containerRowSection}>
                        <Typography>Upload Supporting Documents:</Typography>
                        <FileUploader
                          multiple={true}
                          handleChange={handleFileChange}
                          name="file"
                          types={fileTypes}
                        />
                      </Box>
                      <Box className={classes?.boxFilesBufferSection}>
                        {filesBuffer?.map((file, i) => (
                          <Box
                            key={i}
                            className={classes?.containerFilesBufferContainer}
                          >
                            <Tooltip title={file?.name || ''} arrow>
                              <Typography
                                className={
                                  classes?.typographyFilesBufferFileName
                                }
                              >
                                {file?.name}
                              </Typography>
                            </Tooltip>
                            <ClearIcon
                              onClick={() => deleteFromFilesBuffer(file, i)}
                              className={classes?.iconsFilesBufferClearIcon}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes?.buttonsSubmitButton}
                  >
                    Create Expense Request
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </div>
    </Modal>
  );
};

export default AddExpenseRequestForm;
