export const REPORT_TYPES = {
  DAILY: 'daily',
  OVERALL: 'overall',
};

export const ATTENDANCE_SELECTED_VALUES = {
  ALL: 'all',
  SPECIFIC: 'specific',
};

export const DataMapTypes = {
  EXPENSE_TYPE: 'expenseTypes',
  EMPLOYEE_TYPES: 'employeeTypes',
};

export const COMMON_CONSTANTS = {
  EDIT: 'Edit',
  DELETE: 'Delete',
  ADD: 'Add',
  EMPTY_RECORDS: 'No records found.',
  PLUS: '+',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  DESCRIPTION_IS_REQUIRED: 'Description is required',
  NAME_IS_REQUIRED: 'Name is required',
  CONFIRM_YOUR_DECISION: 'Confirm your decision',
  SUPPORTING_DOCUMENTS: 'Supporting Documents:',
  UNKNOWN: 'UNKNOWN',
  CANCELLED: 'Cancelled',
  NO_SUPPORTING_DOCUMENTS_UPLOADED: 'No Supporting Documents Uploaded',
  LOADING_EXPENSES: 'Loading Expense Requests',
  NO_EXPENSES_FOUND: 'No Expense Requests',
  APPROVED: 'Approved',
  APPROVE: 'Approve',
  REJECTED: 'Rejected',
  REJECT: 'Reject',
  REVOKED: 'Revoked',
  REVOKE: 'Revoke',
  PAID: 'Paid',
  CONFIRM_PAYMENT: 'Confirm Payment',
  ENTER: 'Enter',
  INACTIVE: 'Inactive',
  ODOO_PAYMENT_STATUS: 'Odoo Payment Status',
  BUSINESS_UNIT_APPROVAL_STATUS: 'Business Unit Approval Status',
  DEFAULT_VALUE: '---',
  MANAGER_APPROVAL: 'managerApproval',
  DATA_EXPORTED_SUCCESSFULLY: 'Data exported successfully',
  EXPENSE_CREATED_SUCCESSFULLY:
    'Your expense request has been successfully submitted.',
  UNKNOWN_ERROR_WHILE_UPLOADING_DOCUMENT:
    'Unknown error occurred during file upload.',
  FAILD_TO_SAVE_META_DATA: 'Failed to save file metadata to the database.',
  FILE_UPLOAD_FAILD: 'File upload failed.',
  ERROR_SAVING_DOCUMENT_DATA: 'Error saving document metadata.',
  BUSINESS_APPROVAL: 'businessUnitApproval',
  REPORTING_MANAGER_APPROVAL_STATUS: 'Reporting Manager Approval Status',
  MANAGE_YOUR_EXPENSES: 'Manage your Expenses',
  SOME_ERROR_OCCURED: 'Some error occurred, please contact your administrator',
  EXPENSES_TAGLINE: 'Every rupee counts toward building our success.',
  UNKNOWN_ERROR_WHILE_SAVING_DOCUMENT:
    'Unknown error occurred while saving document metadata.',
  DOCUMENTS_MISSING: 'Document(s) are missing. Please contact Admin.',
  AMOUNT_FIELD_ERROR: 'Please enter a valid amount greater than 0.',
  REASON_FIELD_ERROR: 'Reason is required.',
  DOWNLOAD: 'download',
  EXPENSE_REQUEST_XLSX: 'ExpenseRequests.xlsx',
};

export const LEAVE_CONSTANTS = {
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
  APPROVED: 'approved',
  APPROVE: 'approve',
  REJECTED: 'rejected',
  REJECT: 'reject',
  CANCELLED: 'cancelled',
  CANCEL: 'cancel',
};
export const DataMapCategories = [
  {
    header: 'Employment Types',
    itemKey: DataMapTypes.EMPLOYEE_TYPES,
  },
  {
    header: 'Expense Types',
    itemKey: DataMapTypes.EXPENSE_TYPE,
  },
];

export const DataMapOperations = {
  OPERATION_EDIT: 'edit',
  OPERATION_DELETE: 'delete',
} as const;
export type DataMapOperationsType =
  (typeof DataMapOperations)[keyof typeof DataMapOperations];
