export const DataMapComponentConstants = {
  REQUIRED_FIELD_ERROR_TEXT: 'This field cannot be empty.',
  TEXT_EDIT: 'Edit',
  TEXT_SAVE: 'Save',
  DELETED_MESSAGE: 'Field deleted successfully',
  ADDED_MESSAGE: 'Field saved successfully',
};

export const ExpenseTypePopupConstants = {
  EDIT_EXPENSE_TYPE: 'Edit Expense Type',
  ADD_NEW_EXPENSE_TYPE: 'Add New Expense Type',
};

export const EmployeeTypePopupConstants = {
  ADD_NEW_EMPLOYEE_TYPE: 'Add New Employee Type',
};
