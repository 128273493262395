import { Box, Divider, Typography, useTheme } from '@mui/material';
import useCustomStyles from '../../components/common/styles/CustomStylesHook';
import { COMMON_CONSTANTS } from '../adminConstants';

interface ExpenseTrackerHeadingProps {
  divider?: any;
  dividerWidth?: any;
  dividerHeight?: any;
  containerStyle?: any;
  dividerBackground?: any;
}

const styles = () => ({
  typographyGreeting: {
    lineHeight: '1.2 !important',
    marginTop: '12px !important',
    fontWeight: 'bold !important',
    fontSize: '38px !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    display: '-webkit-box !important',
    textAlign: 'start !important',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical !important',
    maxWidth: '750px !important',
    marginBottom: '0.5rem',
  },
  typographyGreetingTagline: {
    fontSize: '1rem !important',
  },
});

const ExpenseTrackerHeading = ({
  divider = true,
  dividerWidth = '80%',
  dividerHeight = '1px',
  containerStyle = {},
  dividerBackground = '#00000079',
}: ExpenseTrackerHeadingProps) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  return (
    <Box className={containerStyle}>
      <div>
        <Typography className={classes?.typographyGreeting}>
          {COMMON_CONSTANTS?.MANAGE_YOUR_EXPENSES}
        </Typography>{' '}
        <Typography className={classes?.typographyGreetingTagline}>
          {COMMON_CONSTANTS?.EXPENSES_TAGLINE}
        </Typography>
      </div>

      {divider && (
        <Divider
          orientation="horizontal"
          style={{
            height: dividerHeight,
            width: dividerWidth,
            background: dividerBackground,
            marginTop: 1,
          }}
        />
      )}
    </Box>
  );
};

export default ExpenseTrackerHeading;
