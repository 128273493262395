import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useCustomStyles from './styles/CustomStylesHook';
import { EmployeeTypePopupConstants } from './constants';
import { COMMON_CONSTANTS } from '../../screen/adminConstants';

const styles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem !important',
    margin: '1rem',
  },
  textFieldEmployeeAccountId: {
    minWidth: 300,
  },
  buttonCancel: {
    color: 'white',
    background: '#cf9c3e',
    border: '2rem',
  },
  buttonSave: {
    color: 'white',
    background: '#15854d',
    border: '2rem',
  },
});

interface EmployeeType {
  name: string;
  description: string;
}

interface EmployeeTypePopupProps {
  open: boolean;
  onClose: () => void;
  onSave: (updatedRecord: EmployeeType) => void;
}

const EmployeeTypePopup: React.FC<EmployeeTypePopupProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [record, setRecord] = useState<EmployeeType>({
    name: '',
    description: '',
  });

  // State for tracking errors
  const [errors, setErrors] = useState<{ name: boolean; description: boolean }>(
    {
      name: false,
      description: false,
    }
  );

  const handleSave = () => {
    const newErrors = {
      name: !record?.name,
      description: !record?.description,
    };
    setErrors(newErrors);
    if (!newErrors?.name && !newErrors?.description) {
      onSave(record);
      onClose();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (record) {
      setRecord({
        ...record,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };

  useEffect(() => {
    setRecord({
      name: '',
      description: '',
    });
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {EmployeeTypePopupConstants?.ADD_NEW_EMPLOYEE_TYPE}
      </DialogTitle>
      <DialogContent>
        <Box className={classes?.mainContainer}>
          <TextField
            label="Name"
            name="name"
            value={record?.name || ''}
            onChange={handleChange}
            fullWidth
            required
            error={errors?.name}
            helperText={errors?.name ? COMMON_CONSTANTS?.NAME_IS_REQUIRED : ''}
          />

          <TextField
            label="Description"
            name="description"
            value={record?.description || ''}
            onChange={handleChange}
            fullWidth
            required
            error={errors?.description}
            helperText={errors?.description ? COMMON_CONSTANTS?.DESCRIPTION_IS_REQUIRED : ''}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          className={classes?.buttonCancel}
        >
          {COMMON_CONSTANTS?.CANCEL}
        </Button>

        <Button
          onClick={handleSave}
          variant="contained"
          className={classes?.buttonSave}
        >
          {COMMON_CONSTANTS?.SAVE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeTypePopup;
