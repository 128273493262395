import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Modal,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  deactiveEmployeePost,
  FilterFormDetailsWithOfficalEmail,
} from '../services/formService';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import useCustomStyles from '../components/common/styles/CustomStylesHook';
import CONSTANTS from '../constants/constants';
import { MYLEAVE_DETAILS_FORM_ID } from '../constants/formConstants';
import {
  pushNotification,
  setLoader,
  setOfficialEmailId,
  setReportingToEmailId,
  setUserData,
} from '../redux/actions';
import { store } from '../redux/store';
import { getAllUsers } from '../services/userService';
import CustomTextField from './CustomTextField';
import { EmployeeStatusConstants } from './EmployeeList';
import ReassignmentPopup from './ModalViews/HrAdmin/ReassignmentPopup';
import {
  modalAdminGroup,
  modalFinanceGroup,
  modalHrAdminGroup,
  modalLeadGroup,
  modalNcgGroup,
  ROLE_EMP_HR_VIEW,
} from './uiElementsAccess';
import { LeaveApplicationFilterValues } from './LeaveManagementSystem/leaveManagementSystemConstants';

const styles = () => ({
  menuItemAction: { color: '#FF0000 !important' },
  iconButton: { padding: '0 !important' },
  label: {
    fontFamily: 'Roboto !important',
    fontSize: '15px !important',
    marginTop: '8px !important',
  },
  heading: {
    fontFamily: 'Roboto !important',
    fontSize: '16px !important',
    fontWeight: '600 !important',
  },
  createEmployeeBtn: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    marginTop: '20px !important',
  },
  departureDetails: {
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'start !important',
    alignItems: 'start !important',
    gap: '16px !important',
  },

  buttonDepartureReason: {
    backgroundColor: 'rgb(22, 41, 82) !important',
    color: 'white !important',
  },
  formControlProbation: { width: '100% !important' },
  iconMoreVertIcon: {
    color: '#FF0000 !important',
    marginBottom: '5px !important',
  },
  fieldContainer: {
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'start !important',
    alignItems: 'start !important',
    width: '100% !important',
  },
  lwdDatePickerContainerMain: {
    display: 'flex !important',
    justifyContent: 'start !important',
    alignItems: 'center !important',
    gap: '18px !important',
  },
  lwdDatePickerDatePicker: {
    '& .MuiOutlinedInput-input': {
      padding: '8px !important',
      width: '100% !important',
    },
    '& .MuiSvgIcon-root': {
      display: 'block !important',
    },
  },
  boxesFormBoxMain: {
    position: 'absolute !important',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    width: '600px !important',
    maxHeight: '70% !important',
    borderRadius: '2px !important',
    bgcolor: 'background.paper !important',
    padding: '0.6rem !important',
    overflowY: 'auto !important',
    '&::-webkit-scrollbar': {
      width: '6px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px !important',
    },
    backgroundColor: 'white !important',
  },
  buttonsCloseButton: {
    color: 'red !important',
    position: 'absolute !important',
    right: '10px !important',
  },
  iconsCloseIcon: { margin: '2px !important' },
});

const QuickViewOptions = {
  PROFILE_VIEW: 'profileView',
  DEACTIVATE: 'deactivate',
  EDIT: 'edit',
};
interface MoreIconProps {
  row: any;
  // setShow:any
  // setAction: any;
  // isAdmin:any
  setIsAdmin: any;
  isLead: any;
  setIsLead: any;
  // isFinance:any
  setIsFinance: any;
  // isNcg:any
  setIsNcg: any;
  // isHrAdmin:any
  setIsHrAdmin: any;
  emailId: any;
  // setOpenDeactivation:any
  // setPopUpMessage:any
  // showView:any
  setShowView: any;
  setShowProfileView: any;
  setRefresh: any;
  setUserData: any;
}

const MoreIcon = ({
  row,
  // setAction,
  setIsAdmin,
  setIsLead,
  setIsFinance,
  setIsNcg,
  setIsHrAdmin,
  emailId,
  setShowView,
  setShowProfileView,
  setRefresh,
  setUserData: setUserDataLocally,
}: MoreIconProps) => {
  const dispatch = useDispatch();
  const reduxState: any = store.getState();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const { userData } = useSelector((state: any) => ({
    userData: state.userData,
  }));
  const [openDeactivate, setOpenDeactivate] = React.useState(false);
  const [isDeactivationStarted, setIsDeactivationStarted] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [openReassignmentPopup, setOpenReassignmentPopup] = useState(false);
  const [userDataWithReportees, setUserDataWithReportees] = useState<
    Record<string, any>
  >({});
  const [usersLeavesData, setUsersLeavesData] = useState<Record<string, any>>(
    {}
  );
  const [emailsArray, setEmailsArray] = useState<string[]>([]);
  const validationSchema = Yup.object({
    officialEmail: Yup.string(),
    lwd: Yup.string().required('Date & time is required'),
    departureReason: Yup.string().required('Reason required'),
    performanceSummary: Yup.string(),
    strengths: Yup.string(),
    areasImprovement: Yup.string(),
    additionalComments: Yup.string(),
    confidentialityChecked: Yup.boolean(),
    generalFormId: Yup.string(),
    leaveFormId: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      officialEmail: '',
      lwd: '',
      departureReason: '',
      performanceSummary: '',
      departureStrengths: '',
      areasImprovement: '',
      additionalComments: '',
      confidentialityChecked: false,
      generalFormId: '',
      leaveFormId: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleDeactivation(values);
    },
  });

  const handleClickDeactivate = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(setLoader(true));
    setIsDeactivationStarted(true);
  };

  const handleCloseDeactivate = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDeactivate(false);
    setIsDeactivationStarted(false);
    setOpenReassignmentPopup(false);
  };

  const actions = [
    {
      actionId: QuickViewOptions.PROFILE_VIEW,
      actionName:
        row?.status === EmployeeStatusConstants.INACTIVE
          ? 'Quick View'
          : 'View Profile',
    },
    {
      actionId: QuickViewOptions.EDIT,
      actionName: 'Edit',
    },
    {
      actionId: QuickViewOptions.DEACTIVATE,
      actionName: 'Deactivate',
    },
  ];

  // code for more menu
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeactivation = async (values: any) => {
    setButtonDisabled(true);
    dispatch(setLoader(true));

    const response = await deactiveEmployeePost(values);
    if (response?.success) {
      setButtonDisabled(false);
      dispatch(setLoader(false));
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      handleClose();
      setOpenDeactivate(false);
      setRefresh((prevState) => !prevState);
    } else {
      setButtonDisabled(false);
      dispatch(setLoader(false));
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.ERROR,
        })
      );
      handleClose();
      setOpenDeactivate(false);
      setRefresh((prevState) => !prevState);
    }
  };

  const handleActionClick = async (event, row, id) => {
    event.preventDefault();
    event.stopPropagation();
    setUserDataLocally(row);

    if (id === QuickViewOptions.EDIT) {
      handleClose();
      await dispatch(setOfficialEmailId(emailId));
      await dispatch(setReportingToEmailId(row?.reportingTo));
      if (reduxState?.roles?.some((role) => modalAdminGroup.includes(role))) {
        setIsAdmin(true);
      } else if (
        reduxState?.roles?.some((role) => modalFinanceGroup.includes(role))
      ) {
        setIsFinance(true);
      } else if (
        reduxState?.roles?.some((role) => modalNcgGroup.includes(role))
      ) {
        setIsNcg(true);
      } else if (
        reduxState?.roles?.some((role) => modalHrAdminGroup.includes(role))
      ) {
        setIsHrAdmin(true);
      } else if (
        reduxState?.roles?.some((role) => modalLeadGroup.includes(role))
      ) {
        setIsLead(true);
      }
    } else if (id === QuickViewOptions.PROFILE_VIEW) {
      await dispatch(setUserData(row));
      row?.status === EmployeeStatusConstants.INACTIVE
        ? setShowView((prevState) => !prevState)
        : setShowProfileView((prevState) => !prevState);
    } else if (id === QuickViewOptions.DEACTIVATE) {
      dispatch(setUserData(row));
      handleClickDeactivate(event);
    }
  };

  const handleFieldChange = (field: any, value: any) => {
    formik.handleChange({
      target: {
        name: field,
        value: value,
      },
    });
  };
  const fetchAllReportees = async () => {
    const response = await getAllUsers();
    if (response?.success && response?.data?.length > 0) {
      const emailsArray = response?.data?.map(
        (user) => user?.userData?.emailId
      );
      const allReportees = response?.data?.filter(
        (user) => user?.userData?.reportingTo === userData?.officialEmail
      );
      setEmailsArray([...emailsArray]);
      if (allReportees && Array.isArray(allReportees)) {
        const updatedUsersData = allReportees?.reduce((acc, user) => {
          if (user?.userData?.emailId) {
            acc[user?.userData?.emailId] = user;
          }
          return acc;
        }, {} as Record<string, any>);

        const usersLeavesData: Record<string, any> = {};
        for (const userEmail of Object.keys(updatedUsersData)) {
          const response: any = await FilterFormDetailsWithOfficalEmail(
            MYLEAVE_DETAILS_FORM_ID,
            userEmail
          );
          usersLeavesData[userEmail] = 0;
          for (const leaveData of response?.data?.content) {
            if (
              leaveData?.formData?.status ===
              LeaveApplicationFilterValues.PENDING.value
            )
              usersLeavesData[userEmail] += 1;
          }
        }

        setUserDataWithReportees(updatedUsersData);
        setUsersLeavesData(usersLeavesData);
      }
      return allReportees;
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message:
            response?.message ??
            'Some Error occured whilre retriveing reportees',
          type: CONSTANTS.ERROR,
        })
      );
    }
  };
  useEffect(() => {
    handleFieldChange('officialEmail', userData?.officialEmail);
    handleFieldChange('generalFormId', userData?.generalFormId);
    handleFieldChange('leaveFormId', userData?.leaveFormId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const startPopups = async () => {
    dispatch(setLoader(true));
    const dataArray: any = await fetchAllReportees();
    dispatch(setLoader(false));

    if (dataArray?.length === 0) setOpenDeactivate(true);
    else {
      setOpenReassignmentPopup(true);
    }
  };
  useEffect(() => {
    if (userData && userData?.officialEmail && isDeactivationStarted) {
      startPopups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, isDeactivationStarted]);
  const handleReassignmentPopupClose = () => {
    setOpenReassignmentPopup(false);
    setOpenDeactivate(false);
    setIsDeactivationStarted(false);
  };

  const evaluateReassignmentResult = (success: boolean) => {
    if (success) {
      setOpenReassignmentPopup(false);
      setOpenDeactivate(true);
    }
  };

  return (
    <>
      <ReassignmentPopup
        onClose={handleReassignmentPopupClose}
        open={openReassignmentPopup}
        reportees={userDataWithReportees}
        userData={userData}
        emailsArray={emailsArray}
        callBack={evaluateReassignmentResult}
        reporteesLeaves={usersLeavesData}
      />
      <Modal
        open={openDeactivate}
        onClose={handleCloseDeactivate}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box className={classes?.boxesFormBoxMain}>
            <Button
              className={classes?.buttonsCloseButton}
              onClick={handleCloseDeactivate}
            >
              <CloseOutlinedIcon className={classes?.iconsCloseIcon} />
            </Button>
            <Box
              display={'flex'}
              flexDirection="column"
              justifyContent={'center'}
              alignItems={'center'}
              padding={1}
              gap={2}
            >
              <Box className={classes?.departureDetails}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  className={classes?.formControlProbation}
                >
                  <label className={classes?.heading}>Departure Details:</label>

                  <label className={classes?.label}>
                    <span>Please Select the Last Working Day for </span>
                    <span style={{ fontWeight: 800, color: '#f15830' }}>
                      {row.firstName.charAt(0).toUpperCase() +
                        row.firstName.slice(1).toLowerCase()}{' '}
                      {row.lastName.charAt(0).toUpperCase() +
                        row.lastName.slice(1).toLowerCase()}
                    </span>
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className={classes?.lwdDatePickerDatePicker}
                      onChange={(dateDayJs: any) => {
                        try {
                          const formattedDate = dateDayJs.format('YYYY-MM-DD');
                          handleFieldChange('lwd', formattedDate);
                        } catch (error: any) {
                          handleFieldChange('lwd', '');
                        }
                      }}
                      slotProps={{
                        actionBar: { actions: ['clear', 'today'] },
                      }}
                    />
                  </LocalizationProvider>
                  <label className={classes?.label}>
                    Reason for Departure*
                  </label>
                  <FormControl
                    className={classes?.formControlProbation}
                    size="small"
                  >
                    <Select
                      labelId="probation"
                      id="probation"
                      value={formik.values.departureReason}
                      onChange={(e) => {
                        handleFieldChange('departureReason', e.target.value);
                      }}
                      error={
                        formik.touched.departureReason &&
                        Boolean(formik.errors.departureReason)
                      }
                    >
                      <MenuItem value={'Resignation'}>Resignation</MenuItem>
                      <MenuItem value={'Termination'}>Termination</MenuItem>
                      <MenuItem value={'Retirement'}>Retirement</MenuItem>
                    </Select>
                    {/* <FormHelperText className={{ color: "red" }}>
                        {formOne.touched.departure && formOne.errors.departure}
                      </FormHelperText> */}
                  </FormControl>
                </Box>
                <Box className={classes?.fieldContainer}>
                  <label className={classes?.heading}>
                    Feedback and Remarks:
                  </label>
                  <label className={classes?.label}>Performance Summary</label>

                  <CustomTextField
                    contentText={formik.values.performanceSummary}
                    handleContentTextChange={(e: any) => {
                      handleFieldChange('performanceSummary', e.target.value);
                    }}
                    color={'#121212'}
                    height={'60px'}
                    width={'100%'}
                    fontsize={'16px'}
                    // placeholder={"Performance summary"}
                  />

                  <label className={classes?.label}>Strengths:</label>

                  <CustomTextField
                    contentText={formik.values.departureStrengths}
                    handleContentTextChange={(e: any) => {
                      handleFieldChange('departureStrengths', e.target.value);
                    }}
                    color={'#121212'}
                    height={'60px'}
                    width={'100%'}
                    fontsize={'16px'}
                  />
                  <label className={classes?.label}>
                    Areas for Improvement:
                  </label>

                  <CustomTextField
                    contentText={formik.values.areasImprovement}
                    handleContentTextChange={(e: any) => {
                      handleFieldChange('areasImprovement', e.target.value);
                    }}
                    color={'#121212'}
                    height={'60px'}
                    width={'100%'}
                    fontsize={'16px'}
                  />
                  <label className={classes?.label}>Additional Comments:</label>

                  <CustomTextField
                    contentText={formik.values.additionalComments}
                    handleContentTextChange={(e: any) => {
                      handleFieldChange('additionalComments', e.target.value);
                    }}
                    color={'#121212'}
                    height={'60px'}
                    width={'100%'}
                    fontsize={'16px'}
                  />
                </Box>
                <Box className={classes?.fieldContainer}>
                  <label className={classes?.heading}>
                    Confidentiality and Compliance:
                  </label>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.confidentialityChecked}
                        onClick={() => {
                          handleFieldChange(
                            'confidentialityChecked',
                            !formik.values.confidentialityChecked
                          );
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Check if compliance with company policies on
                        confidentiality is ensured.*
                      </Typography>
                    }
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  disabled={
                    isButtonDisabled ||
                    formik.values.lwd === '' ||
                    formik.values.departureReason === '' ||
                    !formik.values.confidentialityChecked
                  }
                  className={classes?.buttonDepartureReason}
                  type="submit"
                  onClick={() => {
                    formik.submitForm();
                  }}
                >
                  Deactivate
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Modal>

      <Box>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(e) => handleClick(e)}
          className={classes?.iconButton}
        >
          <MoreVertIcon className={classes?.iconMoreVertIcon} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '15ch',
            },
          }}
        >
          {actions?.map((item, index) => {
            const isDisable = Boolean(
              (item.actionId === QuickViewOptions.EDIT &&
                row?.status === EmployeeStatusConstants.INACTIVE) ||
                (item.actionId === QuickViewOptions.DEACTIVATE &&
                  row?.status === EmployeeStatusConstants.INACTIVE) ||
                (item.actionId === QuickViewOptions.DEACTIVATE &&
                  !reduxState?.roles?.some((role) =>
                    modalHrAdminGroup.includes(role)
                  ))
            );
            return (
              <MenuItem
                key={item.actionId || index}
                id={item.actionId}
                onClick={(e) => handleActionClick(e, row, item.actionId)}
                className={classes?.menuItemAction}
                disabled={isDisable}
                style={{
                  display:
                    (item.actionId === QuickViewOptions.DEACTIVATE &&
                      !reduxState?.roles?.some((role) =>
                        modalHrAdminGroup.includes(role)
                      )) ||
                    (item.actionId === QuickViewOptions.EDIT &&
                      reduxState?.roles?.includes(ROLE_EMP_HR_VIEW))
                      ? 'none'
                      : '',
                }}
              >
                {item.actionName}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </>
  );
};

export default MoreIcon;
