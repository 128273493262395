import { Box, Button, Typography, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from '../../components/common/ConfirmationModal/ConfirmationModal';
import TsDatagrid from '../../components/TsDatagrid';
import CONSTANTS from '../../constants/constants';
import { MYLEAVE_DETAILS_FORM_ID } from '../../constants/formConstants';
import { getSessionStorageItem } from '../../encrypt';
import { pushNotification } from '../../redux/actions';
import {
  capitalize,
  formatDate,
  getFullName,
} from '../../services/commonHelperService';
import {
  ApproveRejectLeave,
  FilterFormDetailsWithReportingTo,
  UpdateCompOffLeaveStatus,
} from '../../services/lmsService';
import { leaveTypeEnums } from '../uiElementsAccess';
import {
  dataGridStyles,
  DataGridUtils,
  FilterState,
  LeaveApplcationUtils,
  LeaveApplicationFilterValues,
} from './leaveManagementSystemConstants';
import './teamLeaves.css';
import useCustomStyles from '../../components/common/styles/CustomStylesHook';

interface TeamLeavesProps {
  isWFH: boolean;
  filterState: FilterState;
}

const TeamLeaves = ({ isWFH = false, filterState }: TeamLeavesProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useCustomStyles(dataGridStyles, theme);
  const emailId: any = getSessionStorageItem('user-email');
  const [teamLeavesData, setTeamLeavesData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState('');
  const [selectedDecision, setSelectedDecision] = useState('');
  const [filteredData, setFilteredData] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);

  const [isAcceptReject, setIsAcceptReject] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const openConfirmationDialog = (item, decision) => {
    setSelectedLeaveRequest(item);
    setSelectedDecision(decision);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationDialog = () => {
    setSelectedLeaveRequest('');
    setSelectedDecision('');
    setIsConfirmationModalOpen(false);
  };

  useEffect(() => {
    handleGetLeavesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Filter based on the status in filterState

    const filteredLeaveList = teamLeavesData?.filter((leaveItem) => {
      if (isWFH) {
        return leaveItem?.leaveType === leaveTypeEnums?.WFH;
      } else {
        return leaveItem?.leaveType !== leaveTypeEnums?.WFH;
      }
    });
    if (filterState?.status === LeaveApplicationFilterValues?.ALL?.value) {
      setFilteredData(filteredLeaveList);
    } else {
      const filtered = filteredLeaveList?.filter(
        (item) => item?.status === filterState?.status
      );
      setFilteredData(filtered);
    }
  }, [filterState, teamLeavesData, isWFH]);

  const handleGetLeavesData = async () => {
    setIsLoading(true);
    const response = await FilterFormDetailsWithReportingTo(
      MYLEAVE_DETAILS_FORM_ID,
      emailId
    );
    if (response?.success && response?.data?.length > 0) {
      const teamData = response?.data?.map((item) => {
        const mapData = item?.formData;
        return {
          id: item?.id,
          firstName: mapData?.firstName,
          lastName: mapData?.lastName,
          empId: mapData?.employeeId,
          officialEmail: mapData?.officialEmail,
          reportingTo: mapData?.reportingTo,
          fromDate: mapData?.fromDate,
          toDate: mapData?.toDate,
          totalDays: mapData?.totalDays,
          status: mapData?.status,
          comments: mapData?.reason,
          lop: mapData?.lopDays,
          leaveType: mapData?.leaveType,
          fullName: `${getFullName([
            mapData?.firstName,
            mapData?.lastName,
          ])} - (${mapData?.employeeId})`,
          appliedOn: item?.createdOn,
          approvedOn:
            Boolean(item?.formData?.approvedOn) &&
            item?.formData?.approvedOn !== ''
              ? item?.formData?.approvedOn
              : '-',
        };
      });

      setTeamLeavesData(teamData);
      setIsLoading(false);
    } else {
      setTeamLeavesData([]);
      setIsLoading(false);
    }
  };

  const handleAcceptReject = async (leaveRequest, decision) => {
    setIsAcceptReject(true);
    let response;
    try {
      if (leaveRequest?.leaveType === leaveTypeEnums?.COMP_OFF) {
        response = await UpdateCompOffLeaveStatus(leaveRequest?.id, decision);
      } else {
        response = await ApproveRejectLeave(leaveRequest?.id, decision);
      }
      if (response?.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS?.SUCCESS,
          })
        );
        setIsConfirmationModalOpen(false);
        setIsAcceptReject(false);
        await handleGetLeavesData();
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response?.message,
            type: CONSTANTS?.ERROR,
          })
        );
        setIsConfirmationModalOpen(false);
        setIsAcceptReject(false);
      }
    } catch (error) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: `Encountered an Error: ${error}`,
          type: CONSTANTS?.ERROR,
        })
      );
      setIsConfirmationModalOpen(false);
      setIsAcceptReject(false);
    }
  };

  // const getConfirmationTitleForTeamLeaves = () => {
  //   return `${selectedDecision?.toUpperCase()} Leave Request`;
  // };

  const getConfirmationMessageForTeamLeaves = () => {
    return `Do you want to ${selectedDecision} this Leave Request?`;
  };
  // const getConfirmationTitleForTeamWFHRequests = () => {
  //   return `${selectedDecision?.toUpperCase()} WFH Request`;
  // };

  const getConfirmationMessageForTeamWFHRequests = () => {
    return `Do you want to ${selectedDecision} this WFH Request?`;
  };

  const getTableHeader = (title) => {
    return (
      <Typography className={classes?.tableHeaderStyle}>{title}</Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: 'User',
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'fromDate',
      headerName: 'From',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'toDate',
      headerName: 'To',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => formatDate(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'totalDays',
      headerName: 'No.of.days',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => capitalize(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'leaveType',
      headerName: !isWFH ? 'Leave Type' : 'Request Type',
      flex: 1,
      editable: false,
      // hide: isWFH,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (row) => capitalize(row?.value),
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'lop',
      headerName: 'Lop Days',
      flex: 1,
      editable: false,
      minWidth: 150,
      hide: isWFH,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'appliedOn',
      headerName: 'Applied On',
      editable: false,
      headerAlign: 'center',
      minWidth: 200,
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row: any) => {
        const value = dayjs(new Date(row?.value))
          .format('DD/MM/YYYY - hh:mm a')
          .replace('am', 'AM')
          .replace('pm', 'PM');
        if (value === 'Invalid Date') return '-';
        else return value;
      },
      flex: 1,
    },
    {
      field: 'approvedOn',
      headerName: 'Approved on',
      minWidth: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      // valueFormatter: (row: any) => {
      //   const value = dayjs(new Date(row?.value)).format("DD/MM/YYYY");
      //   if (value === "Invalid Date") return "-";
      //   else return value;
      // },
      flex: 1,
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      editable: false,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (props) => {
        const item = props?.row;
        return (
          <Box display={'flex'} gap={1.5}>
            <Button
              id="Leave-Accept"
              variant="contained"
              size="small"
              className={classes?.acceptButtonStyle}
              onClick={() => {
                openConfirmationDialog(item, 'approve');
              }}
              disabled={item?.status !== 'pending'}
            >
              Accept
            </Button>
            <Button
              id="Leave-Reject"
              size="small"
              className={classes?.rejectButtonstyle}
              onClick={() => {
                openConfirmationDialog(item, 'reject');
              }}
              disabled={item?.status !== 'pending'}
            >
              Reject
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          id="confirmationModal"
          title={
            'Confirm Decision'
            // isWFH
            //   ? getConfirmationTitleForTeamWFHRequests()
            //   : getConfirmationMessageForTeamLeaves()
          }
          message={
            isWFH
              ? getConfirmationMessageForTeamWFHRequests()
              : getConfirmationMessageForTeamLeaves()
          }
          open={isConfirmationModalOpen}
          onClose={closeConfirmationDialog}
          onSubmit={() =>
            handleAcceptReject(selectedLeaveRequest, selectedDecision)
          }
          isSubmitButtonDisabled={isAcceptReject}
        />
      )}

      <div>
        <Box className="teamsContainer">
          <TsDatagrid
            rows={filteredData}
            columns={columns}
            pageSizeArray={DataGridUtils?.PAGE_SIZE_ARRAY}
            getSelectedRowsData={() => {
              // TODO: Implement function
            }}
            handlePageChange={() => {
              // TODO: Implement function
            }}
            handlePageSizeChange={() => {
              // TODO: Implement function
            }}
            pageSize={rowsPerPage}
            hideFooter={false}
            onPageSizeChange={(value) => setRowsPerPage(value)}
            isCheckboxSelection={false}
            totalElements={filteredData?.length}
            className={classes?.tableStyle}
            components={{
              NoRowsOverlay: () => (
                <Box className={classes?.overlayStyle}>
                  {isLoading
                    ? LeaveApplcationUtils?.LOADING_REQUESTS
                    : LeaveApplcationUtils?.NO_REQUESTS_AVAILABLE}
                </Box>
              ),
            }}
          />
        </Box>
      </div>
    </>
  );
};

export default TeamLeaves;
