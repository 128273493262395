import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { StatusConstants } from './types/ExpenseTrackerTypes';
import useCustomStyles from '../../components/common/styles/CustomStylesHook';
import {
  ActionButtonTypes,
  AmountApprovalTypes,
  StatusHeadingMap,
} from './utils/expenseUtils';
import { COMMON_CONSTANTS } from '../adminConstants';

const styles = () => ({
  mainContainer: {
    position: 'absolute !important',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    backgroundColor: 'white !important',
    borderRadius: '8px !important',
    padding: '1.5rem !important',
    minWidth: 300,
    width: '30vw !important',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem !important',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem !important',
  },
  marginBottom_2: { mb: 2 },
  textFieldCommonFontStyles: {
    '& .MuiOutlinedInput-input': {
      fontSize: '0.9rem !important',
    },
  },
  boxFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 2,
  },
});

interface PopupProps {
  open: boolean;
  type: StatusConstants; // Type to define what action to perform
  amountRequested: number;
  onClose: () => void;
  onConfirm: (data: any) => void;
}

const ActionPopup: React.FC<PopupProps> = ({
  open,
  type,
  amountRequested,
  onClose,
  onConfirm,
}) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [isPartial, setIsPartial] = useState(false);
  const [approvedAmount, setApprovedAmount] = useState(amountRequested);
  const [reason, setReason] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [approvedAmountError, setApprovedAmountError] = useState('');
  const [reasonError, setReasonError] = useState('');

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event?.target?.value);
    setApprovedAmount(value);
    if (!value || value <= 0) {
      setApprovedAmountError(COMMON_CONSTANTS?.AMOUNT_FIELD_ERROR);
    } else {
      setApprovedAmountError('');
    }
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event?.target?.value);
    setReasonError(
      event?.target?.value?.trim() === ''
        ? COMMON_CONSTANTS?.REASON_FIELD_ERROR
        : ''
    );
    setIsSubmitDisabled(event?.target?.value?.trim() === '');
  };

  const validateFields = () => {
    let isValid = true;

    if (
      type === StatusConstants?.APPROVED &&
      isPartial &&
      (!approvedAmount || approvedAmount <= 0)
    ) {
      setApprovedAmountError(COMMON_CONSTANTS?.AMOUNT_FIELD_ERROR);
      isValid = false;
    }

    if (
      (type === StatusConstants?.APPROVED && isPartial && reason === '') ||
      ((type === StatusConstants?.REJECTED ||
        type === StatusConstants?.REVOKED) &&
        reason === '')
    ) {
      setReasonError(COMMON_CONSTANTS?.REASON_FIELD_ERROR);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (!validateFields()) {
      return;
    }

    const data = {
      type,
      approvedAmount,
      reason:reason?.trim(),
    };

    onConfirm(data);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="action-popup-title">
      <Box className={classes?.mainContainer}>
        <Typography
          id="action-popup-title"
          variant="h6"
          className={classes?.marginBottom_2}
        >
          {StatusHeadingMap[type]}
        </Typography>

        {/* Body */}
        {type === StatusConstants?.APPROVED && (
          <Box className={classes?.columnContainer}>
            <FormControl fullWidth className={classes?.marginBottom_2}>
              <InputLabel>Approval Type</InputLabel>
              <Select
                value={
                  isPartial
                    ? AmountApprovalTypes?.PARTIAL
                    : AmountApprovalTypes?.FULL
                }
                onChange={(e) =>
                  setIsPartial(
                    e?.target?.value === AmountApprovalTypes?.PARTIAL
                  )
                }
                label="Approval Type"
              >
                <MenuItem value={AmountApprovalTypes?.FULL}>
                  Full Amount
                </MenuItem>
                <MenuItem value={AmountApprovalTypes?.PARTIAL}>
                  Partial Amount
                </MenuItem>
              </Select>
            </FormControl>

            {isPartial && (
              <TextField
                label="Amount to Approve"
                type="number"
                fullWidth
                value={approvedAmount}
                onChange={handleAmountChange}
                className={classes?.marginBottom_2}
                inputProps={{ min: 0 }}
                error={!!approvedAmountError}
                helperText={approvedAmountError}
              />
            )}

            {isPartial && (
              <TextField
                label="Reason for Deviation"
                fullWidth
                value={reason}
                onChange={handleReasonChange}
                className={`${classes?.marginBottom_2} ${classes?.textFieldCommonFontStyles}`}
                multiline
                maxRows={5}
                minRows={3}
                error={!!reasonError}
                helperText={reasonError}
              />
            )}
          </Box>
        )}

        {type === StatusConstants?.REJECTED ||
        type === StatusConstants?.REVOKED ? (
          <TextField
            label="Reason"
            fullWidth
            value={reason}
            onChange={handleReasonChange}
            className={`${classes?.marginBottom_2} ${classes?.textFieldCommonFontStyles}`}
            multiline
            maxRows={5}
            minRows={3}
            error={!!reasonError}
            helperText={reasonError}
          />
        ) : null}

        {/* Footer with buttons */}
        <Box className={classes?.boxFooter}>
          <Button variant="outlined" onClick={onClose}>
            {COMMON_CONSTANTS.CANCEL}
          </Button>
          <Button
            variant="contained"
            color={type === StatusConstants?.APPROVED ? 'success' : 'error'}
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {ActionButtonTypes[type] || ActionButtonTypes?.action}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ActionPopup;
