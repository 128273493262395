import * as Yup from 'yup';

export const ToolTipMessages = {
  reportingName:
    'Signature Name will appear in the Regards section of your email.',
  reportingEmails: 'Select emails to which the reports are to be sent.',
  hoursPerDay: 'Required hours an employee should work each day.',
  warningHours:
    'Hours an employee can work without issue, but approaching the limit.',
  criticalHours:
    'Hours that signal a potential performance issue or error by the employee.',
    employeeIdStartWithMessage:
    'Specify the prefix or code that is used for all Employee IDs in the system.',
};
export const attendanceWeekdays = [
  { label: 'Monday', value: 'MONDAY' },
  { label: 'Tuesday', value: 'TUESDAY' },
  { label: 'Wednesday', value: 'WEDNESDAY' },
  { label: 'Thursday', value: 'THURSDAY' },
  { label: 'Friday', value: 'FRIDAY' },
  { label: 'Saturday', value: 'SATURDAY' },
  { label: 'Sunday', value: 'SUNDAY' },
];

export const monitorFrequencyOptions = [
  { label: 'Every 15 Minutes', value: '15-min' },
  { label: 'Every 30 Minutes', value: '30-min' },
  { label: 'Every Hour', value: 'hourly' },
];

export interface FormValues {
  enableAutoReports: boolean;
  enableManualReports: boolean;
  enableMonitoring: boolean;
  enableLopMails: boolean;
  reportsTime: string;
  monitoringStartTime: string;
  monitoringEndTime: string;
  monitoringFrequency: string;
  reportingName: string;
  employeeIdCode: string;
  reportingEmails: string[];
  checkInTime: string;
  checkOutTime: string;
  acceptableDelayInMinutes: string;
  markLateCheckin: boolean;
  criticalHours: string | number;
  warningHours: string | number;
  hoursPerDay: string | number;
  weekends: string[];
  restartSchedulers?: boolean;
}

// validationMessages.js
export const AttendanceDataValidationMessages = {
  REQUIRED: 'Required',
  INVALID_EMAIL: 'Invalid email format',
  SIGNATURE_NAME_REQUIRED: 'Signature Name is Required',
  EMPLOYEE_ID_REQUIRED: 'Employee ID Code is Required',
  REPORTING_EMAILS_REQUIRED: 'Reporting Emails are Required',
  CHECK_IN_TIME_REQUIRED: 'Check In Time is Required',
  CHECK_OUT_TIME_REQUIRED: 'Check Out Time is Required',
  ACCEPTABLE_DELAY_REQUIRED: 'Acceptable Delay is Required',
  MARK_LATE_CHECKIN_REQUIRED: 'Required',
  CRITICAL_HOURS_REQUIRED: 'Critical Hours is Required',
  WARNING_HOURS_REQUIRED: 'Warning Hours is Required',
  HOURS_PER_DAY_REQUIRED: 'Hours per day are Required',
  WEEK_OFFS_LIST_REQUIRED: 'Week Offs list is Required',
  DELAY_VALUE_CANNOT_BE_NAN: 'The delay value cannot be Empty.',
};
export const AttendanceTimeFormats = {
  HH_MM_A: 'hh:mm A',
};

export const AttendanceDataValidationSchema = Yup.object({
  enableAutoReports: Yup.boolean().required(
    AttendanceDataValidationMessages.REQUIRED
  ),
  enableManualReports: Yup.boolean().required(
    AttendanceDataValidationMessages.REQUIRED
  ),
  enableMonitoring: Yup.boolean().required(
    AttendanceDataValidationMessages.REQUIRED
  ),
  enableLopMails: Yup.boolean().required(
    AttendanceDataValidationMessages.REQUIRED
  ),
  reportsTime: Yup.string().required(AttendanceDataValidationMessages.REQUIRED),
  monitoringStartTime: Yup.string().required(
    AttendanceDataValidationMessages.REQUIRED
  ),
  monitoringEndTime: Yup.string().required(
    AttendanceDataValidationMessages.REQUIRED
  ),
  monitoringFrequency: Yup.string().required(
    AttendanceDataValidationMessages.REQUIRED
  ),
  reportingName: Yup.string().required(
    AttendanceDataValidationMessages.SIGNATURE_NAME_REQUIRED
  ),
  employeeIdCode: Yup.string().required(
    AttendanceDataValidationMessages.EMPLOYEE_ID_REQUIRED
  ),
  reportingEmails: Yup.array()
    .of(Yup.string().email(AttendanceDataValidationMessages.INVALID_EMAIL))
    .required(AttendanceDataValidationMessages.REPORTING_EMAILS_REQUIRED)
    .min(1, AttendanceDataValidationMessages.REPORTING_EMAILS_REQUIRED),
  checkInTime: Yup.string().required(
    AttendanceDataValidationMessages.CHECK_IN_TIME_REQUIRED
  ),
  checkOutTime: Yup.string().required(
    AttendanceDataValidationMessages.CHECK_OUT_TIME_REQUIRED
  ),
  acceptableDelayInMinutes: Yup.string()
    .required(AttendanceDataValidationMessages.ACCEPTABLE_DELAY_REQUIRED)
    .notOneOf(
      ['NaN'],
      AttendanceDataValidationMessages.DELAY_VALUE_CANNOT_BE_NAN
    ),
  markLateCheckin: Yup.boolean().required(
    AttendanceDataValidationMessages.MARK_LATE_CHECKIN_REQUIRED
  ),
  criticalHours: Yup.number().required(
    AttendanceDataValidationMessages.CRITICAL_HOURS_REQUIRED
  ),
  warningHours: Yup.number().required(
    AttendanceDataValidationMessages.WARNING_HOURS_REQUIRED
  ),
  hoursPerDay: Yup.number().required(
    AttendanceDataValidationMessages.HOURS_PER_DAY_REQUIRED
  ),
  weekends: Yup.array()
    .of(Yup.string())
    .required(AttendanceDataValidationMessages.WEEK_OFFS_LIST_REQUIRED)
    .min(1, AttendanceDataValidationMessages.WEEK_OFFS_LIST_REQUIRED),
});
