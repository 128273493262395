import { StatusConstants } from '../types/ExpenseTrackerTypes';

export const getSupportedDocsForThisExpense = (allDocs, supportingDocs) => {
  // Check if allFiles and supportingDocs are arrays
  if (!Array.isArray(allDocs) || !Array.isArray(supportingDocs)) {
    return []; // Return an empty array if either is not an array
  }
  const filteredDocs = allDocs?.filter((file) =>
    supportingDocs?.includes(file?.documentId)
  );
  return filteredDocs;
};

export const StatusHeadingMap = {
  [StatusConstants.APPROVED]: 'Approve Reimbursement',
  [StatusConstants.REJECTED]: 'Reject Reimbursement',
  [StatusConstants.REVOKED]: 'Revoke Reimbursement',
};

export const ButtonActionMap = {
  [StatusConstants.APPROVED]: {
    text: 'Approve',
    color: 'success',
  },
  [StatusConstants.REJECTED]: {
    text: 'Reject',
    color: 'error',
  },
  [StatusConstants.REVOKED]: {
    text: 'Revoke',
    color: 'error',
  },
};

export const AmountApprovalTypes = {
  PARTIAL: 'partial',
  FULL: 'full',
};
export const ActionButtonTypes = {
  [StatusConstants.APPROVED]: 'Approve',
  [StatusConstants.REJECTED]: 'Reject',
  [StatusConstants.REVOKED]: 'Revoke',
  action: 'Action',
};

export const ErrorMessages = {
  FAILD_TO_FETCH_USER_DATA: 'Failed to fetch User Data',
};

export const stripCurrencyFormatting = (value) => {
  return value?.replace(/[^\d]/g, '');
};
export const removeSpaces = (value: string) => {
  return value?.replace(/\s/g, '');
};

export const ValidationUtils = {
  AlphabeticRegexAtLeast5: /^(?=[\s\S]{5,})[\s\S]*$/,
  MESSAGE_REQUEST_TYPE_REQUIRED: 'Request Type is required',
  AT_LEAST_FIVE_CHARACTERS_REQUIRED: 'At least 5 characters required',
  MESSAGE_REQUEST_AMOUNT_REQUIRED: 'Request Amount is required',
  MESSAGE_REQUEST_AMOUNT_NUMBER: 'Request Amount must be a number',
  MESSAGE_REQUEST_AMOUNT_NEGATIVE: 'Request Amount cannot be negative',
  MESSAGE_DESCRIPTION_MAX_LENGTH: 'Description cannot exceed 250 characters',
};
