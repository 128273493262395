import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@mui/material';
import useCustomStyles from '../../../components/common/styles/CustomStylesHook';

interface ConfirmationDialogProps {
  open: any;
  onClose: any;
  title: any;
  message: any;
}

const styles = () => ({
  button: {
    textTransform: 'capitalize',
  },
});

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  message,
}: ConfirmationDialogProps) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(false)}
          color="primary"
          className={classes?.button}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onClose(true)}
          color="primary"
          className={classes?.button}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
