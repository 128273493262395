import React from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

interface Tabs {
  title: string;
  index: number;
  icon: React.ReactNode;
  url: string;
}

export const hrAdminTabs: Array<Tabs> = [
  {
    title: 'User Profile',

    index: 0,

    icon: <AccountCircleOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}`,
  },

  {
    title: 'Dashboard',

    index: 1,

    icon: <DashboardOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/employes-list`,
  },
  {
    title: 'Leave Management',

    index: 2,

    icon: <AirplaneTicketOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/lms`,
  },

  {
    title: 'Admin',

    index: 3,

    icon: <ManageAccountsOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/admin`,
  },
  {
    title: 'Visitors',

    index: 4,

    icon: <SupervisorAccountOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/visitors`,
  },
  {
    title: 'Expense Tracker',

    index: 5,

    icon: <ReceiptLongOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/expense-tracker`,
  },
  {
    title: 'ISMS Policies',

    index: 6,

    icon: <SecurityIcon />,

    url: `${process.env.PUBLIC_URL}/policies`,
  },
];

export const employeeTabs: Array<Tabs> = [
  {
    title: 'User Profile',

    index: 0,

    icon: <AccountCircleOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}`,
  },
  {
    title: 'Leave Management',

    index: 1,

    icon: <AirplaneTicketOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/lms`,
  },

  {
    title: 'Visitors',

    index: 2,

    icon: <SupervisorAccountOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/visitors`,
  },
  {
    title: 'Expense Tracker',

    index: 3,

    icon: <ReceiptLongOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/expense-tracker`,
  },
  {
    title: 'ISMS Policies',

    index: 4,

    icon: <SecurityIcon />,

    url: `${process.env.PUBLIC_URL}/policies`,
  },
];

export const managerTabs: Array<Tabs> = [
  {
    title: 'User Profile',

    index: 0,

    icon: <AccountCircleOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}`,
  },
  {
    title: 'Dashboard',

    index: 1,

    icon: <DashboardOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/employes-list`,
  },

  {
    title: 'Leave Management',

    index: 2,

    icon: <AirplaneTicketOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/lms`,
  },

  {
    title: 'Visitors',

    index: 3,

    icon: <SupervisorAccountOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/visitors`,
  },
  {
    title: 'Expense Tracker',

    index: 4,

    icon: <ReceiptLongOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/expense-tracker`,
  },
  {
    title: 'ISMS Policies',

    index: 5,

    icon: <SecurityIcon />,

    url: `${process.env.PUBLIC_URL}/policies`,
  },
];

export const defaultTabs: Array<Tabs> = [
  {
    title: 'User Profile',

    index: 0,

    icon: <AccountCircleOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}`,
  },
  {
    title: 'Leave Management',

    index: 1,

    icon: <AirplaneTicketOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/lms`,
  },
  {
    title: 'Visitors',

    index: 2,

    icon: <SupervisorAccountOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/visitors`,
  },
  {
    title: 'Expense Tracker',

    index: 3,

    icon: <ReceiptLongOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/expense-tracker`,
  },
  {
    title: 'ISMS Policies',

    index: 4,

    icon: <SecurityIcon />,

    url: `${process.env.PUBLIC_URL}/policies`,
  },
];

export const ncgTabs: Array<Tabs> = [
  {
    title: 'User Profile',

    index: 0,

    icon: <AccountCircleOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}`,
  },
  {
    title: 'Dashboard',

    index: 1,

    icon: <DashboardOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/employes-list`,
  },
  {
    title: 'Leave Management',

    index: 2,

    icon: <AirplaneTicketOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/lms`,
  },

  {
    title: 'Visitors',

    index: 3,

    icon: <SupervisorAccountOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/visitors`,
  },
  {
    title: 'Expense Tracker',

    index: 4,

    icon: <ReceiptLongOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/expense-tracker`,
  },
  {
    title: 'ISMS Policies',

    index: 5,

    icon: <SecurityIcon />,

    url: `${process.env.PUBLIC_URL}/policies`,
  },
];

export const technicalArchitectTabs: Array<Tabs> = [
  {
    title: 'User Profile',

    index: 0,

    icon: <AccountCircleOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}`,
  },
  {
    title: 'Dashboard',

    index: 1,

    icon: <DashboardOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/employes-list`,
  },

  {
    title: 'Leave Management',

    index: 2,

    icon: <AirplaneTicketOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/lms`,
  },

  {
    title: 'Visitors',

    index: 3,

    icon: <SupervisorAccountOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/visitors`,
  },
  {
    title: 'Expense Tracker',

    index: 4,

    icon: <ReceiptLongOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/expense-tracker`,
  },
  {
    title: 'ISMS Policies',

    index: 5,

    icon: <SecurityIcon />,

    url: `${process.env.PUBLIC_URL}/policies`,
  },
];

export const softwareArchitectTabs: Array<Tabs> = [
  {
    title: 'User Profile',

    index: 0,

    icon: <AccountCircleOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}`,
  },
  {
    title: 'Dashboard',

    index: 1,

    icon: <DashboardOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/employes-list`,
  },

  {
    title: 'Leave Management',

    index: 2,

    icon: <AirplaneTicketOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/lms`,
  },

  {
    title: 'Visitors',

    index: 3,

    icon: <SupervisorAccountOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/visitors`,
  },
  {
    title: 'Expense Tracker',

    index: 4,

    icon: <ReceiptLongOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/expense-tracker`,
  },
  {
    title: 'ISMS Policies',

    index: 5,

    icon: <SecurityIcon />,

    url: `${process.env.PUBLIC_URL}/policies`,
  },
];

export const financeTabs: Array<Tabs> = [
  {
    title: 'User Profile',

    index: 0,

    icon: <AccountCircleOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}`,
  },
  {
    title: 'Dashboard',

    index: 1,

    icon: <DashboardOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/employes-list`,
  },
  {
    title: 'Leave Management',

    index: 2,

    icon: <AirplaneTicketOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/lms`,
  },

  {
    title: 'Visitors',

    index: 3,

    icon: <SupervisorAccountOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/visitors`,
  },
  {
    title: 'Expense Tracker',

    index: 4,

    icon: <ReceiptLongOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/expense-tracker`,
  },
  {
    title: 'ISMS Policies',

    index: 5,

    icon: <SecurityIcon />,

    url: `${process.env.PUBLIC_URL}/policies`,
  },
];

export const unifiedTabs = [
  {
    title: 'User Profile',
    index: 0,
    icon: <AccountCircleOutlinedIcon />,
    url: `${process?.env?.PUBLIC_URL}`,
  },
  {
    title: 'Dashboard',
    index: 1,
    icon: <DashboardOutlinedIcon />,
    url: `${process?.env?.PUBLIC_URL}/employes-list`,
  },
  {
    title: 'Leave Management',
    index: 2,
    icon: <AirplaneTicketOutlinedIcon />,
    url: `${process?.env?.PUBLIC_URL}/lms`,
  },
  {
    title: 'Admin',
    index: 3,
    icon: <ManageAccountsOutlinedIcon />,
    url: `${process?.env?.PUBLIC_URL}/admin`,
  },
  {
    title: 'Visitors',
    index: 4,
    icon: <SupervisorAccountOutlinedIcon />,
    url: `${process?.env?.PUBLIC_URL}/visitors`,
  },
  {
    title: 'Expense Tracker',

    index: 5,

    icon: <ReceiptLongOutlinedIcon />,

    url: `${process.env.PUBLIC_URL}/expense-tracker`,
  },
  {
    title: 'ISMS Policies',
    index: 6,
    icon: <SecurityIcon />,
    url: `${process?.env?.PUBLIC_URL}/policies`,
  },
];

/**
 * Sorts the given tabs array based on the order defined by the unifiedTabs array,
 * and updates the index to start from 0 in the sorted order.
 *
 * @param {Array} unifiedTabs - The reference array that defines the order.
 * @param {Array} tabsToSort - The array of tabs to be sorted.
 * @returns {Array} - The sorted array of tabs with updated indices.
 */
export function sortAndIndexTabs(tabsToSort) {
  // Create a map to easily find the index of each tab in unifiedTabs
  const unifiedIndexMap = new Map(
    unifiedTabs?.map((tab) => [tab?.title, tab?.index])
  );

  // Sort tabsToSort based on the order defined in unifiedTabs
  const sortedTabs = tabsToSort?.sort((a, b) => {
    const indexA: any = unifiedIndexMap?.get(a?.title) ?? Infinity;
    const indexB: any = unifiedIndexMap?.get(b?.title) ?? Infinity;
    return indexA - indexB;
  });

  // Update the index for sorted tabs to start from 0
  return sortedTabs?.map((tab, newIndex) => ({
    ...tab,
    index: newIndex,
  }));
}

// const sortedTabs = sortAndIndexTabs(unifiedTabs, tabsToSort);
