import wretch from 'wretch';
import CONSTANTS from '../constants/constants';
import {
  COMMON_BACKEND_ENDPOINT,
  EXPENSE_END_POINT,
  DOWNLOAD_ALL_EXPENSES_END_POINT,
} from '../constants/endpoints';
import { getSessionStorageItem } from '../encrypt';
import { COMMON_CONSTANTS } from '../screen/adminConstants';

// Function to create a new visitor
export const createOrUpdateExpenseRequest = async (expense: any) => {
  try {
    const response = await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${EXPENSE_END_POINT}`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS?.REACT_TOKEN)}`)
      .post(expense)
      .json();
    if (!response?.success) {
      throw new Error(
        response?.message
          ? response?.message
          : COMMON_CONSTANTS?.SOME_ERROR_OCCURED
      );
    }
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message
        ? JSON?.parse(error?.message).message
        : COMMON_CONSTANTS?.SOME_ERROR_OCCURED
    );
  }
};

// Function to get visitors with filters
export const getExpenseRequests = async (filters: any) => {
  try {
    const response = await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${EXPENSE_END_POINT}/search`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS?.REACT_TOKEN)}`)
      // .query(params)
      .post(filters)
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message
        ? JSON?.parse(error?.message).message
        : COMMON_CONSTANTS?.SOME_ERROR_OCCURED
    );
  }
};

export const approveOrRejectExpenseRequest = async (
  expenseId: string,
  payload: any
) => {
  try {
    const response = await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${EXPENSE_END_POINT}/${expenseId}`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS?.REACT_TOKEN)}`)
      .put(payload)
      .json();
    if (!response?.success) {
      throw new Error(
        response?.message ?? COMMON_CONSTANTS?.SOME_ERROR_OCCURED
      );
    }
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message ? error?.message : COMMON_CONSTANTS?.SOME_ERROR_OCCURED
    );
  }
};

// Function to download visitors as an Excel file
export const downloadExpenseExcel = async () => {
  try {
    await wretch(
      `${`${getSessionStorageItem(
        'gatewayURL'
      )}`}${COMMON_BACKEND_ENDPOINT}${DOWNLOAD_ALL_EXPENSES_END_POINT}`
    )
      .auth(`Bearer ${getSessionStorageItem(CONSTANTS?.REACT_TOKEN)}`)
      .post()
      .blob((blob) => {
        const url = window?.URL?.createObjectURL(blob);
        const link = document?.createElement('a');
        link.href = url;
        link?.setAttribute(
          COMMON_CONSTANTS?.DOWNLOAD,
          COMMON_CONSTANTS?.EXPENSE_REQUEST_XLSX
        ); // You can set the file name here
        document?.body?.appendChild(link);
        link?.click();
        document?.body?.removeChild(link);
      });
  } catch (error: any) {
    throw new Error(
      error?.message
        ? JSON?.parse(error?.message).message
        : COMMON_CONSTANTS?.SOME_ERROR_OCCURED
    );
  }
};
