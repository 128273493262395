import React, { useEffect, useState } from 'react';
import AddVisitorForm from './forms/AddVisitorForm';
import VisitorLogTable from './VisitorLogTable';
import { useDispatch, useSelector } from 'react-redux';
import { StatusConstants } from './types/ReceptionTypes';
import VisitorLogToolbar from './VisitorLogToolbar';
import VisitorHeading from './VisitorHeading';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import {
  convertStatus,
  createHostDetailsMap,
  sanitizeMobileNumber,
} from './utils/utilityFuntions';
import {
  approveOrRejectVisitor,
  checkinVisitor,
  checkoutVisitor,
  createOrUpdate,
  downloadVisitorsExcel,
  getVisitors,
} from '../../services/visitorApiService';
import { setVisitors, updateVisitor } from '../../redux/actions';
import ConfirmationDialog from './utils/ConfirmationDialog';
import UpcomingEvents from './showcase/UpcomingEvents';
import { getRoles } from '../../services/roleService';
import { getAllUsers } from '../../services/userService';
import UpcomingHoliday from '../LeaveManagementSystem/UpcomingHoliday/upcomingHoliday';
import { fetchRuntimeFormData } from '../../services/formService';
import { HOLIDAYS_FORMID } from '../../constants/constants';
import Notification from '../../components/common/notification';
import { NotificationProps } from '../../redux/reducer';
import { getSessionStorageItem } from '../../encrypt';

const styles = {
  containers: {
    containerMain: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      alignContent: 'space-between',
      padding: '18px 24px',
      position: 'relative',
      boxSizing: 'border-box',
      background: '#E3E7EF',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
    },
    containerTop: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '216px',
    },
    visitorHeading: {
      maxWidth: '50% ',
      height: '216px',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '32px',
      minWidth: '556px',
    },
    upCommingEvents: {
      flexGrow: '1',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'stretch',
      marginLeft: '4vw',
      backgroundPosition: 'center -300px',
      boxShadow: '0 0 4px #777',
      background: '#ffffffff',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      borderRadius: '14px',
      minWidth: '356px',
      overflow: 'hidden',
      position: 'relative',
      marginRight: '1vw',
    },
    upCommingEventsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      width: '100%',
    },
    holidaysWrapper: {
      boxShadow: '0 0 4px #777',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      display: 'flex',
      alignItems: 'stretch',
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Reception = () => {
  const [showAddVisitorForm, setShowAddVisitorForm] = useState(false);
  const [visitorStatus, setVisitorStatus] = useState<any>('All');

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarSeverity, setSeverity] =
    useState<NotificationProps['type']>('success');

  const [searchText, setSearchText] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string | null>('');
  const [page, setPage] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [rows, setRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationPromise, setConfirmationPromise] = useState<any>(null);
  const [message, setMessage] = useState('');
  const [isAdmin, setIsAdmin] = useState<any>(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [isSingleTime, setIsSingleTime] = useState(false);

  const [emails, setEmails] = useState([]);
  const [emailMobileFullNamePairs, setEmailMobileFullNamePairs] =
    useState<any>();
  const [next3HolidaysList, setNext3HolidaysList] = useState<any>([]);
  const [userName, setUserName] = useState('User');
  const [initialSearchTextCaller, setInitialSearchTextCaller] = useState(true);
  let fetchVisitorCaller = '----------';

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  const dispatch = useDispatch();
  const rowsStateData = useSelector((state: any) => state?.items);

  const fetchVisitors = async (caller: string) => {
    if (caller === fetchVisitorCaller) return;
    else fetchVisitorCaller = caller;
    setIsLoading(true);
    const tempStatus = convertStatus(visitorStatus);
    try {
      const response = await getVisitors(
        searchText?.trim(),
        selectedDate || '',
        tempStatus || '',
        page - 1,
        rowsPerPage
      );
      const data = response?.contentData;
      setTotalPages(response?.totalPages);

      if (data?.length === 0) {
        setIsSearchEmpty(true);
      } else {
        setIsSearchEmpty(false);
      }

      const items = data?.reduce((acc: any, item: any) => {
        acc[item?.id] = item;
        return acc;
      }, {});
      dispatch(setVisitors(items));
      setIsLoading(false);
      setIsSingleTime(true);
    } catch (error: any) {
      setIsLoading(false);
      setMessage(`Error: ${error?.message}`);
      setSeverity('error');
      setOpenSnackBar(true);
    }
  };
  const fetchHolidayList = async () => {
    const currentYear = new Date().getFullYear();

    try {
      const response = await fetchRuntimeFormData(HOLIDAYS_FORMID);

      if (response?.success) {
        const currentYearHolidayList =
          response?.data?.content[0]?.formData?.holidayList[currentYear];

        const nextYearHolidayList =
          response?.data?.content?.[0]?.formData?.holidayList?.[
            currentYear + 1
          ];

        const upcomingHolidaysList = [
        ...currentYearHolidayList,
        ...nextYearHolidayList,
        ];

        const allHolidays = upcomingHolidaysList?.filter(
          (holiday) => new Date(holiday?.Date) > new Date()
        );

        // Select the next 3 holidays
        const next3Holidays = allHolidays?.slice(0, 3)?.map((holiday) => ({
          date: holiday?.Date,
          name: holiday?.Purpose,
        }));

        setNext3HolidaysList(next3Holidays);
      }
    } catch (e) {
      console?.error(e);
    }
  };

  useEffect(() => {
    fetchVisitors(
      `${selectedDate}${visitorStatus}${page}${rowsPerPage}${visitorStatus}${page}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, visitorStatus, page, rowsPerPage, visitorStatus, page]);

  useEffect(() => {
    if (!initialSearchTextCaller) {
      if (searchText?.length === 0) fetchVisitors(searchText + '-auto');
    } else setInitialSearchTextCaller(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    try {
      setRows(Object?.values(rowsStateData));
      if (!isSingleTime) {
        setIsEmpty(Object?.keys(rowsStateData)?.length === 0);
      }
    } catch (e) {
      console?.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsStateData]);

  const fetchRoles = async () => {
    try {
      const response: any = await getRoles();
      setIsAdmin(response?.formData?.clientRoles?.includes('ts-visitor-admin'));
    } catch (error) {
      console?.error(error);
    }
  };

  const fetchHostDetails = async () => {
    const response = await getAllUsers();
    if (response?.success && response?.data?.length > 0) {
      const emailsArray = response?.data?.map((user) => user?.userData?.emailId);
      const mobileNumbersArray = response?.data?.map((user) => {
        const mobileNumber = sanitizeMobileNumber(user?.userData?.mobileNumber);
        return mobileNumber?.length === 10 ? '91' + mobileNumber : mobileNumber;
      });
      const fullNamesArray = response?.data?.map(
        (user) => `${user?.userData?.firstName} ${user?.userData?.lastName}`
      );

      setEmails(emailsArray);
      const allPairs = createHostDetailsMap(
        emailsArray,
        fullNamesArray,
        mobileNumbersArray
      );
      setEmailMobileFullNamePairs(allPairs);
      setUserName(allPairs[getSessionStorageItem('user-email') + ''].name);
    } else {
      setEmails([]);
      setEmailMobileFullNamePairs([]);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchHostDetails();
    fetchHolidayList();
  }, []);

  const handleOnClickExport = async () => {
    setIsLoading(true);
    try {
      await downloadVisitorsExcel();
      setMessage('Data exported successfully');
      setSeverity('success');
    } catch (error: any) {
      setMessage(`Error exporting data: ${error?.message}`);
      setSeverity('error');
    }
    setIsLoading(false);
    setOpenSnackBar(true);
  };
  const handleOnClickAddVisitor = () => {
    setShowAddVisitorForm(true);
  };
  const handleSearchData = async () => {
    fetchVisitors(searchText + 'manual');
  };

  const statusOptions = [
    'Pending Approval',
    'Approved',
    'Checked In',
    'Checked Out',
    'Rejected',
    'All',
  ];

  const addVisitorCallback = async (values: any) => {
    setIsLoading(true);
    try {
      const response = await createOrUpdate(values);

      setMessage(
        `Visitor created successfully with ID: ${response?.contentData?.id}`
      );
      setSeverity('success');
      setShowAddVisitorForm(false);
      fetchVisitors(`Visitor created with ID: ${response?.contentData?.id}`);
    } catch (error: any) {
      setMessage(`Error: ${error?.message}`);
      setSeverity('error');
    }
    setIsLoading(false);
    setOpenSnackBar(true);
  };
  const showConfirmation = (title: any, message: any) => {
    setConfirmationTitle(title);
    setConfirmationMessage(message);
    setConfirmationOpen(true);
    return new Promise((resolve) => {
      setConfirmationPromise(() => resolve);
    });
  };

  const handleCheckIn = async (params: any, dateString: any) => {
    const confirmed = await showConfirmation(
      'Check-in',
      'Are you sure you want to check-in?'
    );
    if (!confirmed) return;
    const visitorId = params?.id;
    try {
      await checkinVisitor(visitorId, dateString).then((data: any) => {
        setMessage(
          `Check-in successful for visitor ${
            data?.contentData?.firstName + ' ' + data?.contentData?.lastName
          }`
        );
        setSeverity('success');
        dispatch(
          updateVisitor({
            id: visitorId,
            data: { checkin: dateString, status: StatusConstants?.CHECKED_IN },
          })
        );
      });
    } catch (error: any) {
      setMessage(`Error during check-in: ${error?.message}`);
      setSeverity('error');
    }
    setOpenSnackBar(true);
  };
  const handleCheckOut = async (params: any, dateString: any) => {
    const confirmed = await showConfirmation(
      'Check-out',
      'Are you sure you want to check-out?'
    );
    if (!confirmed) return;
    if (!params?.row?.checkin) {
      setMessage('Visitor must be checked in before checkout');
      setSeverity('error');
      setOpenSnackBar(true);
      return;
    }
    const visitorId = params?.id;

    try {
      await checkoutVisitor(visitorId, dateString).then((data: any) => {
        setMessage(
          `Check-out successful for visitor ${
            data?.contentData?.firstName + ' ' + data?.contentData?.lastName
          }`
        );
        setSeverity('success');
        dispatch(
          updateVisitor({
            id: visitorId,
            data: {
              checkout: dateString,
              status: StatusConstants?.CHECKED_OUT,
            },
          })
        );
      });
    } catch (error: any) {
      setMessage(`Error during check-out: ${error?.message}`);
      setSeverity('error');
    }
    setOpenSnackBar(true);
  };
  const handleApproveOrRejct = async (params: any, visitorStatus: any) => {
    let confirmed;
    if (visitorStatus === StatusConstants?.APPROVED) {
      confirmed = await showConfirmation(
        'Approve',
        'Are you sure you want to Approve?'
      );
    } else {
      confirmed = await showConfirmation(
        'Reject',
        'Are you sure you want to Reject?'
      );
    }
    if (!confirmed) return;

    const id = params?.id;

    try {
      await approveOrRejectVisitor(id, visitorStatus).then((data: any) => {
        setMessage(
          `${visitorStatus} successful for visitor ${
            data?.contentData?.firstName + ' ' + data?.contentData?.lastName
          }`
        );
        setSeverity('success');
        dispatch(
          updateVisitor({
            id,
            data: {
              status: visitorStatus,
            },
          })
        );
      });
    } catch (error: any) {
      setMessage(`Error during changing status:  ${error?.message}`);
      setSeverity('error');
    }
    setOpenSnackBar(true);
  };

  const handleCloseConfirmation = (result: any) => {
    setConfirmationOpen(false);
    if (confirmationPromise) {
      confirmationPromise(result);
      setConfirmationPromise(null);
    }
  };

  const onClickNextPage = () => {
    if (page === totalPages) return;
    setPage((prev: any) => prev + 1);
  };
  const onClickPreviousPage = () => {
    if (page === 1) return;
    setPage((prev: any) => prev - 1);
  };

  return (
    <Box sx={styles?.containers?.containerMain}>
      <Notification
        isOpen={openSnackBar}
        type={snackBarSeverity}
        message={message}
        handleClose={handleClose}
      />
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={handleCloseConfirmation}
        title={confirmationTitle}
        message={confirmationMessage}
      />
      <Dialog
        open={isLoading}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Loading</DialogTitle>
        <DialogContent sx={styles?.flexCenter}>
          <CircularProgress size={40} />
        </DialogContent>
      </Dialog>

      <AddVisitorForm
        isAdmin={isAdmin}
        show={showAddVisitorForm}
        setShow={setShowAddVisitorForm}
        addVisitorCallback={addVisitorCallback}
        hostEmailList={emails}
        emailMobileFullNamePairs={emailMobileFullNamePairs}
      />
      <Box sx={styles?.containers?.containerTop}>
        <Box sx={styles?.containers?.visitorHeading}>
          <VisitorHeading
            userName={userName}
            dividerWidth={'100%'}
            dividerBackground={
              'linear-gradient(90deg, rgba(202, 89, 17, 1) 0%, rgba(247, 216, 199, 1) 50%)'
            }
            containerStyle={{
              width: '100%',
            }}
          />
          <VisitorLogToolbar
            containerStyle={{
              width: '100%',
            }}
            handleSearchData={handleSearchData}
            setSearchText={setSearchText}
            searchText={searchText}
            handleOnClickAddVisitor={handleOnClickAddVisitor}
            handleOnClickExport={handleOnClickExport}
            isEmpty={isEmpty}
          />
        </Box>
        <Box sx={[styles?.containers?.upCommingEvents]}>
          <Box sx={styles?.containers?.upCommingEventsWrapper}>
            <UpcomingEvents events={next3HolidaysList} />

            <Box sx={styles?.containers?.holidaysWrapper}>
              <UpcomingHoliday />
            </Box>
          </Box>
        </Box>
      </Box>

      <VisitorLogTable
        isAdmin={isAdmin}
        handleApproveOrRejct={handleApproveOrRejct}
        handleCheckIn={handleCheckIn}
        handleCheckOut={handleCheckOut}
        rows={rows}
        handleRowsPerPageChange={(value: any) => {
          setRowsPerPage(value);
        }}
        page={page}
        rowsPerPageArray={[10, 20, 30, 50, 100]}
        rowsPerPage={rowsPerPage}
        handlePageChange={setPage}
        containerStyle={{
          width: '99%',
          marginTop: '18px',
          height: '600px',
          background: '#ffffffff',
        }}
        statusOptions={statusOptions}
        visitorStatus={visitorStatus}
        setVisitorStatus={setVisitorStatus}
        setSelectedDate={setSelectedDate}
        isLoading={isLoading}
        onClickNextPage={onClickNextPage}
        onClickPreviousPage={onClickPreviousPage}
        totalPages={totalPages}
        isEmpty={isEmpty}
        isSearchEmpty={isSearchEmpty}
      />
    </Box>
  );
};

export default Reception;
