import { TabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useReducer, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {
  LEAVE_DETAILS_FORM_ID,
  MY_COMP_OFFS_FORM_ID,
} from '../../constants/formConstants';
import { getSessionStorageItem } from '../../encrypt';
import {
  fetchRuntimeFormData,
  FilterFormDetailsWithEmployeeId,
  FilterFormDetailsWithOfficalEmail,
} from '../../services/formService';
import ApplyCompOffRequestModal from '../ModalViews/ApplyCompOffRequestModal/applyCompOffRequestModal';
import {
  leaveManagementTabList,
  leaveManagementTabTypeEnums,
  leaveManagementTeamCompOffRequestTab,
  leaveManagementTeamLeavesTab,
  leaveManagementTeamWfhRequestTab,
  commonLeadGroups,
} from '../uiElementsAccess';
import ApplyLeaveModal from './applyLeaveModal';
import HolidaysList from './holidaysList';
import './index.css';
import {
  FilterAction,
  FilterActionTypes,
  FilterState,
  initialFilterState,
  LeaveApplcationUtils,
  LeaveApplicationFilterValues,
  SessionStorageConstants,
} from './leaveManagementSystemConstants';
import MyCompOffs from './MyCompOffs/myCompOffs';
import MyLeaves from './myLeaves';
import TeamCompOffRequest from './TeamCompOffRequests/teamCompOffRequest';
import TeamLeaves from './teamLeaves';
import UpcomingHoliday from './UpcomingHoliday/upcomingHoliday';
import UserOverview from './UserOverview';
import useCustomStyles from '../../components/common/styles/CustomStylesHook';
import CONSTANTS, { HOLIDAYS_FORMID } from '../../constants/constants';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../redux/actions';
import { ERROR_MESSAGES } from '../../constants/common';

interface HolidayList {
  [year: string]: any[]; // Assuming holidayList maps years to holiday data
}

const styles = () => ({
  mainContainer: {
    width: '100%',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  tabs: {
    flexShrink: 0,
    '&.MuiTabs-root .MuiTabs-indicator': {
      backgroundColor: '#F15830',
      width: '5px',
      borderRadius: '10px 0px 0px 10px',
    },
    '&.MuiTabs-root .Mui-selected': {
      color: '#F15830',
      fontWeight: 'bold',
    },
    '& button:hover': {
      backgroundColor: '#E5E4E2',
    },
    '@media (max-width: 960px)': {
      flexDirection: 'row',
      minWidth: 'auto',
      flexShrink: 1,
      '& .MuiTabs-flexContainer': {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      '& .MuiTab-root': {
        minWidth: 'initial',
      },
    },
  },
  tab: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 0.3rem)',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.3rem)',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
    fontWeight: '600',
    color: 'black',
  },
  yearSelectContainer: {
    width: '80px',
  },
  tabPanelContainer: {
    p: 'unset',
    paddingTop: '20px',
  },
  wfhTabPanelContainer: {
    p: 'unset',
    paddingTop: '20px',
    fontWeight: '1000',
    fontSize: '1',
  },
});

const filterReducer = (
  state: FilterState,
  action: FilterAction
): FilterState => {
  switch (action?.type) {
    case FilterActionTypes?.SET_STATUS:
      return { ...state, status: action?.payload };
    default:
      return state;
  }
};

const LeaveManagementSystem = () => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [show, setShow] = useState(false);
  const [leaveChoice, setLeaveChoice] = useState('');
  const [year, setYear] = React.useState<string>(
    new Date()?.getFullYear()?.toString()
  );
  const [refresh, setRefresh] = useState(false);
  const [userRole, setUserRole] = useState<string[]>([]);
  const [isCompOffRequestModalOpen, setIsCompOffRequestModalOpen] =
    useState(false);
  const [yearsList, setYearsList] = React?.useState<any>([]);
  const handleYearChange = (event: SelectChangeEvent) => {
    setYear(event?.target?.value as string);
  };
  const dispatch = useDispatch();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const emailId: any = getSessionStorageItem('user-email');

  const [selectedTab, setSelectedTab] = React.useState(
    leaveManagementTabTypeEnums?.myLeaves
  );
  const [tabList, setTabList] = useState(leaveManagementTabList);

  const [empId, setEmpId] = useState('');

  const handleGetHolidaysList = async () => {
    try {
      const response = await fetchRuntimeFormData(HOLIDAYS_FORMID);
      if (response?.success) {
        const mapData: HolidayList =
          response?.data?.content?.[0]?.formData?.holidayList ?? {};
        setYearsList(Object.keys(mapData));
      }
    } catch (error: any) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: error?.message ?? ERROR_MESSAGES?.ERROR_FETCHING_HOLIDAYS,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };
  useEffect(() => {
    if (selectedTab === leaveManagementTabTypeEnums?.holidayList)
      handleGetHolidaysList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    const lmsTabList = [...tabList];
    const teamLeavesIndex = tabList?.findIndex(
      (tab: any) => tab?.type === leaveManagementTabTypeEnums?.teamLeaves
    );
    if (
      userRole?.some((role) => commonLeadGroups?.includes(role)) &&
      teamLeavesIndex === -1
    ) {
      lmsTabList?.push(leaveManagementTeamLeavesTab);
      lmsTabList?.push(leaveManagementTeamWfhRequestTab);
      lmsTabList?.push(leaveManagementTeamCompOffRequestTab);
    }
    setTabList([...lmsTabList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  useEffect(() => {
    if (empId) {
      fetchCompOffDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empId]);
  useEffect(() => {
    const tempUserRoleString: any = getSessionStorageItem(
      SessionStorageConstants?.KEY_USER_ROLE
    );
    if (tempUserRoleString) setUserRole([...tempUserRoleString?.split(',')]);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setCompOffDetails = (data: any = null) => {
    // const compOffDetails = getCompOffDetails(data);
    // setCompOffData(compOffDetails);
  };

  const fetchLeavesDetail = async (formId, id) => {
    const response: any = await FilterFormDetailsWithOfficalEmail(formId, id);
    if (response?.success && response?.data?.content?.length > 0) {
      setEmpId(response?.data?.content?.[0]?.formData?.employeeId);
    } else {
      setCompOffDetails();
    }
  };

  const fetchCompOffDetails = async () => {
    const response: any = await FilterFormDetailsWithEmployeeId(
      MY_COMP_OFFS_FORM_ID,
      empId
    );

    if (response?.success && response?.data?.content?.length > 0) {
      setCompOffDetails(response?.data?.content);
    } else {
      setCompOffDetails();
    }
  };

  const handleApplyLeave = (leaveType) => {
    setShow(true);
    setLeaveChoice(leaveType);
  };

  const LeaveManagementTabs = React.memo(() => {
    const [filterState, dispatchFilter] = useReducer(
      filterReducer,
      initialFilterState
    );

    const handleStatusChange = (e: any) => {
      dispatchFilter({
        type: FilterActionTypes?.SET_STATUS,
        payload: e?.target?.value,
      });
    };
    return (
      <Box className={classes?.mainContainer}>
        <TabContext value={selectedTab}>
          <Box className={classes?.tabContainer}>
            <Box>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                className={classes?.tabs}
              >
                {tabList?.map((tabListItem) => {
                  return (
                    <Tab
                      id={`lmsTab_${tabListItem?.type}`}
                      label={tabListItem?.label}
                      key={`lmsTab_${tabListItem?.type}`}
                      value={tabListItem?.type}
                      className={classes?.tab}
                    />
                  );
                })}
              </Tabs>
            </Box>
            {selectedTab === leaveManagementTabTypeEnums?.holidayList && (
              <Box>
                <div className="years-list-div">
                  <FormControl>
                    <InputLabel id="year-label">Year</InputLabel>
                    <Select
                      size="small"
                      labelId="year-label"
                      id="yearLabel"
                      value={year}
                      onChange={handleYearChange}
                      label="Year"
                      className={classes?.yearSelectContainer}
                    >
                      {yearsList?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            )}

            {selectedTab !== leaveManagementTabTypeEnums?.holidayList && (
              <FormControl>
                <InputLabel id="status-filter-label">Status</InputLabel>
                <Select
                  labelId="status-filter-label"
                  value={filterState?.status}
                  onChange={handleStatusChange}
                  label="Status"
                >
                  {Object.values(LeaveApplicationFilterValues).map(
                    ({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
          </Box>
          <TabPanel
            value={leaveManagementTabTypeEnums?.myLeaves}
            className={classes?.tabPanelContainer}
          >
            <Box>
              <MyLeaves filterState={filterState} />
            </Box>
          </TabPanel>

          <TabPanel
            value={leaveManagementTabTypeEnums?.wfhRequests}
            className={classes?.wfhTabPanelContainer}
          >
            <Box>
              <MyLeaves isWFH={true} filterState={filterState} />
            </Box>
          </TabPanel>

          <TabPanel
            value={leaveManagementTabTypeEnums?.holidayList}
            className={classes?.tabPanelContainer}
          >
            <Box>
              <HolidaysList year={year} />
            </Box>
          </TabPanel>
          <TabPanel
            value={leaveManagementTabTypeEnums?.teamLeaves}
            className={classes?.tabPanelContainer}
          >
            <Box>
              <TeamLeaves isWFH={false} filterState={filterState} />
            </Box>
          </TabPanel>
          <TabPanel
            value={leaveManagementTabTypeEnums?.teamWfhRequests}
            className={classes?.tabPanelContainer}
          >
            <Box>
              <TeamLeaves isWFH filterState={filterState} />
            </Box>
          </TabPanel>
          <TabPanel
            value={leaveManagementTabTypeEnums?.myCompOffs}
            className={classes?.tabPanelContainer}
          >
            <Box>
              <MyCompOffs empId={empId} filterState={filterState} />
            </Box>
          </TabPanel>
          <TabPanel
            value={leaveManagementTabTypeEnums?.teamCompOffRequest}
            className={classes?.tabPanelContainer}
          >
            <Box>
              <TeamCompOffRequest filterState={filterState} />
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    );
  });
  LeaveManagementTabs.displayName =
    LeaveApplcationUtils?.LEAVE_MANAGEMENT_TABS_DISPLAY_NAME;

  useEffect(() => {
    fetchLeavesDetail(LEAVE_DETAILS_FORM_ID, emailId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  const handleApplyCompOffRequest = () => {
    setIsCompOffRequestModalOpen(true);
  };

  const closeCompOffRequestModal = () => {
    setIsCompOffRequestModalOpen(false);
  };

  useEffect(() => {
    if (!show && !isCompOffRequestModalOpen) {
      setRefresh((prev) => !prev);
    }
  }, [isCompOffRequestModalOpen, show]);

  return (
    <>
      {show ? (
        <ApplyLeaveModal
          show={show}
          setShow={setShow}
          leaveChoice={leaveChoice}
        />
      ) : null}

      {isCompOffRequestModalOpen ? (
        <ApplyCompOffRequestModal
          isModalOpen={isCompOffRequestModalOpen}
          closeModal={closeCompOffRequestModal}
          empId={empId}
        />
      ) : null}

      <div className="myDivLms">
        <div className="LeaveManagementWithoutProfileCard">
          <Grid container spacing={2} alignItems={'stretch'}>
            <Grid item xs={12} md={12} lg={9.5}>
              <UserOverview
                handleApplyLeave={handleApplyLeave}
                handleApplyCompOffRequest={handleApplyCompOffRequest}
                updated={refresh}
                userEmail={emailId}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={2.5}>
              <UpcomingHoliday />
            </Grid>
          </Grid>

          <div className="lmsLeaveTabsCards">
            <LeaveManagementTabs />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementSystem;
