import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useCustomStyles from '../../../components/common/styles/CustomStylesHook';
import CONSTANTS from '../../../constants/constants';
import { pushNotification, setLoader } from '../../../redux/actions';
import { updateReportingManagers } from '../../../services/formService';
import { ReassignmentPopupConstants } from '../ModalViewsConstants';

const styles = () => {
  return {
    dialogContent: {
      maxHeight: '50rem !important',
      overflowY: 'auto !important',
    },
    typography: {
      fontSize: '1rem !important',
    },
    reporteeBox: {
      alignItems: 'center',
      display: 'flex !important',
      flexDirection: 'row !important',
      gap: '2px !important',
      '@media (max-width:899.95px)': {
        flexDirection: 'column !important',
        alignItems: 'start',
      },
    },
    leftSection: {
      display: 'flex !important',
      justifyContent: 'flex-start !important',
      flexDirection: 'row !important',
      alignItems: 'center !important',
      gap: '2px !important',
      minWidth: '25rem !important',
    },
    reporteeInfo: {
      display: 'flex !important',
      flexDirection: 'column !important',
      justifyContent: 'flex-start !important',
      alignItems: 'stretch !important',
    },
    textTypography: {
      fontSize: '1rem !important',
      whiteSpace: 'nowrap !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      maxWidth: '20rem !important',
    },
    textFieldBox: {
      display: 'flex !important',
      flexDirection: 'column !important',
      gap: '1px !important',
      width: '100% !important',
    },
    dividerTop: {
      margin: '0 0 16px 0 !important',
    },
    dividerCommon: {
      margin: '16px 0 !important',
    },
    button: {
      textTransform: 'capitalize !important',
    },
    boxFormControlLabelContainer: {
      display: 'flex !important',
      justifyContent: 'start !important',
      alignItems: 'center !important',
      gap: '4px !important',
    },
    formControlLabelSelectAll: {
      '& .MuiTypography-root': {
        fontSize: '1rem !important',
        lineHeight: '1 !important',
      },
      marginTop: '16px !important',
    },
    buttonAssignToSelected: {
      textTransform: 'capitalize',
      fontSize: '0.8rem',
      margin: '16px 0px',
      background: '#f15830',
      '&:hover': {
        background: '#df4923',
      },
    },
    hightLightBackground: {
      background: '#3de08146',
    },
    formControlReportee: {
      '& .MuiTypography-root': {
        fontSize: '1rem !important',
        lineHeight: 1,
      },
      height: '100%',
      margin: '0px',
      '& .MuiFormControlLabel-root': {
        marginLeft: '100px !important',
      },
    },
    typographySerialNumber: {
      fontSize: '1rem !important',
      margin: '8px',
    },
    textFieldAutoComplete: {
      '& .MuiOutlinedInput-root': {
        height: '3rem !important',
      },
      '& .MuiInputLabel-root': {
        lineHeight: 2,
      },
    },
    buttonCancel: {
      textTransform: 'capitalize',
      background: '#e03232',
      color: 'white',
      '&:hover': {
        background: '#bd3321',
      },
    },
    buttonAssign: {
      textTransform: 'capitalize',
      background: '#025e5e',
      color: 'white',
      '&:hover': {
        background: '#044949',
      },
    },
    leftSectionContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    boxPendingRequests: {
      display: 'flex',
      width: '100%',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '0.8rem',
      border: '1px solid #0000007e',
      background: '#f11e1e33',
      borderRadius: '4px',
    },
    typographyPendingRequests: {
      fontSize: '1rem',
    },
  };
};

export interface UserData {
  firstName: string;
  lastName: string;
  officialEmail: string;
  reportees: any;
}

interface Props {
  onClose: () => void;
  open: boolean;
  reportees: Record<string, any>;
  reporteesLeaves: Record<string, any>;
  userData: any;
  emailsArray: string[];
  callBack: any;
}

const ReassignmentPopup: React.FC<Props> = ({
  reportees,
  userData,
  onClose,
  open,
  emailsArray,
  callBack,
  reporteesLeaves,
}) => {
  const dispatch = useDispatch();
  const [managerEmails, setManagerEmails] = useState<{ [key: string]: string }>(
    {}
  );
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedReportees, setSelectedReportees] = useState<
    Record<string, boolean>
  >({});
  const [openAssignPopup, setOpenAssignPopup] = useState(false);
  const [selectedManagerEmail, setSelectedManagerEmail] = useState('');

  const resetLocalState = () => {
    setSelectAll(false);
    setSelectedReportees({});
    setSelectedManagerEmail('');
  };
  const resetGlobalState = () => {
    resetLocalState();
    setOpenAssignPopup(false);
    setManagerEmails({});
  };
  const handleManagerEmailChange = (email: string, newManagerEmail: string) => {
    setManagerEmails((prev) => ({
      ...prev,
      [email]: newManagerEmail,
    }));
  };

  const setManagerEmailsForAllReportees = async () => {
    Object.entries(selectedReportees)?.forEach(([email, value]) => {
      if (email && value && reporteesLeaves[email] === 0) {
        handleManagerEmailChange(email, selectedManagerEmail);
      }
    });
    setOpenAssignPopup(false);
    setSelectAll(false);
    setSelectedReportees({});
  };
  const handleSave = () => {
    const updatedReportees = Object.entries(managerEmails)?.map(
      ([email, newManagerEmail]) => ({
        email,
        newManagerEmail,
        userData: reportees?.[email],
      })
    );
    if (
      updatedReportees?.length !== Object.keys(reportees)?.length ||
      updatedReportees?.some(
        ({ email, newManagerEmail }) => !email || !newManagerEmail
      )
    ) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: ReassignmentPopupConstants?.ASSIGN_ALL_REPORTEES,
          type: CONSTANTS?.ERROR,
        })
      );
    } else {
      updateForms(updatedReportees);
    }
  };

  const updateForms = async (values: any) => {
    dispatch(setLoader(true));
    const response = await updateReportingManagers(values);
    dispatch(setLoader(false));
    callBack(response?.success);
  };

  const handleSelectAllChange = (event) => {
    const checked = event?.target?.checked;
    setSelectAll(checked);

    const updatedSelectedReportees = {};
    for (const reportee of Object.values(reportees)) {
      const emailId = reportee?.userData?.emailId;
      if (emailId && reporteesLeaves[emailId] === 0) {
        updatedSelectedReportees[emailId] = checked;
      }
    }
    setSelectedReportees(updatedSelectedReportees);
  };

  const handleCheckboxChange = (emailId, event) => {
    const checked = event?.target?.checked;
    setSelectedReportees((prevSelected) => ({
      ...prevSelected,
      [emailId]: checked,
    }));
  };

  const handleDialogClose = () => {
    resetLocalState();
    setOpenAssignPopup(false);
  };

  const handleAssignButtonClick = () => {
    if (
      Object.keys(selectedReportees)?.length !== 0 &&
      Object.values(selectedReportees)?.some((value) => value === true)
    ) {
      setOpenAssignPopup(true);
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: ReassignmentPopupConstants?.ASSIGN_REPORTEES,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };
  const handleMainDailogClose = () => {
    resetGlobalState();
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleMainDailogClose}
        maxWidth="lg"
        fullWidth
        onClick={(e: any) => {
          e?.stopPropagation();
        }}
      >
        <DialogTitle>Reassign Reportees Before Deactivation</DialogTitle>
        <DialogContent dividers className={classes?.dialogContent}>
          <Typography className={classes?.typography}>
            <strong>
              {userData?.firstName} {userData?.lastName}
            </strong>{' '}
            has reportees assigned under their management. Before deactivation,
            please assign new reporting managers for these reportees to maintain
            project oversight.
          </Typography>
          <Box className={classes?.boxFormControlLabelContainer}>
            <Box className={classes?.boxFormControlLabelContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    color="primary"
                  />
                }
                label={ReassignmentPopupConstants?.LABEL_SELECT_ALL}
                className={classes?.formControlLabelSelectAll}
              />
              {Object.keys(selectedReportees).length !== 0 &&
                Object.values(selectedReportees)?.some(
                  (value) => value === true
                ) && (
                  <Button
                    variant="contained"
                    onClick={handleAssignButtonClick}
                    className={classes?.buttonAssignToSelected}
                  >
                    Assign to selected
                  </Button>
                )}
            </Box>
          </Box>

          <Divider className={classes?.dividerTop} />

          <Box>
            {Object.values(reportees)?.map((reportee, index) => (
              <Box key={index} mb={2}>
                <Box className={classes?.reporteeBox}>
                  <Box className={classes?.leftSectionContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (selectedReportees?.[reportee?.userData?.emailId] &&
                              reporteesLeaves[reportee?.userData?.emailId] ===
                                0) ||
                            false
                          }
                          onChange={(event) =>
                            handleCheckboxChange(
                              reportee?.userData?.emailId,
                              event
                            )
                          }
                          color="primary"
                          className={
                            managerEmails?.[reportee?.userData?.emailId]
                              ? classes?.hightLightBackground
                              : ''
                          }
                        />
                      }
                      label=""
                      className={classes?.formControlReportee}
                      disabled={
                        reporteesLeaves[reportee?.userData?.emailId] !== 0
                      }
                    />

                    <Typography className={classes?.typographySerialNumber}>
                      {index + 1}.{' '}
                    </Typography>
                    <Box className={classes?.leftSection}>
                      <Box className={classes?.reporteeInfo}>
                        <Typography className={classes?.textTypography}>
                          <strong>Name: </strong>
                          {reportee?.userData?.firstName}{' '}
                          {reportee?.userData?.lastName}
                        </Typography>
                        <Typography className={classes?.textTypography}>
                          <strong>Email: </strong>
                          {reportee?.userData?.emailId}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {reporteesLeaves[reportee?.userData?.emailId] === 0 ? (
                    <Box
                      className={`${classes?.textFieldBox} ${
                        managerEmails?.[reportee?.userData?.emailId]
                          ? classes?.hightLightBackground
                          : ''
                      }`}
                    >
                      <Autocomplete
                        id="new-manager-email"
                        fullWidth
                        options={emailsArray || []}
                        getOptionLabel={(option) => option}
                        value={
                          managerEmails?.[reportee?.userData?.emailId] || ''
                        }
                        onChange={(_event, value) => {
                          handleManagerEmailChange(
                            reportee?.userData?.emailId,
                            value || ''
                          );
                        }}
                        onClick={(e: any) => {
                          e?.stopPropagation();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              ReassignmentPopupConstants?.LABEL_NEW_REPORTING_MANAGER
                            }
                            size="small"
                            className={classes?.textFieldAutoComplete}
                          />
                        )}
                      />
                    </Box>
                  ) : (
                    <Box className={classes?.boxPendingRequests}>
                      <Typography
                        className={classes?.typographyPendingRequests}
                      >
                        {reporteesLeaves[reportee?.userData?.emailId]} Pending
                        Leave/WFH Request(s)
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Divider className={classes?.dividerCommon} />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} className={classes?.button}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            className={classes?.button}
          >
            Save & Start Deactivation
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAssignPopup}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        onClick={(e: any) => {
          e?.stopPropagation();
        }}
      >
        <DialogTitle>Assign Manager</DialogTitle>
        <DialogContent>
          <Box className={classes?.textFieldBox}>
            <Autocomplete
              id="new-manager-email"
              fullWidth
              options={emailsArray || []}
              getOptionLabel={(option) => option}
              value={selectedManagerEmail}
              onChange={async (_event, value) =>
                await setSelectedManagerEmail(value || '')
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={ReassignmentPopupConstants?.LABEL_NEW_REPORTING_MANAGER}
                  size="small"
                  className={classes?.textFieldAutoComplete}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {/* Close and Assign Buttons */}
          <Button onClick={handleDialogClose} className={classes?.buttonCancel}>
            Cancel
          </Button>
          <Button
            onClick={setManagerEmailsForAllReportees}
            className={classes?.buttonAssign}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReassignmentPopup;
