import React, { useEffect } from 'react';
import { Spinner } from '../../common/Spinner';
import Notification from '../../common/notification';
import { useDispatch, useSelector } from 'react-redux';
import {
  pushNotification,
  setRolesData,
} from '../../../redux/actions';
import SideMenu from '../../../screen/SideMenu';
import { getRoles } from '../../../services/roleService';
import { setSessionStorageItem } from '../../../encrypt';

const styles = {
  container: {
    overflow: 'hidden',
  },
};

const Wrapper = (props) => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state: any) => ({
    notification: state.notification,
  }));

  const closeNotification = () => {
    dispatch(
      pushNotification({
        ...notification,
        isOpen: false,
      })
    );
  };

  useEffect(() => {
    handleRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRole = async () => {
    const response = await getRoles();

    const filteredArray = response?.formData?.clientRoles.filter((str) =>
      str.startsWith('emp')
    );

    // let effectiveRole = 'emp-hrAdmin';
    // if (filteredArray?.includes('emp-hrView')) {
    //   effectiveRole = 'emp-hrView'
    // } else if (filteredArray?.includes('emp-hrView')) {
    // }
    dispatch(setRolesData(filteredArray));
    const concatenatedString = filteredArray.join(',');
    setSessionStorageItem('User-Role', concatenatedString);
  };
  const RenderSideMenu = Boolean(
    window.location.pathname === '/emp360/empDashboard'
  );

  // const fetchLocation = () => {
  //   navigator.geolocation.getCurrentPosition((pos) => {
  //     const { latitude, longitude } = pos.coords;
  //     const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
  //     fetch(url)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         dispatch(updateLocation(data.address));
  //         try {
  //           postLiveLocation(getCraftedAddress(data.address));
  //         } catch (e) {}
  //       });
  //   });
  // };

  // useEffect(() => {
  //   fetchLocation();
  //   const intervalId = setInterval(fetchLocation, 300000);

  //   return () => clearInterval(intervalId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  return window.location.href.indexOf('signup') <= -1 ? (
    <div style={styles.container}>
      {/* <Header /> */}
      {!RenderSideMenu && <SideMenu />}
      <div
        // className="main-content-wrapper"
        className={RenderSideMenu ? 'with-side-menu' : 'main-content-wrapper'}
        style={RenderSideMenu ? { backgroundColor: '#e3e7ef' } : {}}
      >
        {props.children}
      </div>
      <Spinner />
      <Notification
        isOpen={notification.isOpen}
        type={notification.type}
        message={notification.message}
        handleClose={closeNotification}
      />
      {/* <Footer /> */}
    </div>
  ) : (
    <div>
      <Notification
        isOpen={notification.isOpen}
        type={notification.type}
        message={notification.message}
        handleClose={closeNotification}
      />
      {props.children}
      <Spinner />
    </div>
  );
};

export default Wrapper;
