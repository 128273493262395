import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useCustomStyles from './styles/CustomStylesHook';
import { ExpenseTypePopupConstants } from './constants';
import { fetchExpenseTypesService } from '../../services/AdminService';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../redux/actions';
import CONSTANTS from '../../constants/constants';

// Define types for the expense record and the props.

const styles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem !important',
    margin: '1rem',
  },
  textFieldExpenseAccountId: {
    minWidth: 300,
  },
  buttonCancel: {
    color: 'white',
    background: '#cf9c3e',
    border: '2rem',
  },
  buttonSave: {
    color: 'white',
    background: '#15854d',
    border: '2rem',
  },
});
interface ExpenseType {
  name: string;
  expenseAccountId: number;
  liabilityAccountId: number;
  reference: string;
}

interface ExpenseTypePopupProps {
  open: boolean;
  onClose: () => void;
  record: ExpenseType | null;
  onSave: (updatedRecord: ExpenseType) => void;
}

const ExpenseTypePopup: React.FC<ExpenseTypePopupProps> = ({
  open,
  onClose,
  record,
  onSave,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useCustomStyles(styles, theme);
  const [getLoading, setGetLoading] = useState(false);
  const [editableRecord, setEditableRecord] = useState<ExpenseType>({
    name: '',
    expenseAccountId: 0,
    liabilityAccountId: 0,
    reference: '',
  });
  const [accountRecords, setAccountTypes] = useState<any[]>([]);

  const fetchExpenseTypes = async () => {
    setGetLoading(true);

    const response = await fetchExpenseTypesService();

    if (response?.success) {
      setAccountTypes(response?.data || []);
      setGetLoading(false);
    } else {
      setAccountTypes([]);
      setGetLoading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  useEffect(() => {
    fetchExpenseTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (record) {
      setEditableRecord(record);
    } else {
      setEditableRecord({
        name: '',
        expenseAccountId: 0,
        liabilityAccountId: 0,
        reference: '',
      });
    }
  }, [record, open]);

  const handleSave = () => {
    if (editableRecord) {
      onSave(editableRecord);
      onClose();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editableRecord) {
      setEditableRecord({
        ...editableRecord,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {record
          ? ExpenseTypePopupConstants?.EDIT_EXPENSE_TYPE
          : ExpenseTypePopupConstants?.ADD_NEW_EXPENSE_TYPE}
      </DialogTitle>
      <DialogContent>
        <Box className={classes?.mainContainer}>
          {/* Display or edit the name */}
          <TextField
            label="Name"
            name="name"
            value={editableRecord?.name || ''}
            disabled={Boolean(record?.name)}
            onChange={handleChange}
            fullWidth
          />

          <Autocomplete
            id="expenseAccountId"
            options={getLoading ? [] : accountRecords}
            loading={getLoading}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Expense Account"
                name="expenseAccountId"
                size="medium"
                fullWidth
              />
            )}
            onChange={(_event, value) => {
              if (editableRecord) {
                setEditableRecord({
                  ...editableRecord,
                  expenseAccountId: value?.id || '',
                });
              }
            }}
            isOptionEqualToValue={(option, value) => option?.id === value}
            value={
              accountRecords?.find(
                (record) => record?.id === editableRecord?.expenseAccountId
              ) || null
            }
          />

          <Autocomplete
            id="liabilityAccountId"
            options={getLoading ? [] : accountRecords}
            loading={getLoading}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Liability Account ID"
                name="liabilityAccountId"
                size="medium"
                fullWidth
              />
            )}
            onChange={(_event, value) => {
              if (editableRecord) {
                setEditableRecord({
                  ...editableRecord,
                  liabilityAccountId: value?.id || '',
                });
              }
            }}
            isOptionEqualToValue={(option, value) => option?.id === value}
            value={
              accountRecords?.find(
                (record) => record?.id === editableRecord?.liabilityAccountId
              ) || null
            }
          />

          {record && (
            <TextField
              label="Reference"
              name="reference"
              value={editableRecord?.reference || ''}
              disabled
              onChange={handleChange}
              fullWidth
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {/* Show delete button only when editing an existing record */}

        <Button
          onClick={onClose}
          variant="contained"
          className={classes?.buttonCancel}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          className={classes?.buttonSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpenseTypePopup;
