export const modalAdminGroup = ['Admin'];
export const modalLeadGroup = [
  'Software Architect',
  'Technical Lead',
  'emp-lead',
];
export const modalFinanceGroup = ['emp-finance'];
export const modalNcgGroup = ['emp-ncg'];
export const modalHrAdminGroup = [
  'Lead HR',
  'Associate HR',
  'HR Admin',
  'HRAdmin',
  'emp-hrAdmin',
];

export const createEmployeeBtnGroup = [
  'emp-hrAdmin',
  'HRAdmin',
  'HR Admin',
  'Admin',
  'Lead HR',
  'Associate HR',
];
export const ROLE_EMP_HR_VIEW = 'emp-hrView';

export const tabRoleMaps = {
  'emp-hrAdmin': 'emp-hrAdmin',
  'emp-hrView': 'emp-hrView',
  'emp-lead': 'emp-lead',
  'emp-ncg': 'emp-ncg',
  'emp-finance': 'emp-finance',
};

export const commonLeadGroups = [
  'emp-lead',
  'Software Architect',
  'Technical Lead',
  'QA Manager',
  'Technical Architect',
];



export const addLeaveGroup = ['emp-hrAdmin'];

export const addCompOffGroup = ['emp-hrAdmin', 'emp-lead'];

const leaveTypeEnums = {
  GENERAL: 'general',
  PRIVILEGE: 'privilege',
  COMP_OFF: 'compOff',
  WFH: 'WFH',
};

const leaveTypeNames = {
  general: 'General',
  privilege: 'Privilege',
  compOff: 'Comp Off',
  WFH: 'Work From Home',
};

const leaveTypes = [
  {
    value: leaveTypeEnums.GENERAL,
    title: 'General',
  },
  {
    value: leaveTypeEnums.PRIVILEGE,
    title: 'Privilege',
  },
];

const leaveManagementTabTypeEnums = {
  myLeaves: 'MY_LEAVES',
  holidayList: 'HOLIDAY_LIST',
  teamLeaves: 'TEAM_LEAVES',
  myCompOffs: 'MY_COMP_OFFS',
  teamCompOffRequest: 'TEAM_COMP_OFF_REQUESTS',
  teamWfhRequests: 'TEAM_WFH_REQUESTS',
  wfhRequests: 'WFH_REQUESTS',
};

const leaveManagementTabTypeNames = {
  HOLIDAY_LIST: 'Holiday List',
  TEAM_LEAVES: 'Team Leaves',
  MY_LEAVES: 'My Leaves',
  MY_COMP_OFFS: 'Comp-Off Requests',
  TEAM_COMP_OFF_REQUESTS: 'Team Comp Off Requests',
  TEAM_WFH_REQUESTS: 'Team WFH Requests',
  WFH_REQUESTS: 'WFH Requests',
};

const expenseTrackerTabTypeEnums = {
  myRequests: 'MY_REQUESTS',
  teamRequests: 'TEAM_REQUESTS',
  allRequests: 'ALL_REQUESTS',
};

const expenseTrackerTabTypeNames = {
  MY_REQUESTS: 'My Requests',
  TEAM_REQUESTS: 'Team Requests',
  ALL_REQUESTS: 'All Requests',
};

const expenseTrackerTabList = [
  {
    label: expenseTrackerTabTypeNames.MY_REQUESTS,
    type: expenseTrackerTabTypeEnums.myRequests,
  },
];

const expenseTrackerTeamRequestsTab = {
  label: expenseTrackerTabTypeNames.TEAM_REQUESTS,
  type: expenseTrackerTabTypeEnums.teamRequests,
};

const expenseTrackerAllRequestsTab = {
  label: expenseTrackerTabTypeNames.ALL_REQUESTS,
  type: expenseTrackerTabTypeEnums.allRequests,
};

const leaveManagementTabList = [
  {
    label: leaveManagementTabTypeNames.MY_LEAVES,
    type: leaveManagementTabTypeEnums.myLeaves,
  },
  {
    label: leaveManagementTabTypeNames.WFH_REQUESTS,
    type: leaveManagementTabTypeEnums.wfhRequests,
  },
  {
    label: leaveManagementTabTypeNames.HOLIDAY_LIST,
    type: leaveManagementTabTypeEnums.holidayList,
  },
  {
    label: leaveManagementTabTypeNames.MY_COMP_OFFS,
    type: leaveManagementTabTypeEnums.myCompOffs,
  },
];

const leaveManagementTeamLeavesTab = {
  label: leaveManagementTabTypeNames.TEAM_LEAVES,
  type: leaveManagementTabTypeEnums.teamLeaves,
};

const leaveManagementTeamCompOffRequestTab = {
  label: leaveManagementTabTypeNames.TEAM_COMP_OFF_REQUESTS,
  type: leaveManagementTabTypeEnums.teamCompOffRequest,
};

const leaveManagementTeamWfhRequestTab = {
  label: leaveManagementTabTypeNames.TEAM_WFH_REQUESTS,
  type: leaveManagementTabTypeEnums.teamWfhRequests,
};

export {
  leaveTypes,
  leaveTypeEnums,
  leaveTypeNames,
  leaveManagementTabTypeEnums,
  leaveManagementTabTypeNames,
  leaveManagementTabList,
  leaveManagementTeamLeavesTab,
  leaveManagementTeamCompOffRequestTab,
  leaveManagementTeamWfhRequestTab,
  expenseTrackerTabTypeEnums,
  expenseTrackerTabTypeNames,
  expenseTrackerTabList,
  expenseTrackerTeamRequestsTab,
  expenseTrackerAllRequestsTab,
};
