import COMMON from '../constants/common';
import {
  GETUSERS_ENDPOINT,
  USER_PREFERENCES_PATH,
} from '../constants/endpoints';
import { getSessionStorageItem } from '../encrypt';
import { request, ResponseProps } from '../services/request';

export const getUserPreferences = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem(COMMON.GATE_WAY_URL)}${USER_PREFERENCES_PATH}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const getAllUsers = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${getSessionStorageItem(COMMON.GATE_WAY_URL)}${GETUSERS_ENDPOINT}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};
