import COMMON from '../constants/common';
import {
  ATTENDANCE_ENDPOINT,
  ATTENDANCE_SETTINGS_ENDPOINT,
  COMMON_BACKEND_ENDPOINT,
  EMPLOYEE_SETTINGS_ENDPOINT as APPLICATION_SETTINGS_ENDPOINT,
  SETTINGS_ENDPOINT,
  EXPENSE_END_POINT,
  EXPENSE_TYPES_ENDPOINT,
} from '../constants/endpoints';
import { getSessionStorageItem } from '../encrypt';
import { request, ResponseProps } from './request';

interface AttendanceReportResponseProps {
  success: boolean;
  data?: Blob;
  message?: string;
}

export const fetchApplicationSettings = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: any = await request?.get(
    `${getSessionStorageItem(
      COMMON?.GATE_WAY_URL
    )}${COMMON_BACKEND_ENDPOINT}/${SETTINGS_ENDPOINT}/${APPLICATION_SETTINGS_ENDPOINT}`
  );

  return r;
};

export const postEmployeeSettings = async (
  payload: any
): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request?.post(
    `${getSessionStorageItem(
      COMMON?.GATE_WAY_URL
    )}${COMMON_BACKEND_ENDPOINT}/${SETTINGS_ENDPOINT}/${APPLICATION_SETTINGS_ENDPOINT}`,
    payload
  )) as ResponseProps;
  if (r?.success) {
    const form = r?.data as any;
    return { success: r?.success, data: form, message: r?.message };
  }

  return { success: r?.success ? r?.success : false, message: r?.message };
};

export const fetchAttendanceSettings = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request?.get(
    `${getSessionStorageItem(
      COMMON?.GATE_WAY_URL
    )}${COMMON_BACKEND_ENDPOINT}/${SETTINGS_ENDPOINT}/${ATTENDANCE_SETTINGS_ENDPOINT}`
  )) as ResponseProps;
  if (r?.success) {
    const form = r?.data as any;
    return { success: r?.success, data: form, message: r?.message };
  }

  return { success: r?.success ? r?.success : false, message: r?.message };
};

export const postAttendanceSettings = async (
  payload: any
): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request?.post(
    `${getSessionStorageItem(
      COMMON?.GATE_WAY_URL
    )}${COMMON_BACKEND_ENDPOINT}/${SETTINGS_ENDPOINT}/${ATTENDANCE_SETTINGS_ENDPOINT}`,
    payload
  )) as ResponseProps;
  if (r?.success) {
    const form = r?.data as any;
    return { success: r?.success, data: form, message: r?.message };
  }

  return { success: r?.success ? r?.success : false, message: r?.message };
};

export const leaveReportRequest = async (
  formDate: string,
  toDate: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request?.get(
    `${getSessionStorageItem(
      COMMON?.GATE_WAY_URL
    )}${COMMON_BACKEND_ENDPOINT}/report?fromDate=${formDate}&toDate=${toDate}`
  )) as ResponseProps;
  if (r?.success) {
    const form = r?.data as any;
    return { success: r?.success, data: form, message: r?.message };
  }

  return { success: false, message: 'Some error occured' };
};

export const attendanceReportRequest = async (
  payload: any,
  reportType: string
): Promise<AttendanceReportResponseProps> => {
  const r: ResponseProps = (await request?.post(
    `${getSessionStorageItem(
      COMMON?.GATE_WAY_URL
    )}${COMMON_BACKEND_ENDPOINT}/${ATTENDANCE_ENDPOINT}/${reportType}-report`,
    payload
  )) as ResponseProps;

  if (r?.success) {
    const form = r?.data as any;
    return { success: r?.success, data: form, message: r?.message };
  }

  return { success: r?.success ? r?.success : false, message: r?.message };
};

export const leaveHistoryRequest = async (
  formDate: string,
  toDate: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request?.get(
    `${getSessionStorageItem(
      COMMON?.GATE_WAY_URL
    )}${COMMON_BACKEND_ENDPOINT}/leave-history/audit?fromDate=${formDate}&&toDate=${toDate}`
  )) as ResponseProps;

  if (r?.success) {
    const form = r?.data as any;
    return { success: r?.success, data: form, message: r?.message };
  }

  return { success: false, message: 'Some error occured' };
};

export const fetchExpenseTypesService = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: any = await request?.get(
    `${getSessionStorageItem(
      COMMON?.GATE_WAY_URL
    )}${COMMON_BACKEND_ENDPOINT}/${EXPENSE_END_POINT}/${EXPENSE_TYPES_ENDPOINT}`
  );

  return r;
};
