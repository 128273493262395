import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import CONSTANTS from '../../../constants/constants';
import { getSessionStorageItem } from '../../../encrypt';
import { pushNotification } from '../../../redux/actions';
import {
  fetchAttendanceSettings,
  postAttendanceSettings,
} from '../../../services/AdminService';
import { getAllUsers } from '../../../services/userService';
import {
  AttendanceDataValidationSchema,
  AttendanceTimeFormats,
  attendanceWeekdays,
  FormValues,
  monitorFrequencyOptions,
  ToolTipMessages,
} from './adminConstants';
import useCustomStyles from '../../../components/common/styles/CustomStylesHook';

const styles = () => ({
  boxMainContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  columnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'start',
  },
  timePicker: { width: '97%' },
  numericField: { width: '32%' },
  errorText: {
    fontSize: '12px !important',
  },
  toggleHeaderText: {
    fontSize: '16px !important',
  },
  saveButton: {
    backgroundColor: '#f15830',
    color: 'white',
  },
  color: '#ffffff',
  override: {
    display: 'block',
    margin: '0 auto',
    borderColor: '#4B6D8F',
    width: '100%',
  },
  thumbStyleBlack: {
    '& .MuiSwitch-thumb': {
      backgroundColor: '#333',
    },
  },
  thumbStyleNull: {
    '& .MuiSwitch-thumb': {
      backgroundColor: null,
    },
  },
  inputAdornment: {
    cursor: 'pointer',
  },
});

const AdminSettings = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const emailId: any = getSessionStorageItem(CONSTANTS?.USER_EMAIL);

  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [usersData, setUsersData] = useState<any>([]);

  const minimumDate = new Date();
  minimumDate?.setDate(minimumDate?.getDate() - 14);

  const formAdmin = useFormik<FormValues>({
    initialValues: {
      enableAutoReports: false,
      enableManualReports: false,
      enableMonitoring: false,
      enableLopMails: false,
      reportsTime: '',
      monitoringStartTime: '',
      monitoringEndTime: '',
      monitoringFrequency: '',
      reportingName: '',
      employeeIdCode: '',
      reportingEmails: [],
      checkInTime: '',
      checkOutTime: '',
      acceptableDelayInMinutes: '',
      markLateCheckin: false,
      criticalHours: 0,
      warningHours: 0,
      hoursPerDay: 0,
      weekends: [],
      restartSchedulers: false,
    },
    validationSchema: AttendanceDataValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any) => {
    postFormValues(values);
  };

  const postFormValues = async (values: any) => {
    setPostLoading(true);
    const response = await postAttendanceSettings(values);

    if (response?.success) {
      setPostLoading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.SUCCESS,
        })
      );
    } else {
      setPostLoading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  const handleGetUserData = async () => {
    const response = await getAllUsers();
    if (response?.success && response?.data?.length > 0) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  useEffect(() => {
    handleGetUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  useEffect(() => {
    prefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prefillData = async () => {
    setGetLoading(true);

    const response = await fetchAttendanceSettings();

    if (response?.success) {
      const mapData: any = response?.data;

      const formValues = {
        enableAutoReports: mapData?.enableAutoReports || false,
        enableManualReports: mapData?.enableManualReports || false,
        enableMonitoring: mapData?.enableMonitoring || false,
        enableLopMails: mapData?.enableLopMails || false,
        reportsTime: mapData?.reportsTime || '',
        monitoringStartTime: mapData?.monitoringStartTime || '',
        monitoringEndTime: mapData?.monitoringEndTime || '',
        monitoringFrequency: mapData?.monitoringFrequency || '',
        reportingName: mapData?.reportingName || '',
        employeeIdCode: mapData?.employeeIdCode || '',
        reportingEmails: mapData?.reportingEmails || [],
        checkInTime: mapData?.checkInTime || '',
        checkOutTime: mapData?.checkOutTime || '',
        acceptableDelayInMinutes:
          mapData?.acceptableDelayInMinutes?.toString() || '',
        markLateCheckin: mapData?.markLateCheckin || false,
        criticalHours: mapData?.criticalHours || 0,
        warningHours: mapData?.warningHours || 0,
        hoursPerDay: mapData?.hoursPerDay || 0,
        weekends: mapData?.weekends || [],
        restartSchedulers: mapData?.restartSchedulers || false,
      };

      formAdmin?.setValues(formValues);
      setGetLoading(false);
    } else {
      formAdmin?.setValues(formAdmin?.initialValues);
      setGetLoading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS?.ERROR,
        })
      );
    }
  };

  const handleOptionSelect = (_event, value) => {
    const selectedValuesArray = value?.map((option) => {
      const mapData = option?.userData;
      return mapData?.emailId;
    });

    formAdmin?.setFieldValue('reportingEmails', selectedValuesArray);
  };

  return (
    <>
      <Box className={classes?.boxMainContainer}>
        {getLoading ? (
          <ClipLoader
            color={classes?.color}
            loading={getLoading}
            className={classes?.override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <form onSubmit={formAdmin?.handleSubmit}>
            <Grid container gap={3}>
              <Grid container gap={2} padding={1}>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  className={classes?.toggleContainer}
                >
                  <Typography className={classes?.toggleHeaderText}>
                    Enable Auto Reports
                  </Typography>
                  <Switch
                    checked={formAdmin?.values?.enableAutoReports}
                    onChange={(event) =>
                      formAdmin?.setFieldValue(
                        'enableAutoReports',
                        event?.target?.checked
                      )
                    }
                    className={
                      !formAdmin?.values?.enableAutoReports
                        ? classes?.thumbStyleBlack
                        : classes?.thumbStyleNull
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  className={classes?.toggleContainer}
                >
                  <Typography className={classes?.toggleHeaderText}>
                    Enable Manual Reports
                  </Typography>
                  <Switch
                    checked={formAdmin?.values?.enableManualReports}
                    onChange={(event) =>
                      formAdmin?.setFieldValue(
                        'enableManualReports',
                        event?.target?.checked
                      )
                    }
                    className={
                      !formAdmin?.values?.enableManualReports
                        ? classes?.thumbStyleBlack
                        : classes?.thumbStyleNull
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  className={classes?.toggleContainer}
                >
                  <Typography className={classes?.toggleHeaderText}>
                    Enable Monitoring
                  </Typography>
                  <Switch
                    checked={formAdmin?.values?.enableMonitoring}
                    onChange={(event) => {
                      const isChecked = event?.target?.checked;
                      formAdmin?.setFieldValue('enableMonitoring', isChecked);

                      if (!isChecked) {
                        formAdmin?.setFieldValue('enableLopMails', false);
                      }
                    }}
                    className={
                      !formAdmin?.values?.enableMonitoring
                        ? classes?.thumbStyleBlack
                        : classes?.thumbStyleNull
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  className={classes?.toggleContainer}
                >
                  <Typography className={classes?.toggleHeaderText}>
                    Mark Late Check-in
                  </Typography>
                  <Switch
                    checked={formAdmin?.values?.markLateCheckin}
                    onChange={(event) =>
                      formAdmin?.setFieldValue(
                        'markLateCheckin',
                        event?.target?.checked
                      )
                    }
                    className={
                      !formAdmin?.values?.markLateCheckin
                        ? classes?.thumbStyleBlack
                        : classes?.thumbStyleNull
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  className={classes?.toggleContainer}
                >
                  <Typography className={classes?.toggleHeaderText}>
                    Enable LOP Mails
                  </Typography>
                  <Switch
                    checked={formAdmin?.values?.enableLopMails}
                    onChange={(event) => {
                      if (formAdmin?.values?.enableMonitoring) {
                        formAdmin?.setFieldValue(
                          'enableLopMails',
                          event?.target?.checked
                        );
                      }
                    }}
                    disabled={!formAdmin?.values?.enableMonitoring}
                    className={
                      !formAdmin?.values?.enableMonitoring
                        ? classes?.thumbStyleBlack
                        : classes?.thumbStyleNull
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} className={classes?.rowContainer}>
                <Grid item xs={12} sm={6} className={classes?.columnContainer}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Monitoring Start Time"
                      value={
                        formAdmin?.values?.monitoringStartTime
                          ? dayjs(
                              formAdmin?.values?.monitoringStartTime,
                              AttendanceTimeFormats?.HH_MM_A
                            )
                          : null
                      }
                      onChange={(newValue: Dayjs | null) => {
                        const formattedTime = newValue
                          ? newValue?.format(AttendanceTimeFormats?.HH_MM_A)
                          : '';
                        formAdmin?.setFieldValue(
                          'monitoringStartTime',
                          formattedTime
                        );
                        formAdmin?.setFieldValue('restartSchedulers', true);
                        formAdmin?.setFieldTouched('monitoringStartTime', true);
                      }}
                      className={classes?.timePicker}
                    />

                    {formAdmin?.touched?.monitoringStartTime &&
                    formAdmin?.errors?.monitoringStartTime ? (
                      <Typography color="error" className={classes?.errorText}>
                        {formAdmin?.errors?.monitoringStartTime}
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} className={classes?.columnContainer}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Monitoring End Time"
                      value={
                        formAdmin?.values?.monitoringEndTime
                          ? dayjs(
                              formAdmin?.values?.monitoringEndTime,
                              AttendanceTimeFormats?.HH_MM_A
                            )
                          : null
                      }
                      onChange={(newValue: Dayjs | null) => {
                        const formattedTime = newValue
                          ? newValue?.format(AttendanceTimeFormats?.HH_MM_A)
                          : '';
                        formAdmin?.setFieldValue(
                          'monitoringEndTime',
                          formattedTime
                        );
                        formAdmin?.setFieldValue('restartSchedulers', true);
                        formAdmin?.setFieldTouched('monitoringEndTime', true);
                      }}
                      className={classes?.timePicker}
                    />

                    {formAdmin?.touched?.monitoringEndTime &&
                    formAdmin?.errors?.monitoringEndTime ? (
                      <Typography color="error" className={classes?.errorText}>
                        {formAdmin?.errors?.monitoringEndTime}
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} className={classes?.rowContainer}>
                <Grid item xs={12} sm={6} className={classes?.columnContainer}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Generate Reports At"
                      value={
                        formAdmin?.values?.reportsTime
                          ? dayjs(
                              formAdmin?.values?.reportsTime,
                              AttendanceTimeFormats?.HH_MM_A
                            )
                          : null
                      }
                      onChange={(newValue: Dayjs | null) => {
                        const formattedTime = newValue
                          ? newValue?.format(AttendanceTimeFormats?.HH_MM_A)
                          : '';
                        formAdmin?.setFieldValue('reportsTime', formattedTime);
                        formAdmin?.setFieldValue('restartSchedulers', true);
                        formAdmin?.setFieldTouched('reportsTime', true);
                      }}
                      className={classes?.timePicker}
                    />

                    {formAdmin?.touched?.reportsTime &&
                    formAdmin?.errors?.reportsTime ? (
                      <Typography color="error" className={classes?.errorText}>
                        {formAdmin?.errors?.reportsTime}
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} className={classes?.columnContainer}>
                  <Autocomplete
                    disablePortal
                    id="monitoringFrequency"
                    options={monitorFrequencyOptions}
                    value={
                      monitorFrequencyOptions?.find(
                        (option) =>
                          option?.value ===
                          formAdmin?.values?.monitoringFrequency
                      ) || null
                    }
                    onChange={(_event, newValue) => {
                      const selectedValue = newValue ? newValue?.value : '';
                      formAdmin?.setFieldValue(
                        'monitoringFrequency',
                        selectedValue
                      );
                      formAdmin?.setFieldValue('restartSchedulers', true);
                    }}
                    getOptionLabel={(option) => option?.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Monitoring Frequency"
                        placeholder="Choose Monitoring Frequency"
                        error={Boolean(
                          formAdmin?.touched?.monitoringFrequency &&
                            formAdmin?.errors?.monitoringFrequency
                        )}
                        helperText={
                          formAdmin?.touched?.monitoringFrequency &&
                          formAdmin?.errors?.monitoringFrequency
                        }
                        fullWidth
                      />
                    )}
                    className={classes?.timePicker}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Signature Name"
                  value={formAdmin?.values?.reportingName}
                  onChange={formAdmin?.handleChange('reportingName')}
                  error={Boolean(
                    formAdmin?.touched?.reportingName &&
                      formAdmin?.errors?.reportingName
                  )}
                  helperText={
                    formAdmin?.touched?.reportingName &&
                    formAdmin?.errors?.reportingName
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes?.inputAdornment}
                      >
                        <Tooltip title={ToolTipMessages?.reportingName}>
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="employeeIdCode"
                  disabled
                  label="Employee ID Starts With"
                  value={formAdmin?.values?.employeeIdCode}
                  onChange={formAdmin?.handleChange('employeeIdCode')}
                  error={Boolean(
                    formAdmin?.touched?.employeeIdCode &&
                      formAdmin?.errors?.employeeIdCode
                  )}
                  helperText={
                    formAdmin?.touched?.employeeIdCode &&
                    formAdmin?.errors?.employeeIdCode
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes?.inputAdornment}
                      >
                        <Tooltip
                          title={ToolTipMessages?.employeeIdStartWithMessage}
                        >
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="reportingEmails"
                  options={usersData}
                  multiple
                  value={usersData?.filter((user) =>
                    formAdmin?.values?.reportingEmails?.includes(
                      user?.userData?.emailId
                    )
                  )}
                  getOptionLabel={(option: any) =>
                    option?.userData?.emailId || ''
                  }
                  onChange={handleOptionSelect}
                  size="medium"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reporting Emails"
                      placeholder="Enter email addresses"
                      error={
                        formAdmin?.touched?.reportingEmails &&
                        Boolean(formAdmin?.errors?.reportingEmails)
                      }
                      helperText={
                        formAdmin?.touched?.reportingEmails &&
                        formAdmin?.errors?.reportingEmails
                      }
                      InputProps={{
                        ...params?.InputProps,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes?.inputAdornment}
                          >
                            <Tooltip title={ToolTipMessages?.reportingEmails}>
                              <HelpOutlineIcon />
                            </Tooltip>
                            {params?.InputProps?.endAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} className={classes?.rowContainer}>
                <Grid item xs={12} sm={6} className={classes?.columnContainer}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Check-In Time"
                      value={
                        formAdmin?.values?.checkInTime
                          ? dayjs(
                              formAdmin?.values?.checkInTime,
                              AttendanceTimeFormats?.HH_MM_A
                            )
                          : null
                      }
                      onChange={(newValue: Dayjs | null) => {
                        const formattedTime = newValue
                          ? newValue?.format(AttendanceTimeFormats?.HH_MM_A)
                          : '';
                        formAdmin?.setFieldValue('checkInTime', formattedTime);
                        formAdmin?.setFieldTouched('checkInTime', true);
                      }}
                      className={classes?.timePicker}
                    />
                    {formAdmin?.touched?.checkInTime &&
                    formAdmin?.errors?.checkInTime ? (
                      <Typography color="error" className={classes?.errorText}>
                        {formAdmin?.errors?.checkInTime}
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} className={classes?.columnContainer}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Check-Out Time"
                      value={
                        formAdmin?.values?.checkOutTime
                          ? dayjs(
                              formAdmin?.values?.checkOutTime,
                              AttendanceTimeFormats?.HH_MM_A
                            )
                          : null
                      }
                      onChange={(newValue: Dayjs | null) => {
                        const formattedTime = newValue
                          ? newValue?.format(AttendanceTimeFormats?.HH_MM_A)
                          : '';
                        formAdmin?.setFieldValue('checkOutTime', formattedTime);
                        formAdmin?.setFieldTouched('checkOutTime', true);
                      }}
                      className={classes?.timePicker}
                    />
                    {formAdmin?.touched?.checkOutTime &&
                    formAdmin?.errors?.checkOutTime ? (
                      <Typography color="error" className={classes?.errorText}>
                        {formAdmin?.errors?.checkOutTime}
                      </Typography>
                    ) : null}
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} className={classes?.rowContainer}>
                <TextField
                  fullWidth
                  type="text"
                  label="Hours Per Day"
                  value={formAdmin?.values?.hoursPerDay}
                  onChange={(event) => {
                    const value = event?.target?.value;

                    const validNumberPattern = /^\d*\.?\d*$/;
                    if (validNumberPattern?.test(value) || value === '') {
                      const numericValue =
                        value === '' ? '' : parseFloat(value);
                      if (
                        typeof numericValue === 'number' &&
                        numericValue > 24
                      ) {
                        formAdmin?.setFieldValue('hoursPerDay', 24);
                      } else {
                        formAdmin?.setFieldValue('hoursPerDay', value);
                      }
                    }
                  }}
                  onBlur={() => {
                    const currentValue = formAdmin?.values?.hoursPerDay;
                    if (
                      typeof currentValue === 'string' &&
                      currentValue !== '' &&
                      !isNaN(parseFloat(currentValue))
                    ) {
                      formAdmin?.setFieldValue(
                        'hoursPerDay',
                        parseFloat(currentValue)
                      );
                    }
                  }}
                  error={Boolean(
                    formAdmin?.touched?.hoursPerDay &&
                      formAdmin?.errors?.hoursPerDay
                  )}
                  helperText={
                    formAdmin?.touched?.hoursPerDay &&
                    formAdmin?.errors?.hoursPerDay
                  }
                  inputProps={{
                    min: 0,
                    step: 'any',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes?.inputAdornment}
                      >
                        <Tooltip title={ToolTipMessages?.hoursPerDay}>
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  className={classes?.numericField}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Warning Hours"
                  value={formAdmin?.values?.warningHours}
                  onChange={(event) => {
                    const value = event?.target?.value;

                    const validNumberPattern = /^\d*\.?\d*$/;

                    if (validNumberPattern?.test(value) || value === '') {
                      const numericValue =
                        value === '' ? '' : parseFloat(value);
                      if (
                        typeof numericValue === 'number' &&
                        numericValue > 24
                      ) {
                        formAdmin?.setFieldValue('warningHours', 24);
                      } else {
                        formAdmin?.setFieldValue('warningHours', value);
                      }
                    }
                  }}
                  onBlur={() => {
                    const currentValue = formAdmin?.values?.warningHours;
                    if (
                      typeof currentValue === 'string' &&
                      currentValue !== '' &&
                      !isNaN(parseFloat(currentValue))
                    ) {
                      formAdmin?.setFieldValue(
                        'warningHours',
                        parseFloat(currentValue)
                      );
                    }
                  }}
                  error={Boolean(
                    formAdmin?.touched?.warningHours &&
                      formAdmin?.errors?.warningHours
                  )}
                  helperText={
                    formAdmin?.touched?.warningHours &&
                    formAdmin?.errors?.warningHours
                  }
                  inputProps={{
                    min: 0,
                    step: 'any',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes?.inputAdornment}
                      >
                        <Tooltip title={ToolTipMessages?.warningHours}>
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  className={classes?.numericField}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Critical Hours"
                  value={formAdmin?.values?.criticalHours}
                  onChange={(event) => {
                    const value = event?.target?.value;

                    const validNumberPattern = /^\d*\.?\d*$/;

                    if (validNumberPattern?.test(value) || value === '') {
                      const numericValue =
                        value === '' ? '' : parseFloat(value);
                      if (
                        typeof numericValue === 'number' &&
                        numericValue > 24
                      ) {
                        formAdmin?.setFieldValue('criticalHours', 24);
                      } else {
                        formAdmin?.setFieldValue('criticalHours', value);
                      }
                    }
                  }}
                  onBlur={() => {
                    const currentValue = formAdmin?.values?.criticalHours;
                    if (
                      typeof currentValue === 'string' &&
                      currentValue !== '' &&
                      !isNaN(parseFloat(currentValue))
                    ) {
                      formAdmin?.setFieldValue(
                        'criticalHours',
                        parseFloat(currentValue)
                      );
                    }
                  }}
                  error={Boolean(
                    formAdmin?.touched?.criticalHours &&
                      formAdmin?.errors?.criticalHours
                  )}
                  helperText={
                    formAdmin?.touched?.criticalHours &&
                    formAdmin?.errors?.criticalHours
                  }
                  inputProps={{
                    min: 0,
                    step: 'any',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes?.inputAdornment}
                      >
                        <Tooltip title={ToolTipMessages?.criticalHours}>
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  className={classes?.numericField}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Acceptable Delay in Minutes"
                  value={formAdmin?.values?.acceptableDelayInMinutes}
                  onChange={(e) => {
                    try {
                      let minutes: number = parseInt(e?.target?.value);
                      if (minutes > 59) minutes = 15;
                      formAdmin?.setFieldValue(
                        'acceptableDelayInMinutes',
                        '' + minutes
                      );
                    } catch (error) {
                      dispatch(
                        pushNotification({
                          isOpen: true,
                          message: 'Invalid Value',
                          type: CONSTANTS?.ERROR,
                        })
                      );
                    }
                  }}
                  error={Boolean(
                    formAdmin?.touched?.acceptableDelayInMinutes &&
                      formAdmin?.errors?.acceptableDelayInMinutes
                  )}
                  helperText={
                    formAdmin?.touched?.acceptableDelayInMinutes &&
                    formAdmin?.errors?.acceptableDelayInMinutes
                  }
                  inputProps={{
                    min: 0,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="weekends"
                  multiple
                  options={attendanceWeekdays}
                  value={attendanceWeekdays?.filter((day) =>
                    formAdmin?.values?.weekends?.includes(day?.value)
                  )}
                  onChange={(event, newValue) => {
                    const selectedValues = newValue?.map((item) => item?.value);
                    formAdmin?.setFieldValue('weekends', selectedValues);
                  }}
                  getOptionLabel={(option) => option?.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Week Offs"
                      placeholder="Choose Week Offs"
                      error={Boolean(
                        formAdmin?.touched?.weekends &&
                          formAdmin?.errors?.weekends
                      )}
                      helperText={
                        formAdmin?.touched?.weekends &&
                        formAdmin?.errors?.weekends
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Box display={'flex'} justifyContent={'center'} mt={4}>
              <Button
                variant="contained"
                className={classes?.saveButton}
                type="submit"
                disabled={!formAdmin?.dirty}
              >
                {postLoading ? (
                  <ClipLoader
                    color={classes?.color}
                    loading={postLoading}
                    className={classes?.override}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </>
  );
};

export default AdminSettings;
