import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import CONSTANTS from '../../constants/constants';
import { pushNotification } from '../../redux/actions';
import {
  COMMON_CONSTANTS,
  DataMapOperations,
  DataMapOperationsType,
  DataMapTypes,
} from '../../screen/adminConstants';
import {
  fetchApplicationSettings,
  postEmployeeSettings,
} from '../../services/AdminService';
import { DataMapComponentConstants } from './constants';
import EmployeeTypePopup from './EmployeeTypePopup';
import ExpenseTypePopup from './ExpenseTypePopup';
import useCustomStyles from './styles/CustomStylesHook';

interface DataMapComponentProps {
  header: string;
  itemKey: string;
}

const styles = () => ({
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.1)',
    width: '31%',
    minWidth: '300px',
    margin: '1rem 0rem',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '8px',
    marginBottom: '16px',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  button: {
    minWidth: '5rem',
    backgroundColor: '#162952',
    color: '#ffff',
    '&:disabled': {
      backgroundColor: 'rgb(128, 128, 128)',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#081530',
      color: '#fff',
    },
  },
  addButton: {
    whiteSpace: 'nowrap !important',
    backgroundColor: '#f15830',
    color: '#ffff',
    '&:disabled': {
      backgroundColor: 'rgb(128, 128, 128)',
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#b13111',
      color: '#fff',
    },
  },
  listContainer: {
    width: '100%',
    maxHeight: '300px',
    overflowY: 'auto',
  },
  iconButton: {
    padding: '4px',
    width: '100%',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '8px 16px',
  },
  actionButton: {
    marginLeft: '8px',
  },
  errorText: {
    color: 'red',
    marginTop: '4px',
  },
  color: '#ffffff',
  cssOverrideStyle: {
    display: 'block',
    margin: '0 auto !important',
    borderColor: '#4B6D8F',
  },
  header: { width: '100% !important', flex: '100%' },
  dataContainer: {
    width: '100% !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
  },
});

const DataMapComponent: React.FC<DataMapComponentProps> = ({
  header,
  itemKey,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);

  const [allData, setAllData] = useState<any>([]);
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [getLoading, setGetLoading] = useState(false);
  const [isOpenExpenseTypePopup, setIsOpenExpenseTypePopup] = useState(false);
  const [isOpenEmployeeTypePopup, setIsOpenEmployeeTypePopup] = useState(false);
  const [selectedExpenseType, setSelectedExpenseType] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredData = allData?.filter((record: any) =>
    record?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e?.target?.value);
  };

  const fetchData = async () => {
    setGetLoading(true);
    const response = await fetchApplicationSettings();
    if (response?.success) {
      setAllData(response?.data?.[itemKey]);
      setGetLoading(false);
    } else {
      setGetLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveExpenseType = (updatedRecord: any) => {
    let payload = [updatedRecord];
    if (updatedRecord?.reference) {
      payload = [
        {
          ...updatedRecord,
          update: true,
        },
      ];
    }
    handleSaveChanges(payload, false).then(async (result) => {
      setSelectedExpenseType(null);
      if (result) await fetchData();
    });
  };

  const processSelectedRecord = async (
    record: any,
    action: DataMapOperationsType
  ) => {
    setGetLoading(true);
    if (itemKey === DataMapTypes?.EXPENSE_TYPE) {
      await handleExpenseTypeAction(record, action);
    } else {
      await handleEmployeeTypeDeletion(record);
    }
    setGetLoading(false);
  };

  const handleExpenseTypeAction = async (
    record: any,
    action: DataMapOperationsType
  ) => {
    if (!allData || allData?.length === 0) return;
    setSelectedExpenseType(record);

    if (action === DataMapOperations?.OPERATION_EDIT) {
      setIsOpenExpenseTypePopup(true);
    } else {
      await deleteExpenseTypeRecord(record);
    }
  };

  const deleteExpenseTypeRecord = async (record: any) => {
    const payload = { ...record, delete: true };
    const result = await handleSaveChanges([payload], true);

    if (result) {
      setSelectedExpenseType(null);
      await fetchData();
    }
  };

  const handleEmployeeTypeDeletion = async (requestedRecord: any) => {
    const newData = allData?.filter(
      (record: any) => record?.name !== requestedRecord?.name
    );
    const result = await handleSaveChanges(newData, true);

    if (result) {
      setSelectedExpenseType(null);
      setAllData([...newData]);
    }
  };

  const handleSaveChanges = async (newData: any, isDeleting: boolean) => {
    setSelectedExpenseType(null);
    const payload = {
      [itemKey]: newData,
    };

    const response = await postEmployeeSettings(payload);

    setIsListVisible(true);
    if (response?.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: isDeleting
            ? DataMapComponentConstants?.DELETED_MESSAGE
            : DataMapComponentConstants?.ADDED_MESSAGE,
          type: CONSTANTS?.SUCCESS,
        })
      );
      return true;
    }

    dispatch(
      pushNotification({
        isOpen: true,
        message: response?.message,
        type: CONSTANTS?.ERROR,
      })
    );

    return false;
  };

  const handleCloseExpenseTypePopup = () => {
    setSelectedExpenseType(null);
    setIsOpenExpenseTypePopup(false);
  };

  const handleCloseEmployeeTypePopup = () => {
    setIsOpenEmployeeTypePopup(false);
  };

  const handleAddExpenseType = () => {
    setIsOpenExpenseTypePopup(true);
  };

  const handleAddEmployeeType = () => {
    setIsOpenEmployeeTypePopup(true);
  };
  const handleSaveEmployeeType = (newEmployeeType: any) => {
    const updatedData = newEmployeeType
      ? [...(allData ?? []), newEmployeeType]
      : allData ?? [];
    handleSaveChanges(updatedData, false)?.then(async (result) => {
      if (result) {
        await fetchData?.(); // Optional chaining for fetchData to ensure it's callable
      }
    });
  };

  return (
    <Box className={classes?.container}>
      <Box className={classes?.headingContainer}>
        <Typography variant="h5" gutterBottom className={classes?.header}>
          {header}
        </Typography>
        {itemKey === DataMapTypes?.EXPENSE_TYPE &&
          getAddButton(classes, handleAddExpenseType)}
        {itemKey === DataMapTypes?.EMPLOYEE_TYPES &&
          getAddButton(classes, handleAddEmployeeType)}
      </Box>
      <ExpenseTypePopup
        open={isOpenExpenseTypePopup}
        onClose={handleCloseExpenseTypePopup}
        record={selectedExpenseType}
        onSave={handleSaveExpenseType}
      />
      <EmployeeTypePopup
        open={isOpenEmployeeTypePopup}
        onClose={handleCloseEmployeeTypePopup}
        onSave={handleSaveEmployeeType}
      />

      <Box className={classes?.dataContainer}>
        {getLoading ? (
          <ClipLoader
            color={classes?.color}
            loading={getLoading}
            className={classes?.cssOverrideStyle}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <Collapse in={isListVisible} timeout="auto" unmountOnExit>
            <Box className={classes?.listContainer}>
              <TextField
                value={searchQuery}
                onChange={handleSearchChange}
                label="Search"
                variant="outlined"
                className={classes?.input}
                margin="normal"
                fullWidth
              />
              {allData?.length > 0 ? (
                <List>
                  {filteredData?.map((record, index) => (
                    <Box key={record}>
                      <ListItem className={classes?.listItem}>
                        <ListItemText primary={record?.name} />
                        {itemKey === DataMapTypes?.EXPENSE_TYPE && (
                          <Button
                            variant="outlined"
                            color="error"
                            className={classes?.actionButton}
                            onClick={() =>
                              processSelectedRecord(
                                record,
                                DataMapOperations?.OPERATION_EDIT
                              )
                            }
                          >
                            {COMMON_CONSTANTS?.EDIT}
                          </Button>
                        )}
                        <Button
                          variant="outlined"
                          color="error"
                          className={classes?.actionButton}
                          onClick={() =>
                            processSelectedRecord(
                              record,
                              DataMapOperations?.OPERATION_DELETE
                            )
                          }
                        >
                          {COMMON_CONSTANTS?.DELETE}
                        </Button>
                      </ListItem>
                      {index < allData?.length - 1 && <Divider />}
                    </Box>
                  ))}
                </List>
              ) : (
                <Typography
                  variant="body1"
                  align="center"
                  color="textSecondary"
                >
                  {COMMON_CONSTANTS?.EMPTY_RECORDS}
                </Typography>
              )}
            </Box>
          </Collapse>
        )}
        <Button
          className={classes?.iconButton}
          onClick={() => setIsListVisible(!isListVisible)}
        >
          {isListVisible ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </Box>
    </Box>
  );
};

export default DataMapComponent;
function getAddButton(
  classes: { [key: string]: string } | null,
  handleAddRecord: () => void
) {
  return (
    <Button
      variant="contained"
      className={classes?.addButton}
      onClick={handleAddRecord}
    >
      {COMMON_CONSTANTS?.PLUS}
      {COMMON_CONSTANTS?.ADD}
    </Button>
  );
}
