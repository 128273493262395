import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddIcon from '@mui/icons-material/Add';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useState } from 'react';
import useCustomStyles from '../../components/common/styles/CustomStylesHook';
import { COMMON_CONSTANTS } from '../adminConstants';

interface ExpenseTrackerToolbarProps {
  isAdmin?: any;
  isBusinessUnit?: any;
  containerStyle?: any;
  handleSearchData: any;
  handleOnClickAddExpenseRequest: any;
  handleOnClickExport: any;
  setSearchText: any;
  isEmpty: any;
}

const styles = () => ({
  containersContainerMain: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    gap: '2px',
    width: '100%',
  },
  containersSearchBox: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
  },
  iconsDownloadIcon: {
    color: '#1965ac',
    cursor: 'pointer',
  },
  iconsSearch: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  typographySearchExpenseRequests: {
    fontSize: '14px !important',
    color: '#121212 !important',
  },
  typographyFilterCount: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    background: '#e9653d',
    height: '16px',
    width: '16px',
    borderRadius: '100%',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
  },
  itemsTextFieldSearch: {
    minWidth: '100px',
    '& .MuiInputBase-root': {
      height: '36px',
    },
  },
  buttonsAddExpenseRequest: {
    textTransform: 'capitalize',
    display: 'flex',
    height: '36px',
    // background: "#08973f",
    background: '#1965ac',
    '&:hover': {
      background: '#0d5496',
    },
  },
  buttonsExport: {
    textTransform: 'capitalize',
    display: 'flex',
    height: '36px',
    background: '#F15830',
    '&:hover': {
      background: '#c93c19',
    },
  },
  buttonsFilter: {
    textTransform: 'capitalize',
    background: '#e9e9e9',
    transition: '1s',
    '&:hover': {
      background: '#ffffff',
      boxShadow: '0 0 15px black',
      transition: '0.2s',
      cursor: 'pointer',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 10px',
    borderRadius: '100%',
    height: '100%',
    position: 'relative',
  },
});
const ExpenseTrackerToolbar = ({
  isAdmin,
  isBusinessUnit,
  containerStyle = {},
  handleSearchData,
  handleOnClickAddExpenseRequest: handleOnClickAddExpenseRequest,
  handleOnClickExport,
  setSearchText,
  isEmpty,
}: ExpenseTrackerToolbarProps) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [localSearchText, setLocalSearchText] = useState('');
  return (
    <Container
      maxWidth={false}
      disableGutters
      className={`${classes?.containerContainerMain} ${containerStyle}`}
    >
      <Box>
        <Typography className={classes?.typographySearchExpenseRequests}>
          Search Expense Requests
        </Typography>
      </Box>
      <Box className={classes?.containersSearchBox}>
        <TextField
          type={'search'}
          id="outlined-size-normal"
          value={localSearchText}
          onChange={(event: any) => {
            const text = event?.currentTarget?.value;
            setLocalSearchText(text);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event?.key === COMMON_CONSTANTS?.ENTER) {
              event?.preventDefault();
              setSearchText(localSearchText);
              handleSearchData();
            }
          }}
          placeholder="Search"
          className={classes?.itemsTextFieldSearch}
          size={'small'}
          disabled={isEmpty}
          InputProps={{
            style: {
              borderRadius: '4px',
              backgroundColor: '#ffff',
            },
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={'Click to Search'}>
                  <SearchOutlinedIcon
                    className={classes?.iconsSearch}
                    onClick={() => {
                      if (!isEmpty) {
                        handleSearchData();
                      }
                    }}
                  />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        {
          <Button
            variant="contained"
            size="medium"
            className={classes?.buttonsAddExpenseRequest}
            onClick={handleOnClickAddExpenseRequest}
          >
            <AddIcon />
            Add Expense Request
          </Button>
        }
        {!isEmpty && (isAdmin || isBusinessUnit) && (
          <CloudDownloadOutlinedIcon
            className={classes?.iconsDownloadIcon}
            onClick={handleOnClickExport}
          />
        )}
      </Box>
    </Container>
  );
};

export default ExpenseTrackerToolbar;
