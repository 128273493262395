import { TabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import Notification from '../../components/common/notification';
import CONSTANTS from '../../constants/constants';
import {
  DOCUMENTS_UPLOAD_FORM_ID,
  GENERAL_FORM_ID,
} from '../../constants/formConstants';
import { getSessionStorageItem } from '../../encrypt';
import {
  pushNotification,
  setExpenseRequests,
  updateExpenseRequest,
} from '../../redux/actions';
import { NotificationProps } from '../../redux/reducer';
import { downloadFile } from '../../services/DocumentService';
import {
  approveOrRejectExpenseRequest,
  createOrUpdateExpenseRequest as createExpenseRequest,
  downloadExpenseExcel,
  getExpenseRequests,
} from '../../services/expenseTrackerApiService';
import {
  fetchFormDataWithId,
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from '../../services/formService';
import { getRoles } from '../../services/roleService';
import { uploadFileApi } from '../../services/UploadFile';
import {
  commonLeadGroups,
  expenseTrackerAllRequestsTab,
  expenseTrackerTabList,
  expenseTrackerTabTypeEnums,
  expenseTrackerTeamRequestsTab,
} from '../uiElementsAccess';
import ActionPopup from './ActionPopup';
import DocsModal from './DocsModal';
import ExpenseTrackerHeading from './ExpenseTrackerHeading';
import ExpenseTrackerLogTable from './ExpenseTrackerLogTable';
import ExpenseTrackerToolbar from './ExpenseTrackerToolbar';
import AddExpenseRequestForm from './forms/AddExpenseRequestForm';
import { StatusConstants } from './types/ExpenseTrackerTypes';
import ConfirmationDialog from './utils/ConfirmationDialog';
import { COMMON_CONSTANTS } from '../adminConstants';

const useStyles = makeStyles(() => ({
  modalRoot: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90%',
    width: '25vw',
    height: 'auto',
    gap: '1rem !important',
    minWidth: '600px !important',
    background: '#f0f4fc',
    borderRadius: '24px',
    padding: '1.5rem',
    '@media (max-width: 840px )': {
      minWidth: '250px !important',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  icon: {
    height: '50px !important',
    width: '50px !important',
  },
  flexCenter: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
  },
  fileNameBox: {
    wordWrap: 'break-word',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    gap: '4px !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    display: '-webkit-box !important',
    textAlign: 'start',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    minWidth: '80px !important',
    maxWidth: '200px !important',
    color: '#777',
  },
  downloadIcon: {
    width: '20px !important',
    height: '20px !important',
  },
  containersContainerMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    alignContent: 'space-between',
    padding: '18px 24px',
    position: 'relative',
    boxSizing: 'border-box',
    background: '#E3E7EF',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    overflow: 'auto',
  },
  containersContainerTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // height: '216px',
  },
  containersExpenseTrackerHeading: {
    width: '100%',
    height: '216px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '32px',
  },
  containersUpCommingEvents: {
    flexGrow: '1',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'stretch',
    // marginLeft: '1vw',
    // marginRight: '1vw',
    backgroundPosition: 'center -300px',
    boxShadow: '0 0 4px #777',
    background: '#ffffffff',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '14px',
    minWidth: '356px',
    // maxWidth: '656px',
    overflow: 'hidden',
    position: 'relative',
  },
  containersUpCommingEventsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
  },
  containersHolidaysWrapper: {
    boxShadow: '0 0 4px #777',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    display: 'flex',
    alignItems: 'stretch',
  },
  containersTabsContainer: {
    width: '100%',
    backgroundColor: 'white',
    //   height: 'auto',
    //   marginTop: '3.4vh',
    borderRadius: '10px',
    padding: '2% 0% 1% 1%',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  containersModalMain: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 2,
    width: '100%',
    '& *': {
      fontSize: '12px',
      color: '#000000ca',
    },
    background: '#f0f4fc',
    borderRadius: '24px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    inner: {
      display: 'flex !important',
      justifyContent: 'start !important',
      alignItems: 'start !important',
      flexWrap: 'wrap',
      gap: '12px !important',
    },
  },
  mainContainer: {
    width: '100%',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  tabs: {
    flexShrink: 0, // Prevent tabs from shrinking
    '&.MuiTabs-root .MuiTabs-indicator': {
      backgroundColor: '#F15830',
      width: '5px',
      borderRadius: '10px 0px 0px 10px',
    },
    '&.MuiTabs-root .Mui-selected': {
      color: '#F15830',
      fontWeight: 'bold',
    },
    '& button:hover': {
      backgroundColor: '#E5E4E2',
    },
    '& button': {
      textTransform: 'capitalize !important',
      fontSize: '1rem !important',
    },
    '@media (max-width: 960px)': {
      flexDirection: 'row',
      minWidth: 'auto',
      flexShrink: 1,
      '& .MuiTabs-flexContainer': {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      '& .MuiTab-root': {
        minWidth: 'initial',
      },
    },
  },
  tab: {
    '@media (min-width: 2000px)': {
      fontSize: 'calc(12px + 0.3rem)',
    },
    '@media (min-width: 1500px) and (max-width: 2000px)': {
      fontSize: 'calc(12px + 0.3rem)',
    },
    '@media (min-width: 1000px) and (max-width: 1500px)': {
      fontSize: 'calc(12px + 0.04rem)',
    },
    '@media (min-width: 600px) and (max-width: 1000px)': {
      fontSize: 'calc(12px + 0.004rem)',
    },
    '@media (max-width: 600px)': {
      fontSize: 'calc(12px + 0.0006rem)',
    },
    fontWeight: '600',
    color: 'black',
  },
  yearSelectContainer: {
    width: '80px',
  },
  tabPanelContainer: {
    p: 'unset',
  },
  wfhTabPanelContainer: {
    p: 'unset',
    paddingTop: '20px',
    fontWeight: '1000',
    fontSize: '1',
  },
  buttonsCloseButton: {
    color: '#2b5f6b',
    position: 'absolute',
    top: 16,
    right: 16,
    borderRadius: '50%',
    width: 40,
    height: 40,
    minWidth: 0,
    padding: 0,
    zIndex: 1100,
  },
  documentsBox: {
    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    border: '1px solid #C3C3C3 !important',
    borderRadius: '15px !important',
    gap: 2,
    padding: '8px !important',
    width: '48% !important',
  },
  fileIconStyle: {
    height: '2rem !important',
    width: '2rem !important',
    backgroundPosition: 'center !important',
    objectFit: 'cover',
    objectPosition: 'center !important',
  },
  constainerStyle: {
    width: '100%',
  },
  toolbarContainerStyle: {
    width: '100%',
  },
}));

interface EmployeeData {
  empId: number;
  fullName: string;
  reportingTo: string;
  email: string;
}
const ExpenseTrackerSystem = () => {
  const classes = useStyles();
  const emailId: any = getSessionStorageItem('user-email');

  const [isLoading, setIsLoading] = useState(false);
  const [recordId, setRecordId] = useState('');
  const [searchText, setSearchText] = useState<string>('');
  const [page, setPage] = useState<any>(1);
  const [rows, setRows] = useState<any[]>([]);
  const [allFiles, setAllFiles] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [showAddExpenseTrackerForm, setShowAddExpenseTrackerForm] =
    useState(false);
  const [expenseRequestStatus, setExpenseRequestStatus] = useState<any>('All');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarSeverity, setSnackbarSeverity] =
    useState<NotificationProps['type']>('success');
  const [message, setMessage] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationPromise, setConfirmationPromise] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isBusinessUnit, setIsBusinessUnit] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docsModalVisibility, setDocsModalVisibility] = useState(false);
  const [isSingleTime, setIsSingleTime] = useState(false);
  const [tabList, setTabList] = useState<any>(expenseTrackerTabList);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [currentAllDocs, setCurrentAllDocs] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = React.useState(
    expenseTrackerTabTypeEnums?.myRequests
  );
  const [empData, setEmpData] = useState<EmployeeData>();
  const [filters, setFilters] = useState<any>();
  const statusOptions = [
    'Pending Approval',
    'Approved',
    'Rejected',
    'Revoked',
    'All',
  ];
  const [isOpenActionPopup, setIsOpenActionPopup] = useState(false);
  const [actionPopupType, setActionPopupType] = useState<any>();
  const [amountRequested, setAmountRequested] = useState<any>();
  const [currentSelectedRequest, setCurrentSelectedRequest] = useState<any>();

  const sortedRows = [...rows]?.sort(
    (a, b) =>
      new Date(b?.createdAt)?.getTime() - new Date(a?.createdAt)?.getTime()
  );

  let fetchExpenseRequestsCaller = '----------';

  const dispatch = useDispatch();
  const rowsStateData = useSelector((state: any) => state?.expenses);

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  const handleActionPopupClose = () => {
    setIsOpenActionPopup(false);
  };
  const handleActionPopupConfirm = async (data: any) => {
    setLoading(true);
    let approverType;
    if (selectedTab === expenseTrackerTabTypeEnums?.teamRequests) {
      if (isManager) {
        approverType = COMMON_CONSTANTS?.MANAGER_APPROVAL;
      }
    } else if (selectedTab === expenseTrackerTabTypeEnums?.allRequests) {
      if (isBusinessUnit) {
        approverType = COMMON_CONSTANTS?.BUSINESS_APPROVAL;
      }
    }
    if (!approverType) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: 'You are not allowed to do this operation',
          type: CONSTANTS?.ERROR,
        })
      );
      return;
    }

    const modifiedPayload = {
      [approverType]: {
        status: data?.type,
        approverType,
        comments:
          data?.type === StatusConstants?.REJECTED ||
          data?.type === StatusConstants?.REVOKED
            ? data?.reason
            : '',
        deviationReason:
          data?.type === StatusConstants?.REJECTED ||
          data?.type === StatusConstants?.REVOKED
            ? ''
            : data?.reason,
        deviationAmount: data?.approvedAmount,
      },
    };
    try {
      await approveOrRejectExpenseRequest(
        currentSelectedRequest?.id,
        modifiedPayload
      ).then((response: any) => {
        if (response?.success) {
          setMessage(response?.message ?? 'Updated Successfully');
          setSnackbarSeverity('success');
          dispatch(
            updateExpenseRequest({
              id: currentSelectedRequest?.id,
              data: {
                ...modifiedPayload,
              },
            })
          );
        }
        setCurrentSelectedRequest(null);
        fetchExpenseRequests('handleActionPopupConfirm');
      });
      setLoading(false);
    } catch (error: any) {
      setCurrentSelectedRequest(null);
      setMessage(`${error?.message}`);
      setSnackbarSeverity('error');
    }
    setLoading(false);
    setOpenSnackBar(true);
  };
  const submitFormDataToDocumentsDataBase = async (
    allFiles: any,
    recordId: string
  ) => {
    // if (!recordId || recordId === '') {
    //   return { success: false, message: 'Documents Id is missing.' };
    // }
    const tempPayload = {
      formId: DOCUMENTS_UPLOAD_FORM_ID,
      formData: {
        reportingTo: empData?.reportingTo,
        officialEmail: empData?.email,
        files: allFiles,
      },
    };
    let payload;
    if (recordId && recordId !== '') {
      payload = {
        ...tempPayload,
        id: recordId,
      };
    } else {
      payload = {
        ...tempPayload,
      };
    }
    try {
      const response = await postForm(payload);

      if (!response?.success) {
        const notification = {
          isOpen: true,
          message:
            response?.message || COMMON_CONSTANTS?.ERROR_SAVING_DOCUMENT_DATA,
          type: CONSTANTS?.ERROR,
        };
        dispatch(pushNotification(notification));
        return { success: false, message: response?.message };
      }

      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        message:
          error?.message ||
          COMMON_CONSTANTS?.UNKNOWN_ERROR_WHILE_SAVING_DOCUMENT,
      };
    }
  };

  const postDocuments = async (files: any) => {
    try {
      const filesDataArray = await Promise.all(
        files?.map(async (item: any) => {
          const response: any = await uploadFileApi(
            item?.name,
            item,
            'empMaster',
            {}
          );
          if (!response?.success) {
            throw new Error(COMMON_CONSTANTS?.FILE_UPLOAD_FAILD);
          }
          return {
            fileName: item?.name,
            fileType: item?.type,
            fileSize: item?.size,
            documentId: response?.data?.id,
            status: response?.success,
          };
        })
      );

      const dbResponse = await submitFormDataToDocumentsDataBase(
        [...allFiles, ...filesDataArray],
        recordId
      );

      if (!dbResponse?.success) {
        throw new Error(
          dbResponse?.message || COMMON_CONSTANTS?.FAILD_TO_SAVE_META_DATA
        );
      }

      return { success: true, data: filesDataArray || [] };
    } catch (error: any) {
      return {
        success: false,
        data: [],
        message:
          error?.message ||
          COMMON_CONSTANTS?.UNKNOWN_ERROR_WHILE_UPLOADING_DOCUMENT,
      };
    }
  };

  const fetchUserData = async () => {
    const response = await fetchFormDataWithId(GENERAL_FORM_ID, emailId);

    if (response?.success && response?.data?.length > 0) {
      const mapData: any = response?.data?.[0].formData;

      const extractedData = {
        empId: mapData?.empId,
        fullName: mapData?.firstName + mapData?.lastName,
        reportingTo: mapData?.reportingTo,
        email: mapData?.officialEmail,
      };
      setEmpData(extractedData);
      const documentsUploadResponse: any =
        await FilterFormDetailsWithOfficalEmail(
          DOCUMENTS_UPLOAD_FORM_ID,
          mapData?.officialEmail
        );
      if (
        documentsUploadResponse?.success &&
        documentsUploadResponse?.data?.content?.length > 0
      ) {
        setRecordId(documentsUploadResponse?.data?.content?.[0]?.id);
        setAllFiles(
          documentsUploadResponse?.data?.content?.[0]?.formData?.files ?? []
        );
      } else {
        setRecordId('');
        setAllFiles([]);
      }
    }
  };

  const fetchExpenseRequests = async (caller: string) => {
    if (caller === fetchExpenseRequestsCaller) return;

    if (!filters) return;
    else fetchExpenseRequestsCaller = caller;
    setIsLoading(true);

    try {
      const response = await getExpenseRequests(filters);
      const data = response?.data;

      setTotalPages(response?.totalPages);

      if (data?.length === 0) {
        setIsSearchEmpty(true);
      } else {
        setIsSearchEmpty(false);
      }
      const items = data?.reduce((acc: any, item: any) => {
        acc[item?.id] = item;
        return acc;
      }, {});

      dispatch(setExpenseRequests(items));
      setIsLoading(false);
      setIsSingleTime(true);
    } catch (error: any) {
      setIsLoading(false);
      setMessage(`Error: ${error?.message}`);
      setSnackbarSeverity('error');
      setOpenSnackBar(true);
    }
  };

  const handleSearchData = async () => {
    fetchExpenseRequests(searchText + 'manual');
  };

  const handleOnClickExport = async () => {
    setIsLoading(true);
    try {
      await downloadExpenseExcel();
      setMessage(COMMON_CONSTANTS?.DATA_EXPORTED_SUCCESSFULLY);
      setSnackbarSeverity('success');
    } catch (error: any) {
      setMessage(`Error exporting data: ${error?.message}`);
      setSnackbarSeverity('error');
    }
    setIsLoading(false);
    setOpenSnackBar(true);
  };

  const handleOnClickAddExpenseRequest = () => {
    setShowAddExpenseTrackerForm(true);
  };

  const submitFormData = async (payload) => {
    try {
      const response = await createExpenseRequest(payload);
      setMessage(
        `${COMMON_CONSTANTS?.EXPENSE_CREATED_SUCCESSFULLY} Reference ID: [${response?.data?.id}]`
      );
      setSnackbarSeverity('success');
      setShowAddExpenseTrackerForm(false);
      fetchExpenseRequests(
        `Expense Request created with ID: ${response?.data?.id}`
      );
    } catch (error: any) {
      setMessage(`Error: ${error?.message}`);
      setSnackbarSeverity('error');
    } finally {
      setOpenSnackBar(true);
    }
  };

  const addExpenseRequestCallback = async (payloadValues: any) => {
    setIsLoading(true);
    try {
      let attachments: any = [];
      if (payloadValues?.filesBuffer?.length > 0) {
        const documentsUploadResponse = await postDocuments(
          payloadValues?.filesBuffer
        );

        if (!documentsUploadResponse?.success) {
          const notification = {
            isOpen: true,
            message:
              documentsUploadResponse?.message || 'Document upload failed.',
            type: CONSTANTS?.ERROR,
          };

          dispatch(pushNotification(notification));
          return; // Stop execution if document upload fails
        }

        attachments =
          documentsUploadResponse?.data?.map((file: any) => file?.documentId) ||
          [];
      }

      const finalPayload = {
        ...payloadValues,
        attachments: attachments,
      };

      delete finalPayload?.filesBuffer;

      await submitFormData(finalPayload);
    } catch (error: any) {
      const notification = {
        isOpen: true,
        message: error?.message || 'Error submitting form.',
        type: CONSTANTS?.ERROR,
      };
      dispatch(pushNotification(notification));
    } finally {
      setIsLoading(false);
    }
  };

  const showConfirmation = (title: any, message: any) => {
    setConfirmationTitle(title);
    setConfirmationMessage(message);
    setConfirmationOpen(true);
    return new Promise((resolve) => {
      setConfirmationPromise(() => resolve);
    });
  };

  const handleCloseConfirmation = (result: any) => {
    setConfirmationOpen(false);
    if (confirmationPromise) {
      confirmationPromise(result);
      setConfirmationPromise(null);
    }
  };
  const updateExpenseLocally = async (id: any, payload: any) => {
    try {
      await approveOrRejectExpenseRequest(id, payload).then((response: any) => {
        setMessage(response?.message ?? 'Updated Successfully');
        setSnackbarSeverity('success');
        dispatch(
          updateExpenseRequest({
            id,
            data: {
              ...payload,
            },
          })
        );
      });
    } catch (error: any) {
      setMessage(`${error?.message}`);
      setSnackbarSeverity('error');
    }
    setOpenSnackBar(true);
  };

  const handleDecision = async (params: any, newStatus: any) => {
    if (newStatus === StatusConstants?.INACTIVE) {
      const confirmed = await showConfirmation(
        'Cancel',
        'Are you sure you want to Cancel?'
      );
      if (!confirmed) return;
      const payload = {
        requestStatus: newStatus,
      };
      updateExpenseLocally(params?.row?.id, payload);
      return;
    }
    if (newStatus === StatusConstants?.PAID) {
      const payload = {
        paymentStatus: newStatus,
      };
      updateExpenseLocally(params?.row?.id, payload);
      return;
    }
    // setCurrentSelectedUser(params?.row);
    // setCurrentSelectedStatus(newStatus);
    // setApproveRejectPopupHeading(newStatus);
    setCurrentSelectedRequest(params?.row);
    setActionPopupType(newStatus);
    const finalAmount =
      !params?.row?.amountApproved ||
      params?.row?.amountApproved === '' ||
      params?.row?.amountApproved === '0'
        ? params?.row?.amountRequested
        : params?.row?.amountApproved;
    setAmountRequested(finalAmount);

    setIsOpenActionPopup(true);
  };

  const onClickNextPage = () => {
    if (page === totalPages) return;
    setPage((prev: any) => prev + 1);
  };

  const onClickPreviousPage = () => {
    if (page === 1) return;
    setPage((prev: any) => prev - 1);
  };

  const fetchRoles = async () => {
    try {
      const response: any = await getRoles();
      setIsAdmin(response?.formData?.clientRoles?.includes('ts-expense-admin'));
      const clientRoles: string[] = response?.formData?.clientRoles;
      setUserRoles([...clientRoles]);
      setIsBusinessUnit(clientRoles?.includes('ts-expense-bu'));
      setIsManager(
        clientRoles?.some((role) => commonLeadGroups?.includes(role))
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleCloseForm = () => {
    setDocsModalVisibility(false);
  };

  const downloadFiles = async (id, name) => {
    await downloadFile(id, name);
  };

  useEffect(() => {
    const lmsTabList: any[] = [...tabList];
    const teamRequestsIndex = tabList?.findIndex(
      (tab) => tab?.type === expenseTrackerTabTypeEnums?.teamRequests
    );
    if (
      userRoles?.some((role) => commonLeadGroups?.includes(role)) &&
      teamRequestsIndex === -1
    ) {
      lmsTabList?.push(expenseTrackerTeamRequestsTab);
      setIsManager(true);
    }
    const allReqeustTabIndex = lmsTabList?.findIndex(
      (tab) => tab?.type === expenseTrackerTabTypeEnums?.allRequests
    );
    if ((isBusinessUnit || isAdmin) && allReqeustTabIndex === -1) {
      // lmsTabList = [];
      lmsTabList?.push(expenseTrackerAllRequestsTab);
      setSelectedTab(expenseTrackerTabTypeEnums?.allRequests);
    }

    if (userRoles) {
      const orderedTabList = lmsTabList?.sort((a, b) => {
        // Sorting by the index of the enum values directly
        return (
          Object.values(expenseTrackerTabTypeEnums).indexOf(a?.type) -
          Object.values(expenseTrackerTabTypeEnums).indexOf(b?.type)
        );
      });
      setTabList([...orderedTabList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles, isBusinessUnit, isAdmin]);

  useEffect(() => {
    fetchUserData();
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      setRows(Object.values(rowsStateData));
      if (!isSingleTime) {
        setIsEmpty(Object.keys(rowsStateData)?.length === 0);
      }
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsStateData]);

  useEffect(() => {
    // emp360admin won't work here, because he don't have general form
    if (empData) {
      if (selectedTab === expenseTrackerTabTypeEnums?.myRequests) {
        setFilters({
          officialEmail: empData?.email,
          searchText,
        });
      } else if (selectedTab === expenseTrackerTabTypeEnums?.teamRequests) {
        setFilters({
          reportingTo: empData?.email,
          searchText,
        });
      } else {
        setFilters({
          searchText,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, empData, searchText]);

  useEffect(() => {
    fetchExpenseRequests(
      `${expenseRequestStatus}${page}${rowsPerPage}${expenseRequestStatus}${page}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters,
    expenseRequestStatus,
    page,
    rowsPerPage,
    expenseRequestStatus,
    page,
  ]);

  const ExpenseTrackerTabs = () => {
    return (
      <Box className={classes?.mainContainer}>
        {isOpenActionPopup && currentSelectedRequest && (
          <ActionPopup
            open={isOpenActionPopup}
            type={actionPopupType}
            amountRequested={amountRequested}
            onClose={handleActionPopupClose}
            onConfirm={handleActionPopupConfirm}
          />
        )}
        <Dialog
          open={loading}
          a-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Loading</DialogTitle>
          <DialogContent className={classes?.flexCenter}>
            <CircularProgress size={40} />
          </DialogContent>
        </Dialog>

        <DocsModal
          docsModalVisibility={docsModalVisibility}
          handleCloseForm={handleCloseForm}
          currentAllDocs={currentAllDocs}
          downloadFiles={downloadFiles}
          classes={classes}
        />

        <TabContext value={selectedTab}>
          <Box className={classes?.tabContainer}>
            <Box>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                className={classes?.tabs}
              >
                {tabList?.map((tabListItem) => {
                  return (
                    <Tab
                      id={`ttTab_${tabListItem?.type}`}
                      label={tabListItem?.label}
                      key={`ttTab_${tabListItem?.type}`}
                      value={tabListItem?.type}
                      className={classes?.tab}
                    />
                  );
                })}
              </Tabs>
            </Box>
          </Box>

          {Object.values(expenseTrackerTabTypeEnums)?.map((tab: string) => {
            return (
              <TabPanel
                key={tab}
                value={tab}
                className={classes?.tabPanelContainer}
              >
                <ExpenseTrackerLogTable
                  key={tab} // Ensure each table is uniquely identified
                  selectedTab={selectedTab}
                  rows={sortedRows}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  statusOptions={statusOptions}
                  expenseRequestStatus={expenseRequestStatus}
                  handleDecision={handleDecision}
                  isBusinessUnit={isBusinessUnit}
                  isFinanceTeam={isAdmin}
                  isLoading={isLoading}
                  onClickNextPage={onClickNextPage}
                  onClickPreviousPage={onClickPreviousPage}
                  isSearchEmpty={isSearchEmpty}
                  setDocsModalVisibility={setDocsModalVisibility}
                  setCurrentAllDocs={setCurrentAllDocs}
                  setExpenseRequestStatus={setExpenseRequestStatus}
                  handleRowsPerPageChange={(value) => setRowsPerPage(value)}
                  handlePageChange={setPage}
                  rowsPerPageArray={[10, 20, 30, 50, 100]}
                  containerStyle={{
                    width: '99%',
                    height: '550px',
                    marginTop: '18px',
                  }}
                  isManager={isManager}
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    );
  };

  return (
    <>
      <Box className={classes?.containersContainerMain}>
        <Notification
          isOpen={openSnackBar}
          type={snackBarSeverity}
          message={message}
          handleClose={handleClose}
        />
        {confirmationOpen && (
          <ConfirmationDialog
            open={confirmationOpen}
            onClose={handleCloseConfirmation}
            title={confirmationTitle}
            message={confirmationMessage}
          />
        )}
        <Dialog
          open={isLoading}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Loading</DialogTitle>
          <DialogContent className={classes?.flexCenter}>
            <CircularProgress size={40} />
          </DialogContent>
        </Dialog>

        <AddExpenseRequestForm
          show={showAddExpenseTrackerForm}
          setShow={setShowAddExpenseTrackerForm}
          addExpenseRequestCallback={addExpenseRequestCallback}
        />
        <Box className={classes?.containersContainerTop}>
          <Box className={classes?.containersExpenseTrackerHeading}>
            <ExpenseTrackerHeading
              dividerWidth={'90%'}
              dividerBackground={
                'linear-gradient(90deg, rgba(202, 89, 17, 1) 0%, rgba(247, 216, 199, 1) 50%)'
              }
              containerStyle={classes?.constainerStyle}
            />
            <ExpenseTrackerToolbar
              isAdmin={isAdmin}
              isBusinessUnit={isBusinessUnit}
              containerStyle={classes?.toolbarContainerStyle}
              handleSearchData={handleSearchData}
              setSearchText={setSearchText}
              handleOnClickAddExpenseRequest={handleOnClickAddExpenseRequest}
              handleOnClickExport={handleOnClickExport}
              isEmpty={isEmpty}
            />
          </Box>
        </Box>

        <Box className={classes?.containersTabsContainer}>
          <ExpenseTrackerTabs />
        </Box>
      </Box>
    </>
  );
};

export default ExpenseTrackerSystem;
