export const APPLY_LEAVE_CONSTANTS = {
  UPDATE: 'update',
  SAVE: 'save',
  FULL_DAY: 'fullDay',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  LEAVE_CATEGORY: 'leaveCategory',
  PARTIAL_LEAVE_CATEGORY: 'partialLeaveCategory',
  AVAILABLE_LEAVES: 'availableLeaves',
  PARTIAL_DAY: 'partialDay',
  PARTIAL_LEAVE_TYPE: 'partialLeaveType',
  LEAVE_TYPE: 'leaveType',
  INFORM_TO: 'informTo',
  REQUEST_TO: 'requestTo',
  TO_TIME: 'toTime',
  FROM_TIME: 'fromTime',
  EMP_ID: 'empId',
  COMMENTS: 'comments',
  COMP_OFF_ID: 'compOffRecordId',
  AVAILABLE: 'Available',
  COMP_OFFS: 'Comp Offs',
  COMP_OFF: 'Comp Off',
  WFH: 'WFH Request',
  LEAVES: 'Leaves',
  LEAVE: 'Leave',
  REASON: 'reason',
  SUBMIT_BUTTON: 'Submit',
  APPLY_BUTTON: 'Apply',
  CANCEL_BUTTON: 'Cancel',
  REASON_HEADING: 'May I know the Reason?',
  INFORM_TO_HEADING: 'Inform to (CC)',
  REQUEST_TO_HEADING: 'Reporting Manager',
  DATE_HEADING: 'Select Date',
  TIME_HEADING: 'Select Time Range',
  TO_TIME_HEADING: 'To Time',
  LEAVE_CATEGORY_HEADING: 'Category',
  LEAVE_TYPE_HEADING: 'Leave Type',
  EMP_ID_HEADING: 'Emp ID',
  FROM_TIME_HEADING: 'From Time',
  REASON_FIELD_HEADER: 'Reason for leave',
  CC_FIELD_HEADER: 'CC',
  RM_FIELD_HEADER: 'Reporting Manager',
  DAYS_FIELD_HEADER: 'No of Days',
  TO_DATE_FIELD_HEADER: 'To Date',
  FROM_DATE_FIELD_HEADER: 'From Date',
  AVAILABLE_LEAVES_HEADING: 'Available Leaves',
  LOP_FIELD_HEADER: 'Loss Of Pay',
  LEAVE_TYPE_FIELD_HEADER: 'Leave Type',
  EMP_ID_FIELD_HEADER: 'EmpId',
  START_DATE_PLACEHOLDER: 'Select Start Date',
  END_DATE_PLACEHOLDER: 'Select End Date',
  ERROR_MESSAGES: {
    HOLIDAYS: 'Error fetching holiday data:',
  },
};

export const popupMessages = {
  NO_LEAVES: {
    infoText: 'Leave request results in Loss of Pay (LOP).',
    rightButtonText: 'Cancel',
    leftButtonText: 'Proceed with LOP',
  },
  NO_GENERAL_LEAVES_USE_PRIVILEGE: {
    infoText:
      'General leaves exhausted, resulting in Loss of Pay (LOP). Use Privilege Leaves',
    rightButtonText: 'Use Privilege Leaves',
    leftButtonText: 'Proceed with LOP',
  },
  NO_GENERAL_LEAVES_LOP: {
    infoText: 'Leave request results in Loss of Pay (LOP).',
    rightButtonText: 'Save for later',
    leftButtonText: 'Proceed with LOP',
  },
  EXCEEDS_GENERAL_USE_PRIVILEGE: {
    infoText:
      'Requested leave days exceed available general leaves. Use Privilege Leaves for Excess',
    rightButtonText: 'Use Privilege Leaves',
    leftButtonText: 'Proceed with LOP',
  },
  GENERAL_LEAVES_AVAILABLE: {
    infoText:
      'General leaves are available. Reserve Privilege Leaves for Future Use',
    rightButtonText: 'Reserve Privilege Leaves',
    leftButtonText: 'Proceed',
  },
  NO_PRIVILEGE_LEAVES: {
    infoText:
      'General leaves are available. Save Privilege Leaves for Future Needs',
    rightButtonText: 'Save Privilege Leaves',
    leftButtonText: 'Proceed',
  },
};

export const leaveCategories = [
  {
    title: 'Partial Day',
    value: 'partialDay',
  },
  {
    title: 'Full Day',
    value: 'fullDay',
  },
];

export const partialLeaveCategories = [
  {
    title: 'First Half (10AM - 2PM)',
    value: 'firstHalf',
  },
  {
    title: 'Second Half (2PM - 6PM)',
    value: 'secondHalf',
  },
];

// validationMessages.js

export const validationMessages = {
  startDate: {
    required: 'Please select Start Date',
    invalid: 'Start Date must be within the past 30 days',
  },
  endDate: {
    required: 'Please select End Date',
    beforeStartDate: 'End Date cannot be before Start Date',
  },
  leaveType: {
    required: 'Please select at least one Option',
  },
  informTo: {
    required: 'Please select at least one Option',
  },
  reason: {
    required: 'Please enter the reason',
  },
  leaveCategory: {
    required: 'Please select the Leave Category',
  },
  partialLeaveType: {
    required: 'Please select the time range',
  },
  fromTime: {
    required: 'Please select the From time',
    invalid: 'Invalid time format',
  },
  toTime: {
    required: 'Please select the To time',
    invalid: 'Invalid time format',
  },
};
