const CONSTANTS = {
  WELCOME_MSG: 'welcome_msg',
  COMPONENT_LIST_MSG: 'component_list_msg',
  DEFAULT_MSG: 'default_msg',
  PROFILE_MSG: 'profile_msg',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  LOGOUT: 'logout',
  THEME: 'theme',
  SXP_TITLE: 'Welcome to TSF React base project',
  SXP_SUBTITLE: 'Service eXperience Platform',
  SXP_DEFAULT_MSG: 'Hi, Welcome To React Template Project',
  VERSION_ID: 'WORKING',
  COULDNT_UPLOAD_DOC: 'Could not upload Document',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  BUSINESS_USER: 'business-user',
  SELECT_START_END_DATE: 'Select start date and end date',
  USER_EMAIL: 'user-email',
  FIRST_NAME: 'first-name',
  LAST_NAME: 'last-name',
  USER_ID: 'user-id',
  REACT_TOKEN: 'react-token',
  USER_TYPE: 'user-type',
  COUNTRY: 'country',
  I18NEXTLNG: 'i18nextLng',
  USER_NAME: 'userName',
  ERROR_OCCURRED: 'Some error occurred',
  DOCUMENTS_ERROR_OCCURRED:
    'Error occurred while deleting supporting documents:',
  COPY_SUCCESS: 'Copied to clipboard',
  SUPPORTED_FILES: ', supported files type are "JPG", "PNG", "JPEG"',
  DEFAULT_USER_IMAGE:
    'https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg',
};

export const LETTERS_REGEX = /^[a-zA-Z '&().,\b]+$/;
export const URL_REGEX = new RegExp(
  '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
);
export const PINCODE_REGEX = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;
// export const PHONE_REGEX =
//   /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/;
export const PHONE_REGEX = /^(?:(?:\+|0{0,2})92(\s*[-]\s*)?|[6-9])\d{11}$/;

// /^(\+92|0)?[3456789]\d{11}$/

// /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[6-9]\d{9}|(\d[ -]?){10}\d$/;
// /^(?:(?:\+|0{0,2})\d{1,4}(\s*[\ -]\s*)?|[0]?)?[1-9]\d{9}|(\d[ -]?){10}\d$/

export const FIRST_NAME = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const LAST_NAME = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const EMP_ID = /^[a-zA-Z0-9]+$/;
export const USER_NAME = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const BANK_NAME = /^[a-zA-Z\s-]+$/;
export const ACCOUNT_NUMBER = /^[0-9]{6,}$/;
export const IFSC = /^[A-Z]{4}[0][a-zA-Z0-9]{6}$/;
export const BRANCH_NAME = /^[a-zA-Z0-9\s-']+$/;
export const NATIONALITY = /^[a-zA-Z\s-]+$/;
export const ACCOUNT_NO = /^\d{9,18}$/;
export const AADHAR_NO = /^\d{12}$/;
export const PAN_NO = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
export const IFSC_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/i;

export const PASSPORT_REGEX = /^[A-Z0-9]{6,10}$/;

export const PERSONAL_FORMID = '1052917923916066816';
export const GENERAL_FORMID = '1058380750487334912';
export const HOLIDAYS_FORMID = '1060961139886174208';
export const ALL_KEY = 'All';
export const COLUMNS_TEXT = 'Columns';
export const PAGE_SIZE_ARRAY = [10, 15, 20, 25, 50, 100];
export const ISMS_INFO =
  'An information security management system (ISMS) is a set of policies and procedures for systematically managing an organization\'s sensitive data. The goal of an ISMS is to minimize risk and ensure business continuity by proactively limiting the impact of a security breach.An ISMS typically addresses employee behavior and processes as well as data and technology. It can be targeted toward a particular type of data, such as customer data, or it can be implemented in a comprehensive way that becomes part of the company\'s culture.';
export const BULK_UPLOAD_INITIAL_MESSAGE =
  'Import in Progress: Your import request is being processed. Please do not close this page or initiate another upload. You will be notified via email once the import is completed.';
export const phoneNumberRegex = {
  in: /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?[6789]\d{9})$/,
  us: /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
  pk: /^(?:\+92|0)?[6789]\d{11}$/,
  ca: /^(?:\+?1)?[2-9]\d{9}$/,
  gb: /^(?:\+?44)?(?:0|11)?[1-9]\d{9}$/,
  au: /^(?:\+?61)?(?:0?[2-57-8]|0?[1,9][0-9]|0?4[0-8])\d{8}$/,
  nz: /^(?:\+?64)?(0)[23456789]\d{8}$/,
  lk: /^(?:0|94|\+94)?(7(0|1|2|5|6|7|8)( |-)?\d)\d{6}$/,
  'am-AM': /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
  'ar-AE': /^((\+?971)|0)?5[024568]\d{7}$/,
  'ar-BH': /^(\+?973)?(3|6)\d{7}$/,
  'ar-DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
  'ar-EG': /^((\+?20)|0)?1[0125]\d{8}$/,
  'ar-IQ': /^(\+?964|0)?7[0-9]\d{8}$/,
  'ar-JO': /^(\+?962|0)?7[789]\d{7}$/,
  'ar-KW': /^(\+?965)[569]\d{7}$/,
  'ar-SA': /^(!?(\+?966)|0)?5\d{8}$/,
  'ar-SY': /^(!?(\+?963)|0)?9\d{8}$/,
  'ar-TN': /^(\+?216)?[2459]\d{7}$/,
  'be-BY': /^(\+?375)?(24|25|29|33|44)\d{7}$/,
  'bg-BG': /^(\+?359|0)?8[789]\d{7}$/,
  'bn-BD': /^(\+?880|0)1[13456789][0-9]{8}$/,
  'cs-CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  'da-DK': /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'de-DE': /^(\+49)?0?1(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7}$/,
  'de-AT': /^(\+43|0)\d{1,4}\d{3,12}$/,
  'el-GR': /^(\+?30|0)?(69\d{8})$/,
  'en-GB': /^(\+?44|0)7\d{9}$/,
  'en-GG': /^(\+?44|0)1481\d{6}$/,
  'en-GH': /^(\+233|0)(20|50|24|54|27|57|26|56|23|28)\d{7}$/,
  'en-HK': /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
  'en-MO': /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
  'en-IE': /^(\+?353|0)8[356789]\d{7}$/,
  'en-KE': /^(\+?254|0)(7|1)\d{8}$/,
  'en-MT': /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
  'en-MU': /^(\+?230|0)?\d{8}$/,
  'en-NG': /^(\+?234|0)?[789]\d{9}$/,
  'en-NZ': /^(\+?64|0)[28]\d{7,9}$/,
  'en-RW': /^(\+?250|0)?[7]\d{8}$/,
  'en-SG': /^(\+65)?[89]\d{7}$/,
  'en-TZ': /^(\+?255|0)?[67]\d{8}$/,
  'en-UG': /^(\+?256|0)?[7]\d{8}$/,
  'en-ZA': /^(\+?27|0)\d{9}$/,
  'en-ZM': /^(\+?26)?09[567]\d{7}$/,
  'es-CL': /^(\+?56|0)[2-9]\d{1}\d{7}$/,
  'es-EC': /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
  'es-ES': /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
  'es-MX': /^(\+?52)?(1|01)?\d{10,11}$/,
  'es-PA': /^(\+?507)\d{7,8}$/,
  'es-PY': /^(\+?595|0)9[9876]\d{7}$/,
  'es-UY': /^(\+598|0)9[1-9][\d]{6}$/,
  'et-EE': /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
  'fa-IR': /^(\+?98[-\s]?|0)9[0-39]\d[\s]?\d{3}[\s]?\d{4}$/,
  'fi-FI': /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
  'fj-FJ': /^(\+?679)?\s?\d{3}\s?\d{4}$/,
  'fo-FO': /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'fr-FR': /^(\+?33|0)[67]\d{8}$/,
  'fr-GF': /^(\+?594|0|00594)[67]\d{8}$/,
  'fr-GP': /^(\+?590|0|00590)[67]\d{8}$/,
  'fr-MQ': /^(\+?596|0|00596)[67]\d{8}$/,
  'fr-RE': /^(\+?262|0|00262)[67]\d{8}$/,
  'he-IL': /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/,
  'hu-HU': /^(\+?36)(20|30|70)\d{7}$/,
  'id-ID':
    /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
  'it-IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
  'ja-JP': /^(\+81[ ]?(\(0\))?|0)[6789]0[ ]?\d{4}[ ]?\d{4}$/,
  'kk-KZ': /^(\+?7|8)?7\d{9}$/,
  'kl-GL': /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'ko-KR': /^((\+?82)[ ]?)?0?1([0|1|6|7|8|9]{1})[ ]?\d{3,4}[ ]?\d{4}$/,
  'lt-LT': /^(\+370|8)\d{8}$/,
  'ms-MY': /^(\+?6?01){1}(([0145]{1}[-\s]?\d{7,8})|([236789]{1}[\s-]?\d{7}))$/,
  'nb-NO': /^(\+?47)?[49]\d{7}$/,
  'ne-NP': /^(\+?977)?9[78]\d{8}$/,
  'nl-BE': /^(\+?32|0)4?\d{8}$/,
  'nl-NL': /^(\+?31|0)6?\d{8}$/,
  'nn-NO': /^(\+?47)?[49]\d{7}$/,
  'pl-PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
  'pt-BR':
    /(?=^(\+?5{2}-?|0)[1-9]{2}-?\d{4}-?\d{4}$)(^(\+?5{2}-?|0)[1-9]{2}-?[6-9]\d{3}-?\d{4}$)|(^(\+?5{2}-?|0)[1-9]{2}-?9[6-9]\d{3}-?\d{4}$)/,
  'pt-PT': /^(\+?351)?9[1236]\d{7}$/,
  'ro-RO': /^(\+?4?0)\s?7\d{2}(\/|\s|\.|-)?\d{3}(\s|\.|-)?\d{3}$/,
  'ru-RU': /^(\+?7|8)?9\d{9}$/,
  'sl-SI':
    /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
  'sk-SK': /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  'sr-RS': /^(\+3816|06)[- \d]{5,9}$/,
  'sv-SE': /^(\+?46|0)[\s-]?7[\s-]?[02369]([\s-]?\d){7}$/,
  'th-TH': /^(\+66|66|0)\d{9}$/,
  'tr-TR': /^(\+?90|0)?5\d{9}$/,
  'uk-UA': /^(\+?38|8)?0\d{9}$/,
  'vi-VN':
    /^(\+?84|0)((3([2-9]))|(5([2689]))|(7([0|6-9]))|(8([1-6|89]))|(9([0-9])))([0-9]{7})$/,
  'zh-CN':
    /^((\+|00)86)?1([358][0-9]|4[579]|6[67]|7[01235678]|9[189])[0-9]{8}$/,
  'zh-TW': /^(\+?886-?|0)?9\d{8}$/,
};

export const LeaveTooltips = {
  GENERAL:
    'All the Employees are eligible for general leave of 12 working days for each year of service. An employee under probation period is eligible to take only one general leave in a month,and this leave will not be carry forwarded to the next month. If there are any general leaves left at the end of the year, those leaves will be lapsed and will not be carry forwarded to the next year. If an employee does not having general leaves remaining, and applying for leave will then result in loss of pay(LOP).',
  PRIVILEGE:
    'Employees who have completed their probation period are the only ones eligible for privilege leave of 12 working days for each year. An employee under probation period is not eligible to take privilege leave. Privilege Leaves will be carry forwarded to next year.',
  COMP_OFF:
    'Comp Off provides an opportunity for full-time employees to take time off. Eligible employees can accumulate Comp Off up to a maximum of 3 days. It\'s important to note that Comp Off expires after 3 months from the date it is credited. To avail Comp Off, Supervisor/Reporting Manager approval is required, ensuring a coordinated and planned utilization of this leave benefit.',
  WFH: 'All full-time employees are eligible for Work From Home. When requesting WFH, it is advised to submit requests at least 24 hours in advance. Supervisor/Reporting Manager approval is necessary before WFH can be confirmed. Employees can avail a maximum of 1 week (5 days) of WFH in a quarter, ensuring a balanced and flexible approach to remote work.',
};

export default CONSTANTS;
